import { TextField } from "@mui/material";
import React from "react";

interface CustomTextFieldProps {
  sx?: any;
  placeholder?: any;
  name?: any;
  handleContentTextChange: any;
  contentText: any;
  height: any;
  width: any;
  color: any;
  fontsize: any;
}

const CustomTextField = ({
  sx = {},
  placeholder = "",
  name,
  handleContentTextChange,
  contentText,
  height,
  width,
  color,
  fontsize,
}: CustomTextFieldProps) => {
  return (
    <TextField
      multiline
      onChange={handleContentTextChange}
      value={contentText}
      placeholder={placeholder}
      size="small"
      sx={[
        {
          width: width,
          "& .MuiInputBase-input-MuiOutlinedInput-input": {
            border: "none !important",
          },
          "& .MuiOutlinedInput-root": {
            padding: "6px 2px 6px 12px !important",
            outline: "0 !important",
          },
          "& .MuiInputBase-input": {
            color: color + " !important",
            fontSize: fontsize + " !important",
            overflow: "auto !important",
            overflowX: "hidden !important",
            height: height + " !important",
          },
          // "& .MuiOutlinedInput-notchedOutline:hover": {
          //   outline:"10px !important",
          // },
          // "& .MuiOutlinedInput-root:hover, .MuiOutlinedInput-root:focus":{
          //   outline:"10px !important"

          // },
          // "& .Mui-focused, .Mui-focused:hover, .Mui-focused:focus":{
          //     outline:"none !important",
          //     border:"none !important"
          // },
          // "& .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input:hover":{
          //   outline:"0 !important"
          // }
        },
        sx,
      ]}
    />
  );
};

export default CustomTextField;
