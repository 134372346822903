import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

interface FormExitPopupProps {
  callbackAction: any;
  isShowFormExitPopup: any;
  setIsShowFormExitPopup: any;
}
export const FormExitPopupActions = {
  LEAVE: "leave",
  CANCEL: "cancel",
};

const useStyles = makeStyles(() => ({
  modalRoot: {
    width: "25%",
    minWidth: "180px",
    borderRadius: "12px",
  },
}));

const FormExitPopup = ({
  callbackAction,
  isShowFormExitPopup,
  setIsShowFormExitPopup,
}: FormExitPopupProps) => {
  const classes = useStyles();

  return (
    <Modal open={isShowFormExitPopup}>
      <div className={`${classes.modalRoot} modal-container`}>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              padding: "18px 18px 8px 18px",
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            Leave?
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              padding: "0 18px 12px",
              // whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            Changes that you have made may not be saved.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "18px",
              gap: "18px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
              }}
              onClick={() => {
                setIsShowFormExitPopup(false);
                callbackAction(FormExitPopupActions.LEAVE);
              }}
            >
              Leave
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                background: "transparent",
                border: "1px solid #121212",
                color: "black",
                "&:hover": {
                  background: "transparent",
                },
              }}
              onClick={() => callbackAction(FormExitPopupActions.CANCEL)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default FormExitPopup;
