import { request, ResponseProps } from "./request";

export const FilterFormDetailsWithEmail = async (
  id: string,
  email: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${id}&filter=formData.officialEmail:"${email}"`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const ApplyLeavePost = async (
  formData: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/apply-leave`,
    formData
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const FilterFormDetailsWithReportingTo = async (
  id: string,
  email: string,
  filterUrl: string = ""
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${id}&filter=formData.reportingTo:"${email}",${filterUrl}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const CancelAppliedLeave = async (
  leaveApplicationId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/cancel-leave?leaveApplicationId=${leaveApplicationId}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ApproveRejectLeave = async (
  leaveApplicationId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/decision?leaveApplicationId=${leaveApplicationId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const searchEmployeesList = async (
  searchQuery: string,
  status: string,
  page: number,
  size: number
) => {
  const r = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/search?query=${searchQuery}&status=${status}&page=${page}&size=${size}`
  );

  if (r) {
    return { success: true, data: r, message: "Data Fetched Successfully" };
  }

  return { success: false, message: "Failed to Fetch Data" };
};

export const ApplyCompOff = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/apply-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const AddLeaves = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/add-leaves`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const AddCompOffs = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/add-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ApplyCompOffRequest = async (
  formBody
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/request/add-comp-off`,
    formBody
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ChangeCompOffStatus = async (
  compOffId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/comp-off/decision?compOffCreditApplicationId=${compOffId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const ExpirationCheck = async (
  empId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/check-comp-off-expiry?employeeId=${empId}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const UpdateCompOffLeaveStatus = async (
  leaveId: string,
  decision: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/apply-comp-off/decision?leaveApplicationId=${leaveId}&decision=${decision}`,
    null
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getLeaveDetails = async () => {
  const r = await request.get(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/cards`
  );

  if (r) {
    return { success: true, data: r, message: "Data Fetched Successfully" };
  }

  return { success: false, message: "Failed to Fetch Data" };
};
