export const TEMPLATE_UPLOAD =
  "/dms/v1/documents?documentTypeId=920997426618875904";
export const TEMPLATE_DOWNLOAD = "/dms/v1/documents";
export const GENERATE_OTP = "";
export const VERIFY_OTP = "";
export const KEYCLOAK_TOKEN =
  "auth/realms/" +
  sessionStorage.getItem("realm") +
  "/protocol/openid-connect/token";

export const FORM_ENDPOINT = "/form-modeler/v1/forms";
export const RUNTIME_FORM_ENDPOINT = "/form-runtime/v1/form-data";
export const GENERAL_FORM_ENDPOINT = "/emp360-backend/v1/generalFormSave";

export const DMS_ENDPOINT = "/dms/v1/document-types";
export const WORKFLOW_ENDPOINT = "/workflow/v1/process/start";
export const GETUSERS_ENDPOINT = "/accounts/v1/users";
export const USER_PREFERENCE = "/accounts/v1/users/preferences";
export const MENU = "/accounts/v1/roles/menu";
export const MENU_ROLE = "/accounts/v1/menurole";
export const ALL_MENUS = "/accounts/v1/menus";
