import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FINANCE_FORM_ID } from "../../constants/formsConstants";
import { store } from "../../redux/store";
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from "../../services/formService";
import {
  accountNumberMsg,
  bankNameMsg,
  bankValidMsg,
  branchMsg,
  branchName,
  ifscMsg,
  ifscNumber,
  OptedForSodexoMealCouponsMsg,
} from "./formConstants";

import CONSTANTS, {
  ACCOUNT_NUMBER,
  BANK_NAME,
  BRANCH_NAME,
  IFSC,
} from "../../constants/constants";
import { pushNotification } from "../../redux/actions";
import { useDispatch } from "react-redux";

const Finance = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const reduxState = store.getState();
  // const [id, setId] = React.useState<any>();
  let emailId: any = sessionStorage.getItem("user-email");
  const [formId, setFormId] = React.useState("");

  const formOne = useFormik({
    initialValues: {
      bankName: "",
      accountNumber: "",
      ifsc: "",
      branch: "",
      optedForSodexoMealCoupons: false,
    },
    validationSchema: Yup.object({
      bankName: Yup.string()
        .required(bankNameMsg)
        .matches(BANK_NAME, bankValidMsg),
      // .min(3, "Minimum 3 Characters"),
      accountNumber: Yup.string()
        .required(accountNumberMsg)
        .matches(ACCOUNT_NUMBER, accountNumberMsg),
      ifsc: Yup.string().required(ifscMsg).matches(IFSC, ifscNumber),
      branch: Yup.string().required(branchMsg).matches(BRANCH_NAME, branchName),
      optedForSodexoMealCoupons: Yup.boolean().required(
        OptedForSodexoMealCouponsMsg
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },

    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    postFormValues(values);
  };

  const formPayload = (values: any) => ({
    formId: FINANCE_FORM_ID,
    formData: {
      reportingTo: reduxState.reportingToEmailId,
      officialEmail: sessionStorage.getItem("user-email"),
      bankName: values.bankName,
      accountNumber: values.accountNumber,
      financeStatus: "Pending",
      ifsc: values.ifsc,
      branch: values.branch,
      optedForSodexoMealCoupons: values.optedForSodexoMealCoupons,
    },
  });
  const postFormValues = async (values: any) => {
    const postData = formPayload(values);

    if (formId) {
      let payload = {
        formId: postData.formId,
        id: formId,
        formData: postData.formData,
      };
      const response = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const response = await postForm(postData);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  useEffect(() => {
    prefillData();
    // handleDownloadFile("1067049429665820672");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      FINANCE_FORM_ID,
      emailId
    );
    if (response.success && response?.data?.content?.length > 0) {
      setFormId(response?.data?.content[0]?.id);
      // setId(response?.data?.content[0]?.id);
      let mapData: any = response?.data?.content[0]?.formData;
      let finalData: any = {
        bankName: mapData.bankName,
        accountNumber: mapData.accountNumber,
        ifsc: mapData.ifsc,
        branch: mapData.branch,
        optedForSodexoMealCoupons: mapData.optedForSodexoMealCoupons,
      };

      formOne.setValues(finalData);
    } else {
    }
  };
  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={2}>
            <Typography
              color={"red"}
              width="100%"
              sx={{
                "@media (min-width: 2000px)": {
                  fontSize: "calc(12px + 1.4rem)",
                },
                "@media (min-width: 1500px) and (max-width: 1999px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 1000px) and (max-width: 1499px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 600px) and (max-width: 999px)": {
                  fontSize: "calc(12px + 0.4rem)",
                },
                "@media (max-width: 600px)": {
                  fontSize: "calc(12px + 0.2rem)",
                },
              }}
            >
              Bank Details <Divider />
            </Typography>
            <Grid
              container
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              columnSpacing={4}
            >
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label style={{ fontWeight: "bold" }}>Bank Name*</label>
                  <TextField
                    id="bank-Name"
                    name="bankName"
                    size="small"
                    helperText={
                      formOne.touched.bankName && formOne.errors.bankName
                    }
                    error={
                      formOne.touched.bankName &&
                      Boolean(formOne.errors.bankName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.bankName}
                  />
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label style={{ fontWeight: "bold" }}>Account Number*</label>
                  <TextField
                    size="small"
                    id="account-number"
                    name="accountNumber"
                    helperText={
                      formOne.touched.accountNumber &&
                      formOne.errors.accountNumber
                    }
                    error={
                      formOne.touched.accountNumber &&
                      Boolean(formOne.errors.accountNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.accountNumber}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              columnSpacing={4}
            >
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label style={{ fontWeight: "bold" }}>IFSC*</label>
                  <TextField
                    id="ifsc"
                    name="ifsc"
                    size="small"
                    helperText={formOne.touched.ifsc && formOne.errors.ifsc}
                    error={formOne.touched.ifsc && Boolean(formOne.errors.ifsc)}
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.ifsc}
                  />
                </Box>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} xs={12} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label style={{ fontWeight: "bold" }}>Branch*</label>
                  <TextField
                    id="branch"
                    name="branch"
                    size="small"
                    helperText={formOne.touched.branch && formOne.errors.branch}
                    error={
                      formOne.touched.branch && Boolean(formOne.errors.branch)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.branch}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <Grid container gap={2} marginTop={3}>
              <Typography color={"red"} width="100%">
                Void cheque/Bank Passbook
                <Divider />
              </Typography>
              <Divider />
              <Grid xs={12}>
                <Box mt={3} display={"flex"} flexDirection={"column"} gap={2}>
                  <Box>
                    <FileUploader
                      multiple={false}
                      handleChange={handleFileChange}
                      name="file"
                      types={fileTypes}
                    />
                  </Box>
                  {fileUpload === 'application/pdf' && (
                    <iframe
                      src={URL.createObjectURL(file)}
                      style={{ width: "100%", height: "300px" }}
                    />
                  )}
                  {fileUpload === 'image/png' || fileUpload === 'image/jpeg' || fileUpload === 'image/jpg'  ? (
                    <img
                     src={URL.createObjectURL(file)}
                     style={{width: "30%", height: "200px"}}
                    />
                  ) : null} 
                </Box>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container gap={5}></Grid>

          <Grid
            container
            gap={2}
            marginTop={3}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              color={"red"}
              width="100%"
              sx={{
                "@media (min-width: 2000px)": {
                  fontSize: "calc(12px + 1.4rem)",
                },
                "@media (min-width: 1500px) and (max-width: 1999px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 1000px) and (max-width: 1499px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 600px) and (max-width: 999px)": {
                  fontSize: "calc(12px + 0.4rem)",
                },
                "@media (max-width: 600px)": {
                  fontSize: "calc(12px + 0.2rem)",
                },
              }}
            >
              Tax Benefits
              <Divider />
            </Typography>
            <Grid xl={12}>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ color: "black", fontWeight: "bold" }}
                  style={{ color: "black" }}
                  className="generalLabel"
                >
                  Opted for Sodexo Meal Coupons *
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="optedForSodexoMealCoupons"
                  id="Sodexo-meal"
                  onBlur={formOne.handleBlur}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      formOne.setFieldValue("optedForSodexoMealCoupons", true);
                    } else {
                      formOne.setFieldValue("optedForSodexoMealCoupons", false);
                    }
                  }}
                  value={formOne.values.optedForSodexoMealCoupons}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
                <FormHelperText sx={{ color: "red" }}>
                  {formOne.touched.optedForSodexoMealCoupons &&
                    formOne.errors.optedForSodexoMealCoupons}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                "@media (min-width: 2000px)": {
                  fontSize: "30px",
                },
                "@media (min-width: 1500px) and (max-width: 1999px)": {},
                "@media (min-width: 1000px) and (max-width: 1499px)": {},
                "@media (min-width: 600px) and (max-width: 999px)": {},
                "@media (max-width: 600px)": {},
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Finance;
