import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";

import "react-circular-progressbar/dist/styles.css";

import {
  FilterFormDetailsWithEmployeeId,
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from "../services/formService";

import {
  ASSETS_FORM_ID,
  GENERAL_FORM_ID,
  LEAVE_DETAILS_FORM_ID,
  MY_COMP_OFFS_FORM_ID,
  PERSONAL_FORM_ID,
  SUMMARY_FORM_ID,
} from "../constants/formConstants";

import CONSTANTS from "../constants/constants";

import "./home.css";

import ApplyLeaveModal from "./LeaveManagementSystem/applyLeaveModal";

import { useDispatch, useSelector } from "react-redux";

import { pushNotification, setProfilePictureRefresh } from "../redux/actions";

import SummaryCard from "./SummaryCard";

import HobbiesCard from "./HobbiesCard";

import { getUserPreferences } from "../services/userService";

import { uploadProfileImage } from "../services/PreferenceService";
import UserOverview from "./LeaveManagementSystem/UserOverview";
import ApplyCompOffRequestModal from "./ModalViews/ApplyCompOffRequestModal/applyCompOffRequestModal";
import { ExpirationCheck } from "../services/lmsService";
import ProfileDetails from "./LeaveManagementSystem/ProfileDetails/profileDetails";
import { getFullName } from "../services/commonHelperService";

const Home = () => {
  const dispatch = useDispatch();

  const { profilePicture } = useSelector((state: any) => ({
    profilePicture: state.profilePicture,
  }));

  let userEmail = sessionStorage.getItem("user-email");
  const [profileDetails, setProfileDetails] = useState<any>({});
  const [assetsData, setAssetsData] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [isApplyLeaveModalOpen, setIsApplyLeaveModalOpen] = useState(false);
  // const [generalLeaves, setGeneralLeaves] = useState<any>({});
  // const [privilegeLeaves, setPrivilegeLeaves] = useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);
  // const [isLeavesLoading, setIsLeavesLoading] = React.useState(false);
  const [leaveChoice, setLeaveChoice] = useState("");
  const [summary, setSummary] = useState<any>("");
  const [summaryId, setSummaryId] = useState("");
  const [linkedin, setLinkedin] = useState<any>("");
  const [isCompOffRequestModalOpen, setIsCompOffRequestModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [compOffData, setCompOffData] = useState({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const wfhData = {
  //   heading: "WFH",
  //   infoText: "WFH",
  //   availableLeaves: 0,
  //   consumedLeaves: 0,
  //   accruedSoFar: 0,
  // };

  useEffect(() => {
    fetchSummaryDetail(SUMMARY_FORM_ID, userEmail);
    fetchOtherDetails(GENERAL_FORM_ID, userEmail);
    fetchOtherDetails(GENERAL_FORM_ID, userEmail);
    fetchAssetsDetail(ASSETS_FORM_ID, userEmail);
    fetchLeavesDetail(LEAVE_DETAILS_FORM_ID, userEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  useEffect(() => {
    if (profileDetails?.empId) {
      fetchCompOffDetails();
      checkCompOffExpiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails]);

  const fetchSummaryDetail = async (
    formId: string,
    emailId: any
  ): Promise<{ success: boolean; data?: any; message?: string }> => {
    const response = await FilterFormDetailsWithOfficalEmail(formId, emailId);
    if (response.success && response?.data?.content?.length > 0) {
      const linkedinUrl = response?.data?.content[0]?.formData?.hasOwnProperty(
        "linkedinUrl"
      )
        ? response?.data?.content[0]?.formData?.linkedinUrl
        : "";
      const urlParts = linkedinUrl.split("linkedin.com");
      setLinkedin(urlParts[urlParts[1] ? 1 : 0]);
      setSummary(response.data?.content[0]?.formData?.summary);
      setSummaryId(response?.data?.content[0]?.id);
    } else {
      setLinkedin("");
      setSummary("");
      setSummaryId("");
    }
    return { success: false };
  };

  const handleApplyLeave = (leaveType) => {
    setIsApplyLeaveModalOpen(true);
    setLeaveChoice(leaveType);
  };

  const fetchOtherDetails = async (formId, email) => {
    setIsLoading(true);
    const generalInfoResponse: any = await FilterFormDetailsWithOfficalEmail(
      formId,
      email
    );

    const personalInfoResponse = await FilterFormDetailsWithOfficalEmail(
      PERSONAL_FORM_ID,
      email
    );

    await getUserPreferences();
    let result1 = {};
    let result2 = {};
    if (generalInfoResponse.success) {
      setIsLoading(false);
      let mapData1 = generalInfoResponse?.data?.content[0]?.formData;

      result1 = {
        profileImage: profilePicture
          ? `data:image/jpeg;base64,${profilePicture}`
          : "https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg",

        reportingTo: mapData1?.reportingTo || "...",
        empId: mapData1?.empId || "...",
        role: mapData1?.designation || "...",
        dob: mapData1?.dob || "...",
        department: mapData1?.department || "...",
        fullName:
          `${getFullName([mapData1?.firstName, mapData1?.lastName])}` || "...",
        firstName: mapData1?.firstName || "...",
        lastName: mapData1?.lastName || "...",
        bloodGroup: mapData1?.bloodGroup || "...",
        emailId: mapData1?.officialEmail || "...",
        phone: `${mapData1?.phone || "..."}`,
        dateOfJoining: mapData1?.dateOfJoining || "...",
      };
    } else {
      setIsLoading(false);
      result1 = {
        profileImage:
          "https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg",
        reportingTo: "...",
        empId: "...",
        role: "...",
        dob: "...",
        fullName: "...",
        firstName: "...",
        lastName: "...",
        bloodGroup: "...",
        emailId: "...",
        phone: "...",
        department: "...",
      };
    }

    if (personalInfoResponse.success) {
      setIsLoading(false);
      let mapData2 = personalInfoResponse?.data?.content[0]?.formData;

      result2 = {
        maritalStatus: mapData2?.family?.marriedStatus
          ? mapData2.family.marriedStatus.charAt(0).toUpperCase() +
            mapData2.family.marriedStatus.slice(1).toLowerCase()
          : "...",

        location: mapData2?.currentAddress || "...",

        emergencyNo: `${
          mapData2?.emergencyDetails?.emergencyDetails1?.phone || "..."
        }`,
      };
    } else {
      setIsLoading(false);
      result2 = {
        maritalStatus: "...",
        location: "...",
        emergencyNo: "...",
        department: "...",
      };
    }

    setIsLoading(false);
    setProfileDetails({ ...result1, ...result2 });
  };

  const fetchAssetsDetail = async (formId, email) => {
    const response: any = await FilterFormDetailsWithOfficalEmail(
      formId,

      email
    );

    if (response.success && response?.data?.content?.length > 0) {
      setAssetsData(response?.data?.content[0]?.formData?.assets);
    } else {
      setAssetsData([]);
    }
  };

  const fetchLeavesDetail = async (formId, id) => {
    // setIsLeavesLoading(true);
    const response: any = await FilterFormDetailsWithOfficalEmail(formId, id);
    if (response.success && response?.data?.content?.length > 0) {
      // setIsLeavesLoading(false);
      // let generalLeavesData = {
      //   heading: "General Leaves",
      //   infoText:
      //     "All the Employees are eligible for general leave of 12 working days for each year of service.   An employee under probation period is eligible to take only one general leave in a month,and this leave will not be carry forwarded to the next month.   If there are any general leaves left at the end of the year, those leaves will be lapsed and will not be carry forwarded to the next year.  If an employee does not having general leaves remaining, and applying for leave will then result in loss of pay(LOP).",
      //   availableLeaves:
      //     response?.data?.content[0]?.formData?.leaveDetails?.general,
      //   consumedLeaves:
      //     response?.data?.content[0]?.formData?.consumedLeaveDetails?.general,
      //   accruedSoFar:
      //     response?.data?.content[0]?.formData?.accruedLeaveDetails?.general,
      // };
      // let privilegeLeavesData = {
      //   heading: "Privilege Leaves",
      //   infoText:
      //     "Employees who have completed their probation period are the only ones eligible for privilege leave of 12 working days for each year.  An employee under probation period is not eligible to take privilege leave.  Privilege Leaves will be carry forwarded to next year.",
      //   availableLeaves:
      //     response?.data?.content[0]?.formData?.leaveDetails?.privilege,
      //   consumedLeaves:
      //     response?.data?.content[0]?.formData?.consumedLeaveDetails?.privilege,
      //   accruedSoFar:
      //     response?.data?.content[0]?.formData?.accruedLeaveDetails?.privilege,
      // };
      // setGeneralLeaves(generalLeavesData);
      // setPrivilegeLeaves(privilegeLeavesData);
    } else {
      // setIsLeavesLoading(false);
      // setGeneralLeaves({
      //   heading: "General Leaves",
      //   infoText:
      //     "All the Employees are eligible for general leave of 12 working days for each year of service.   An employee under probation period is eligible to take only one general leave in a month,and this leave will not be carry forwarded to the next month.   If there are any general leaves left at the end of the year, those leaves will be lapsed and will not be carry forwarded to the next year.  If an employee does not having general leaves remaining, and applying for leave will then result in loss of pay(LOP).",
      //   availableLeaves: 0,
      //   consumedLeaves: 0,
      //   accruedSoFar: 0,
      // });
      // setPrivilegeLeaves({
      //   heading: "Privilege Leaves",
      //   infoText:
      //     "Employees who have completed their probation period are the only ones eligible for privilege leave of 12 working days for each year.  An employee under probation period is not eligible to take privilege leave.  Privilege Leaves will be carry forwarded to next year.",
      //   availableLeaves: 0,
      //   consumedLeaves: 0,
      //   accruedSoFar: 0,
      // });
    }
  };

  const fetchCompOffDetails = async () => {
    // setIsLeavesLoading(true);
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      profileDetails.empId
    );

    if (response.success && response?.data?.content?.length > 0) {
      // setIsLeavesLoading(false);
      // const compOffDetails = getCompOffDetails(response?.data?.content);
      // setCompOffData(compOffDetails);
    } else {
      // setIsLeavesLoading(false);
      // const compOffDetails = getCompOffDetails(response?.data?.content);
      // setCompOffData(compOffDetails);
    }
  };

  const checkCompOffExpiry = async () => {
    // setIsLeavesLoading(true);
    const response: any = await ExpirationCheck(profileDetails.empId);

    if (response.success && response?.data?.content?.length > 0) {
      // setIsLeavesLoading(false);
      await fetchCompOffDetails();
    } else {
      // setIsLeavesLoading(false);
    }
  };

  const handleFileChange = async (file) => {
    const response: any = await uploadProfileImage(file, file?.name);

    if (response?.success) {
      dispatch(
        pushNotification({
          isOpen: true,

          message: response.message,

          type: CONSTANTS.SUCCESS,
        })
      );

      dispatch(setProfilePictureRefresh((prevSate) => !prevSate));

      setAnchorEl(null);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,

          message: response.message,

          type: CONSTANTS.ERROR,
        })
      );

      setAnchorEl(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkedinData = (e) => {
    setLinkedin(e.target.value);
  };

  const handleLinkedinPost = async () => {
    if (summaryId) {
      let payload = {
        formId: SUMMARY_FORM_ID,

        id: summaryId,

        formData: {
          summary: summary,

          officialEmail: sessionStorage.getItem("user-email"),

          linkedinUrl: linkedin,
        },
      };
      const response: any = await postForm(payload);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,

            message: response.message,

            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,

            message: response.message,

            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      let payload = {
        formId: SUMMARY_FORM_ID,

        formData: {
          summary: summary,

          officialEmail: sessionStorage.getItem("user-email"),

          linkedinUrl: linkedin,
        },
      };

      const response = await postForm(payload);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,

            message: response.message,

            type: CONSTANTS.SUCCESS,
          })
        );
      } else {
        dispatch(
          pushNotification({
            isOpen: true,

            message: response.message,

            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  const handleApplyCompOffRequest = () => {
    setIsCompOffRequestModalOpen(true);
  };

  const closeCompOffRequestModal = () => {
    setIsCompOffRequestModalOpen(false);
  };

  useEffect(() => {
    if (!isApplyLeaveModalOpen && !isCompOffRequestModalOpen) {
      setRefresh((prev) => !prev);
    }
  }, [isCompOffRequestModalOpen, isApplyLeaveModalOpen]);

  return (
    <>
      <Grid container spacing={2} className={"home-grid-container"}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <ProfileDetails
            profileDetails={profileDetails}
            isLoading={isLoading}
            isEmployeeModalOpen={show}
            setIsEmployeeModalOpen={setShow}
            menuAnchorElement={anchorEl}
            closeMenu={handleClose}
            handleFileChange={handleFileChange}
            handleOpenMenu={handleClick}
            handleLinkedinPost={handleLinkedinPost}
            handleLinkedinData={handleLinkedinData}
            linkedinUrl={linkedin}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8} xl={9}>
          <div className="withoutProfileCard">
            {isApplyLeaveModalOpen ? (
              <ApplyLeaveModal
                show={isApplyLeaveModalOpen}
                setShow={setIsApplyLeaveModalOpen}
                leaveChoice={leaveChoice}
              />
            ) : null}

            {isCompOffRequestModalOpen ? (
              <ApplyCompOffRequestModal
                isModalOpen={isCompOffRequestModalOpen}
                closeModal={closeCompOffRequestModal}
                empId={profileDetails?.empId}
              />
            ) : null}

            <UserOverview
              handleApplyLeave={handleApplyLeave}
              handleApplyCompOffRequest={handleApplyCompOffRequest}
              updated={refresh}
            />

            {assetsData?.length > 0 && assetsData[0].serialNumber !== "" && (
              <div className="assets">
                <p className="summaryTitleAsset">Assets</p>

                {assetsData?.length > 0 ? (
                  <div className="summaryContents">
                    {assetsData?.map((item, index) => {
                      return (
                        <p className="summaryContent">
                          {`${index + 1}.`} {item?.asset} | {item?.brand} |{" "}
                          {item?.operatingSystem} | {item?.serialNumber}
                          {/* rendering divider based on length */}
                          {assetsData?.length > index + 1 ? (
                            <Divider style={{ marginTop: "8px" }}></Divider>
                          ) : null}
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  <p>No Asset Found</p>
                )}
              </div>
            )}

            <div className="assets summaryDiv">
              <SummaryCard />

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  margin: "10px",
                }}
              />

              <HobbiesCard />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
