import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

interface VisitorLogToolbarProps {
  containerStyle?: any;
  handleSearchData: any;
  handleOnClickAddVisitor: any;
  handleOnClickExport: any;
  setSearchText: any;
  searchText: any;
}

const styles = {
  containers: {
    containerMain: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      flexDirection: "column",
      gap: "2px",
    },
    searchBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
  },
  typography: {
    searchVisitors: {
      fontSize: "14px",
      color: "#121212",
    },
    filterCount: {
      position: "absolute",
      top: "0px",
      right: "0px",
      background: "#e9653d",
      height: "16px",
      width: "16px",
      borderRadius: "100%",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontWeight: "bold",
    },
  },
  items: {
    textFieldSearch: {
      minWidth: "100px",
      "& .MuiInputBase-root": {
        height: "36px",
      },
    },
  },
  buttons: {
    addVisitor: {
      textTransform: "capitalize",
      display: "flex",
      height: "36px",
      // background: "#08973f",
      background: "#1965ac",
      "&:hover": {
        background: "#0d5496",
      },
    },
    export: {
      textTransform: "capitalize",
      display: "flex",
      height: "36px",
      background: "#F15830",
      "&:hover": {
        background: "#c93c19",
      },
    },
    filter: {
      textTransform: "capitalize",
      background: "#e9e9e9",
      transition: "1s",
      "&:hover": {
        background: "#ffffff",
        boxShadow: "0 0 15px black",
        transition: "0.2s",
        cursor: "pointer",
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "2px 10px",
      borderRadius: "100%",
      height: "100%",
      position: "relative",
    },
  },
};
const VisitorLogToolbar = ({
  containerStyle = {},
  handleSearchData,
  handleOnClickAddVisitor,
  handleOnClickExport,
  setSearchText,
  searchText,
}: VisitorLogToolbarProps) => {

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={[styles.containers.containerMain, containerStyle]}
    >
      <Box>
        <Typography sx={styles.typography.searchVisitors}>
          Search Visitors
        </Typography>
      </Box>
      <Box sx={styles.containers.searchBox}>
        <TextField
          type={"search"}
          id="outlined-size-normal"
          value={searchText}
          onChange={(event) => {
            setSearchText(event.currentTarget.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              handleSearchData();
            }
          }}
          placeholder="Search"
          sx={styles.items.textFieldSearch}
          size={"small"}
          InputProps={{
            style: {
              borderRadius: "4px",
              backgroundColor: "#ffff",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={"Click to Search"}>
                  <SearchOutlinedIcon
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      handleSearchData();
                    }}
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          size="medium"
          sx={styles.buttons.addVisitor}
          onClick={handleOnClickAddVisitor}
        >
          <AddIcon />
          Add visitor
        </Button>
        <CloudDownloadOutlinedIcon
          sx={{
            color: "#1965ac",

            cursor: "pointer",
          }}
          onClick={handleOnClickExport}
        />
      </Box>
    </Container>
  );
};

export default VisitorLogToolbar;
