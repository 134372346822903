import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";

import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import "react-phone-input-2/lib/style.css";
import CustomTextField from "../../CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DateTimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { StatusConstants } from "../types/ReceptionTypes";
import { phoneNumberRegex } from "../../../constants/constants";
import { convertDateTimeToISOString } from "../../../utils/DateOperations";
import { formPic } from "../images";
import { WebcamCapture } from "./WebcamCapture";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const useStyles = makeStyles(() => ({
  modalRoot: {
    maxHeight: "100%",
    width: "62vw",
    borderRadius: "18px",
    backgroundColor: "transparent",
    overflowY: "scroll",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none", // IE and Edge
    "scrollbar-width": "none", // Firefox
  },
  divider: {
    backgroundColor: "#f15830",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "15px",
  },
  addBtn: {
    marginTop: "15px",
  },
  createEmployeeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  phoneInputStyles: {
    width: "100% !important",

    "@media (min-width: 320px )": {
      width: "100% !important",
    },
    "@media (min-width: 375px)": {
      width: "85% !important",
    },
    "@media (min-width: 425px)": {
      width: "85% !important",
    },
    "@media (min-width: 600px)": {
      width: "100% !important",
    },
    "@media (min-width: 768px)": {
      width: "100% !important",
    },
    "@media (min-width: 1440px)": {
      width: "100% !important",
    },
    "@media (min-width: 2560px)": {
      width: "100% !important",
    },
  },
}));

interface AddVisitorFormProps {
  isAdmin: any;
  show: any;
  setShow: any;
  addVisitorCallback: any;
  hostEmailList: any;
  emailMobileFullNamePairs: any;
}

const styles = {
  container: {
    main: {
      width: "62vw",
      "& *": {
        fontSize: "12px",
        color: "#000000ca",
      },
      // background: `url(https://wallpapercave.com/wp/wp8489436.jpg)`,
      // background: `url(${formPic})`,
      background: "#E1EDF2",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      borderRadius: "24px",
    },
    mainBox: {
      display: "flex",
      justifyContent: "center",
      // gap: "24px",
    },
    logo: {
      flexGrow: "1",
      minHeight: "100%",
      minWidth: "320px",
      display: "flex",
      background: `url(${formPic})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center left",
      borderTopLeftRadius: "24px",
      borderBottomLeftRadius: "24px",
    },
    formMain: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      padding: "28px 42px 42px 42px",
      margin: "28px",
      background: "#ffffffec",
      borderRadius: "24px",
    },
    mainHeading: {
      display: "flex",
      flexGrow: "1",
      width: "100%",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "start",
    },
    formBox: {
      maxWidth: "24vw",
      minWidth: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      flexGrow: "1",
      padding: "18px 0px 0 0",
    },
    visitorBox: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    visitorSectionBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      gap: "1vw",
    },
    visitorSectionBox2: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
      gap: "1vw",
    },
    hostBoxMain: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5vw",
      width: "100%",
    },
    hostSection: {
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      gap: "1vw",
      position: "relative",
    },
    purposeBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      "& *": {
        fontSize: "14px !important",
      },
    },
    rowSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      width: "100%",
      "& *": {
        fontSize: "14px !important",
        lineHeight: "1.2 !important",
      },
    },
    webCam: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  typography: {
    headingEnterDetails: {
      textAlign: "start",
      fontSize: "28px !important",
      color: "#000000bd !important",
    },
    infoText: {
      fontSize: "14px !important",
    },
  },
  dividers: {
    headingUnderlineDivider: {
      height: "1px",
      background: "#00000035",
      width: "100%",
    },
  },
  buttons: {
    submitButton: {
      width: "100%",
      borderRadius: "24px",
      marginBottom: "-18px",
      background: "#2b5f6b",
      "&:hover": {
        background: "#1c3a42",
      },
      color: "white !important",
      textTransform: "capitalize",
      fontsize: "16px",
    },
  },
  icons: {
    profilePic: { width: 70, height: 70 },
    addAPhotoIcon: {
      position: "absolute",
      bottom: "0px",
      right: "0px",
      fontSize: "18px",
      "& .MuiSvgIcon-root, .MuiSvgIcon-fontSizeMedium": {
        color: "white !important",
      },
    },
  },
  dateTimePicker: {
    "& .MuiOutlinedInput-input": {
      flexGrow: "1",
      height: "9px",
      width: "100%",
    },
  },
  checkBox: {
    fontSize: "10px",
    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
  },
};
const AddVisitorForm = ({
  isAdmin,
  show,
  setShow,
  addVisitorCallback,
  hostEmailList,
  emailMobileFullNamePairs,
}: AddVisitorFormProps) => {
  const classes = useStyles();
  let userEmail = sessionStorage.getItem("user-email");

  const [countryCode, setCountryCode] = useState("in");
  const [scheduledDateTime, setScheduledDateTime] =
    useState<dayjs.Dayjs | null>();
  const [countryRegex] = useState(
    phoneNumberRegex[countryCode as keyof typeof phoneNumberRegex]
  );

  const [imageSrc, setImageSrc] = useState<any>(null);
  const [webcamOpen, setWebcamOpen] = useState(false);
  const handlePhoneChange = async (value: any, country: any) => {
    if (country && "dialCode" in country) {
      // const countryCode = `+${country.dialCode}`;
      // const phoneNumber = value.slice(countryCode.length);
      // const formattedNumber = `${countryCode} ${phoneNumber}`;
    }
    setCountryCode(country.countryCode);
  };

  const alphabeticRegexAtleast3 = /^[a-zA-Z\s]{3,}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(alphabeticRegexAtleast3, "Atlease 3 characters, only letters")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(alphabeticRegexAtleast3, "Atlease 3 characters, only letters")
      .required("Last name is required"),
    visitorMobile: Yup.string()
      .matches(countryRegex, "Please Enter Valid Phone Number")
      .required("Mobile number is required"),
    visitorEmail: Yup.string().matches(emailRegex, "Invalid email format"),
    visitorLocation: Yup.string()
      .matches(/^.{3,}$/, "Atlease 3 characters, only letters")
      .required("Visitor location is required"),
    hostEmail: Yup.string()
      .matches(emailRegex, "Invalid email format")
      .required("Host email is required"),
    purpose: Yup.string()
      .matches(alphabeticRegexAtleast3, "Atlease 3 characters, only letters")
      .required("Purpose is required"),
    scheduledDateTime: Yup.string().required("Date & time is required"),
    immediate: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      visitorMobile: "",
      visitorEmail: "",
      photoUrl: "",
      visitorLocation: "",
      hostName: "",
      hostEmail: "",
      purpose: "",
      hostMobile: "",
      scheduledDateTime: "",
      immediate: false,
      checkin: "",
    },
    validationSchema,
    onSubmit: (values) => {
      addVisitorCallback(values);
      // formik.resetForm();
      // setScheduledDateTime(null);
    },
  });

  const handleDateTimeChangeLocally = (dateTime: dayjs.Dayjs) => {
    setScheduledDateTime(dateTime);
    const dateString = convertDateTimeToISOString(dateTime);
    handleFieldChange("scheduledDateTime", dateString);
  };

  const handleInstantCheckin = (isChecked: boolean) => {
    handleFieldChange("immediate", isChecked);
    handleFieldChange("checkin", convertDateTimeToISOString(dayjs()));
    handleFieldChange("status", StatusConstants.CHECKED_IN);
    if (isChecked) handleDateTimeChangeLocally(dayjs());
    else {
      setScheduledDateTime(null);
      handleFieldChange("scheduledDateTime", "");
      formik.setFieldTouched("scheduledDateTime", false);
      handleFieldChange("checkin", null);
    }
  };

  const handleFieldChange = (field: any, value: any) => {
    formik.handleChange({
      target: {
        name: field,
        value: value,
      },
    });
  };

  const handleCapture = (base64: string | null) => {
    handleFieldChange("photoUrl", base64);
  };
  useEffect(() => {
    formik.resetForm();
    setScheduledDateTime(null);
    setImageSrc("");
    try {
      if (!isAdmin) {
        handleFieldChange("hostEmail", userEmail);
        if (userEmail) {
          handleFieldChange(
            "hostMobile",
            emailMobileFullNamePairs?.[userEmail]?.mobile
          );
          handleFieldChange(
            "hostName",
            emailMobileFullNamePairs?.[userEmail]?.name
          );
        }
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Modal
      open={show}
      onClose={() => {
        setShow(false);
      }}
    >
      <div className={`${classes.modalRoot} modal-container`}>
        <Box sx={styles.container.webCam}>
          <WebcamCapture
            onCapture={handleCapture}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            webcamOpen={webcamOpen}
            setWebcamOpen={setWebcamOpen}
          />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={styles.container.main}>
            <Box sx={styles.container.mainBox}>
              <Box sx={styles.container.logo}></Box>
              <Box sx={styles.container.formMain}>
                <Box sx={styles.container.mainHeading}>
                  <Typography sx={styles.typography.headingEnterDetails}>
                    Enter Details
                  </Typography>
                  <Divider
                    orientation="horizontal"
                    sx={styles.dividers.headingUnderlineDivider}
                  />
                </Box>
                <Box sx={styles.container.formBox}>
                  <Box sx={styles.container.visitorBox}>
                    <Box sx={styles.container.visitorSectionBox}>
                      <Box
                        className={"rotate-scale-up-diag-1"}
                        sx={{
                          height: "78px",
                          width: "78px",
                          position: "relative",
                          cursor: "pointer",
                          border: "4px solid #327193",
                          borderRadius: "100%",
                          "&:hover": {
                            boxShadow: "0 0 10px black",
                          },
                          transition: "all 0.3s",
                        }}
                        onClick={() => {
                          setWebcamOpen(true);
                        }}
                      >
                        <Avatar
                          src={imageSrc}
                          alt={"Upload"}
                          sx={styles.icons.profilePic}
                        />
                        <AddAPhotoIcon sx={styles.icons.addAPhotoIcon} />
                      </Box>
                    </Box>
                    <Box sx={styles.container.visitorSectionBox}>
                      <Box sx={styles.container.rowSection}>
                        <Typography>First Name*:</Typography>
                        <TextField
                          id="firstName"
                          name="firstName"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e: any) => {
                            handleFieldChange("firstName", e.target.value);
                          }}
                          value={formik.values.firstName}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          sx={{ width: "100%" }}
                        />
                      </Box>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Last Name*:</Typography>
                        <TextField
                          id="lastName"
                          name="lastName"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e: any) => {
                            handleFieldChange("lastName", e.target.value);
                          }}
                          value={formik.values.lastName}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          sx={{ width: "100%" }}
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.container.visitorSectionBox}>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Visitor mobile*:</Typography>
                        <PhoneInput
                          inputClass={classes.phoneInputStyles}
                          country={"in"}
                          value={formik.values.visitorMobile}
                          onChange={(value, country) => {
                            handlePhoneChange(value, country);
                            handleFieldChange("visitorMobile", value);
                          }}
                          inputStyle={{
                            height: "40px",
                            width: "100%",
                          }}
                        />
                        {formik.touched.visitorMobile &&
                        formik.errors.visitorMobile ? (
                          <Typography color="error" sx={{ fontSize: "12px" }}>
                            {formik.errors.visitorMobile}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Visitor Email:</Typography>
                        <TextField
                          id="visitorEmail"
                          name="visitorEmail"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e: any) => {
                            handleFieldChange("visitorEmail", e.target.value);
                          }}
                          value={formik.values.visitorEmail}
                          error={
                            formik.touched.visitorEmail &&
                            Boolean(formik.errors.visitorEmail)
                          }
                          helperText={
                            formik.touched.visitorEmail &&
                            formik.errors.visitorEmail
                          }
                          placeholder="(Optional)"
                          sx={{ width: "100%" }}
                        />
                      </Box>
                    </Box>{" "}
                    <Box sx={styles.container.visitorSectionBox}>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Visitor Location*:</Typography>
                        <TextField
                          id="visitorLocation"
                          name="visitorLocation"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={(e: any) => {
                            handleFieldChange(
                              "visitorLocation",
                              e.target.value
                            );
                          }}
                          value={formik.values.visitorLocation}
                          error={
                            formik.touched.visitorLocation &&
                            Boolean(formik.errors.visitorLocation)
                          }
                          helperText={
                            formik.touched.visitorLocation &&
                            formik.errors.visitorLocation
                          }
                          sx={{ width: "100%" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={styles.container.hostBoxMain}>
                    <Box sx={styles.container.hostSection}>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Host Email*:</Typography>
                        {isAdmin ? (
                          <Autocomplete
                            id="hostEmail"
                            sx={{ width: "100%" }}
                            options={hostEmailList}
                            getOptionLabel={(option: any) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="hostEmail"
                                size="small"
                                onBlur={formik.handleBlur}
                                value={formik.values.hostEmail}
                                error={
                                  formik.touched.hostEmail &&
                                  Boolean(formik.errors.hostEmail)
                                }
                                helperText={
                                  formik.touched.hostEmail &&
                                  formik.errors.hostEmail
                                }
                                sx={{ width: "100%", flexGrow: "1" }}
                              />
                            )}
                            onChange={(event, value) => {
                              try {
                                handleFieldChange(
                                  "hostEmail",
                                  value !== null ? value : ""
                                );
                                handleFieldChange(
                                  "hostMobile",
                                  emailMobileFullNamePairs?.[value]?.mobile
                                );
                                handleFieldChange(
                                  "hostName",
                                  emailMobileFullNamePairs?.[value]?.name
                                );
                              } catch (_) {}
                            }}
                          />
                        ) : (
                          <TextField
                            name="hostEmail"
                            size="small"
                            onBlur={formik.handleBlur}
                            value={formik.values.hostEmail}
                            error={
                              formik.touched.hostEmail &&
                              Boolean(formik.errors.hostEmail)
                            }
                            helperText={
                              formik.touched.hostEmail &&
                              formik.errors.hostEmail
                            }
                            disabled
                            sx={{ width: "100%", flexGrow: "1" }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box sx={styles.container.hostSection}>
                      <Box sx={styles.container.rowSection}>
                        <Typography>Host Mobile:</Typography>
                        <PhoneInput
                          inputClass={classes.phoneInputStyles}
                          country={"in"}
                          value={formik.values.hostMobile}
                          disabled
                          inputStyle={{
                            height: "40px",
                            width: "100%",
                          }}
                        />
                        {formik.touched.hostMobile &&
                        formik.errors.hostMobile ? (
                          <Typography color="error" sx={{ fontSize: "12px" }}>
                            {formik.errors.hostMobile}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box sx={styles.container.rowSection}>
                        <Box sx={styles.container.rowSection}>
                          <Typography>Host Name:</Typography>
                          <TextField
                            id="hostName"
                            name="hostName"
                            size="small"
                            onBlur={formik.handleBlur}
                            value={formik.values.hostName}
                            disabled
                            error={
                              formik.touched.hostName &&
                              Boolean(formik.errors.hostName)
                            }
                            helperText={
                              formik.touched.hostName && formik.errors.hostName
                            }
                            sx={{ width: "100%" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={[
                        styles.container.hostSection,
                        {
                          alignItems: "center",
                        },
                      ]}
                    >
                      <Box sx={styles.container.rowSection}>
                        <Typography>Scheduled Date & Time*:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            minDate={dayjs()}
                            onError={() => {
                              console.log("Error date");
                            }}
                            viewRenderers={{
                              hours: renderTimeViewClock,
                              minutes: renderTimeViewClock,
                              seconds: renderTimeViewClock,
                            }}
                            sx={styles.dateTimePicker}
                            onChange={(data: any) => {
                              try {
                                handleDateTimeChangeLocally(data);
                                // handleFieldChange(
                                //   "scheduledDateTime",
                                //   convertDateTimeToISOString(data)
                                // );
                              } catch (_) {}
                            }}
                            value={scheduledDateTime}
                            disabled={formik.values.immediate}
                          />
                        </LocalizationProvider>
                        {formik.touched.scheduledDateTime &&
                        formik.errors.scheduledDateTime ? (
                          <Typography color="error" sx={{ fontSize: "12px" }}>
                            {formik.errors.scheduledDateTime}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box sx={styles.container.rowSection}>
                        <FormControlLabel
                          sx={{
                            // width: "100%",
                            height: "100%",
                          }}
                          control={
                            <Checkbox
                              checked={formik.values.immediate}
                              onChange={(e, isChecked) => {
                                handleInstantCheckin(isChecked);
                              }}
                              name="immediate"
                              // color="primary"
                              sx={styles.checkBox}
                            />
                          }
                          label={
                            <Typography sx={{ fontWeight: "bold" }}>
                              Instant Check in
                            </Typography>
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.container.purposeBox}>
                      <Typography>Purpose*:</Typography>
                      <CustomTextField
                        name="purpose"
                        contentText={formik.values.purpose}
                        handleContentTextChange={(e: any) => {
                          formik.setFieldValue("purpose", e.target.value);
                        }}
                        color="#162952"
                        fontsize="16px"
                        height="100px"
                        width="100%"
                      />
                      {formik.touched.purpose && formik.errors.purpose ? (
                        <Typography color="error" sx={{ fontSize: "12px" }}>
                          {formik.errors.purpose}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={styles.buttons.submitButton}
                  >
                    Create Visitor
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </div>
    </Modal>
  );
};

export default AddVisitorForm;
