import React from "react";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { Box, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export const getCraftedAddress = (address: any) => {
  return (
    (address?.building ? `${address.building}, ` : "") +
    " " +
    (address?.road ? `${address.road}, ` : "") +
    (address?.neighbourhood ? `${address.neighbourhood}, ` : "") +
    " " +
    (address?.suburb ? `${address.suburb}, ` : "") +
    " " +
    (address?.city ? `${address.city}. ` : "")
  );
};

const styles = {
  mainContainer: { display: "flex", gap: 0.5, alignItems: "center" },
  icons: {
    pinDropIcon: {
      color: "#F15830",
      fontSize: "small",
    },
  },
  typography: {
    addressContent: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      textAlign: "start",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      maxWidth: "200px",
      fontSize: "13px",
    },
  },
};

export const LocationDisplay: React.FC = () => {
  const address = useSelector((state: any) => state?.address);

  return (
    <Box sx={styles.mainContainer}>
      {address?.suburb ? <PinDropIcon sx={styles.icons.pinDropIcon} /> : null}
      <Tooltip title={getCraftedAddress(address)}>
        <Typography sx={styles.typography.addressContent}>
          {getCraftedAddress(address)}
        </Typography>
      </Tooltip>
    </Box>
  );
};
