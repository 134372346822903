import { Box, Divider } from "@mui/material";
import Greeting from "./showcase/Greetings";
import Quote from "./showcase/Quote";

interface VisitorHeadingProps {
  userName: any;
  divider?: any;
  dividerWidth?: any;
  dividerHeight?: any;
  containerStyle?: any;
  dividerBackground?: any;
}

const VisitorHeading = ({
  userName,
  divider = true,
  dividerWidth = "80%",
  dividerHeight = "1px",
  containerStyle = {},
  dividerBackground = "#00000079",
}: VisitorHeadingProps) => {
  return (
    <Box sx={[containerStyle]}>
      <Greeting userName={userName} />
      <Quote />

      {divider && (
        <Divider
          orientation="horizontal"
          sx={{
            height: dividerHeight,
            width: dividerWidth,
            background: dividerBackground,
          }}
        />
      )}
    </Box>
  );
};

export default VisitorHeading;
