import { GETUSERS_ENDPOINT } from "../constants/endpoints";
import { request, ResponseProps } from "../services/request";

export const getUserPreferences = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem("gatewayURL")}/accounts/v1/users/preferences`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const getAllUsers = async (): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem("gatewayURL")}${GETUSERS_ENDPOINT}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};
