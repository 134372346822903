import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

interface LeaveNotificationPopupProps {
  show: any;
  setShow: any;
  availablePrivilegeLeaves: any;
  callbackAction: any;
  availableGeneralLeaves: any;
  popupData: any;
}

export const LeaveNotificationCallbackActions = {
  CONTINUE: "continueWithLOP",
  CANCEL: "cancel",
};

const useStyles = makeStyles(() => ({
  modalRoot: {
    minHeight: "360px",
    maxHeight: "50%",
    width: "90%",
    maxWidth: "600px",
    borderRadius: "18px",
    margin: "0 auto",
    padding: "0 0 16px 0",
    backgroundColor: "white",
    position: "relative",
  },
}));

const styles = {
  container: {
    main: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      background: "#162952",
      borderTopLeftRadius: "18px",
      borderTopRightRadius: "18px",
      padding: { xs: "8px", sm: "16px" },
    },
    content: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: { xs: "16px 0", sm: "32px 0" },
      flexDirection: { xs: "column", sm: "row" },
      textAlign: { xs: "center", sm: "left" },
    },
    infoText: {
      border: "1px solid red",
      borderRadius: "12px",
      padding: { xs: "8px", sm: "12px" },
      margin: { xs: "8px 16px", sm: "16px 32px" },
    },
    buttonBox: {
      display: "flex",
      justifyContent: { xs: "center", sm: "flex-end" },
      flexDirection: { xs: "column", sm: "row" },
      alignItems: "center",
      margin: "8px 32px",
      padding: "24px 0",
      "& *": {
        fontSize: { xs: "14px", sm: "16px" },
      },
    },
  },
  typography: {
    header: {
      fontSize: { xs: "20px", sm: "28px" },
      color: "white",
    },
    leavesData: {
      whiteSpace: "nowrap",
      fontWeight: "bold",
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "8px",
      color: "#121212c8",
    },
  },
  buttons: {
    closeButton: {
      position: "absolute",
      right: "10px",
      color: "white",
      fontWeight: "bold",
      padding: "4px",
      borderRadius: "50%",
      transition: "all 0.3s",
      "&:hover": {
        cursor: "pointer",
        background: "#12121273",
        transition: "all 0.3s",
      },
    },
    closeIcon: {
      fontSize: { xs: "24px", sm: "28px" },
    },
    leftButton: {
      background: "#c02727",
      color: "white",
      fontWeight: "bold",
      textTransform: "capitalize",
      margin: { xs: "8px 0", sm: "0 12px" },
      "&:hover": {
        background: "#c02727",
      },
    },
    rightButton: {
      background: "#147032",
      color: "white",
      margin: { xs: "8px 0", sm: "0 12px" },
      fontWeight: "bold",
      textTransform: "capitalize",
      "&:hover": {
        background: "#147032",
      },
    },
  },
  divider: {
    height: "2px",
  },
};

const LeaveNotificationPopup = ({
  show,
  setShow,
  availablePrivilegeLeaves,
  availableGeneralLeaves,
  callbackAction,
  popupData,
}: LeaveNotificationPopupProps) => {
  const classes = useStyles();

  return (
    <Modal open={show} onClose={() => setShow(false)}>
      <div className={`${classes.modalRoot} modal-container`}>
        <Box>
          <Box sx={styles.container.main}>
            <Typography sx={styles.typography.header}>
              Attention <ReportProblemIcon />
            </Typography>
            <Box sx={styles.buttons.closeButton}>
              <Close
                sx={styles.buttons.closeIcon}
                onClick={() => {
                  setShow(false);
                }}
              />
            </Box>
          </Box>
          <Divider orientation="horizontal" sx={styles.divider} />
          <Box sx={styles.container.content}>
            <Typography sx={styles.typography.leavesData}>
              General Leaves:{" "}
              <Typography sx={{ color: "#162952" }}>
                {availableGeneralLeaves}
              </Typography>
            </Typography>
            <Typography sx={styles.typography.leavesData}>
              Privilege Leaves:{" "}
              <Typography sx={{ color: "#162952" }}>
                {availablePrivilegeLeaves}
              </Typography>
            </Typography>
          </Box>
          <Box sx={styles.container.infoText}>
            <Typography sx={{ fontSize: { xs: "16px", sm: "18px" } }}>
              {popupData.infoText}
            </Typography>
          </Box>
          <Box sx={styles.container.buttonBox}>
            <Button
              variant="contained"
              sx={styles.buttons.leftButton}
              onClick={() => {
                setShow(false);
                callbackAction(LeaveNotificationCallbackActions.CONTINUE);
              }}
            >
              {popupData.leftButtonText}
            </Button>
            <Button
              variant="contained"
              sx={styles.buttons.rightButton}
              onClick={() => {
                setShow(false);
                callbackAction(LeaveNotificationCallbackActions.CANCEL);
              }}
            >
              {popupData.rightButtonText}
            </Button>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default LeaveNotificationPopup;
