import CustomChart from "./CustomChart";
import "./LeaveChart.css";

const LeaveChart = (props) => {
  const { chartData } = props;
  const colors = ["#EDC683", "#81DDC6", "#F35E58", "#50504E"];
  return (
    <>
      {/* <Chart
        chartType="PieChart"
        data={chartData}
        options={options}
      /> */}
      <CustomChart chartData={chartData} colors={colors} />
    </>
  );
};

export default LeaveChart;
