import React from "react";
import ContentLoader from "react-content-loader";

interface PROPS {
  loaderAlignX?: number;
}

const RectangularLoader = ({ loaderAlignX = 0 }: PROPS) => {
  return (
    <ContentLoader viewBox="0 0 700 70">
      {/* Only SVG shapes */}
      <rect
        x={loaderAlignX}
        y="0"
        rx="10"
        ry="20"
        width="80%"
        height="80%"
        style={{ marginTop: "15%" }}
      />
    </ContentLoader>
  );
};

export default RectangularLoader;
