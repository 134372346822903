import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import "react-circular-progressbar/dist/styles.css";
import {
  LEAVE_DETAILS_FORM_ID,
  MY_COMP_OFFS_FORM_ID,
} from "../../constants/formConstants";
import "./index.css";
import InputLabel from "@mui/material/InputLabel";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import MyLeaves from "./myLeaves";
import TeamLeaves from "./teamLeaves";
import ApplyLeaveModal from "./applyLeaveModal";
import MenuItem from "@mui/material/MenuItem";
import HolidaysList from "./holidaysList";
import {
  leaveManagementTabList,
  leaveManagementTabTypeEnums,
  leaveManagementTeamCompOffRequestTab,
  leaveManagementTeamLeavesTab,
  leaveManagementTeamWfhRequestTab,
  teamLeavesGroup,
} from "../uiElementsAccess";
import {
  FilterFormDetailsWithEmployeeId,
  FilterFormDetailsWithOfficalEmail,
} from "../../services/formService";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import UserOverview from "./UserOverview";
import ApplyCompOffRequestModal from "../ModalViews/ApplyCompOffRequestModal/applyCompOffRequestModal";
import { TabContext } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import MyCompOffs from "./MyCompOffs/myCompOffs";
import TeamCompOffRequest from "./TeamCompOffRequests/teamCompOffRequest";
import { Grid } from "@mui/material";
import UpcomingHoliday from "./UpcomingHoliday/upcomingHoliday";

const LeaveManagementSystem = () => {
  const [show, setShow] = useState(false);
  const [leaveChoice, setLeaveChoice] = useState("");
  const [year, setYear] = React.useState("2024");
  const [refresh, setRefresh] = useState(false);
  let userRole: any = sessionStorage.getItem("User-Role");

  const [isCompOffRequestModalOpen, setIsCompOffRequestModalOpen] =
    useState(false);

  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(event.target.value as string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const yearsList = ["2022", "2023", "2024"];
  let emailId: any = sessionStorage.getItem("user-email");

  // const [generalLeaves, setGeneralLeaves] = useState<any>({});
  // const [privilegeLeaves, setPrivilegeLeaves] = useState<any>({});
  const [selectedTab, setSelectedTab] = React.useState(
    leaveManagementTabTypeEnums.myLeaves
  );
  const [tabList, setTabList] = useState(leaveManagementTabList);

  const [empId, setEmpId] = useState("");
  // const [compOffData, setCompOffData] = useState({});
  // const wfhData = {
  //   heading: "WFH",
  //   infoText: "WFH",
  //   availableLeaves: 0,
  //   consumedLeaves: 0,
  //   accruedSoFar: 0,
  // };

  useEffect(() => {
    let lmsTabList = [...tabList];
    const teamLeavesIndex = tabList.findIndex(
      (tab) => tab.type === leaveManagementTabTypeEnums.teamLeaves
    );
    if (teamLeavesGroup.includes(userRole) && teamLeavesIndex === -1) {
      lmsTabList.push(leaveManagementTeamLeavesTab);
      lmsTabList.push(leaveManagementTeamWfhRequestTab);
      lmsTabList.push(leaveManagementTeamCompOffRequestTab);
    }

    setTabList([...lmsTabList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (empId) {
      fetchCompOffDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empId]);

  const setCompOffDetails = (data: any = null) => {
    // const compOffDetails = getCompOffDetails(data);
    // setCompOffData(compOffDetails);
  };

  const fetchLeavesDetail = async (formId, id) => {
    const response: any = await FilterFormDetailsWithOfficalEmail(formId, id);
    if (response.success && response?.data?.content?.length > 0) {
      // let generalLeavesData = {
      //   heading: "General Leaves",
      //   infoText:
      //     "All the Employees are eligible for general leave of 12 working days for each year of service.   An employee under probation period is eligible to take only one general leave in a month,and this leave will not be carry forwarded to the next month.   If there are any general leaves left at the end of the year, those leaves will be lapsed and will not be carry forwarded to the next year.  If an employee does not having general leaves remaining, and applying for leave will then result in loss of pay(LOP).",
      //   availableLeaves:
      //     response?.data?.content[0]?.formData?.leaveDetails?.general || 0,
      //   consumedLeaves:
      //     response?.data?.content[0]?.formData?.consumedLeaveDetails?.general ||
      //     0,
      //   accruedSoFar:
      //     response?.data?.content[0]?.formData?.accruedLeaveDetails?.general ||
      //     0,
      // };

      // let privilegeLeavesData = {
      //   heading: "Privilege Leaves",
      //   infoText:
      //     "Employees who have completed their probation period are the only ones eligible for privilege leave of 12 working days for each year.  An employee under probation period is not eligible to take privilege leave.  Privilege Leaves will be carry forwarded to next year.",
      //   availableLeaves:
      //     response?.data?.content[0]?.formData?.leaveDetails?.privilege || 0,
      //   consumedLeaves:
      //     response?.data?.content[0]?.formData?.consumedLeaveDetails
      //       ?.privilege || 0,
      //   accruedSoFar:
      //     response?.data?.content[0]?.formData?.accruedLeaveDetails
      //       ?.privilege || 0,
      // };
      // setGeneralLeaves(generalLeavesData);
      // setPrivilegeLeaves(privilegeLeavesData);
      setEmpId(response?.data?.content[0]?.formData?.employeeId);
    } else {
      // setGeneralLeaves({
      //   heading: "General Leaves",
      //   infoText:
      //     "All the Employees are eligible for general leave of 12 working days for each year of service.   An employee under probation period is eligible to take only one general leave in a month,and this leave will not be carry forwarded to the next month.   If there are any general leaves left at the end of the year, those leaves will be lapsed and will not be carry forwarded to the next year.  If an employee does not having general leaves remaining, and applying for leave will then result in loss of pay(LOP).",
      //   availableLeaves: 0,
      //   consumedLeaves: 0,
      //   accruedSoFar: 0,
      // });
      // setPrivilegeLeaves({
      //   heading: "Privilege Leaves",
      //   infoText:
      //     "Employees who have completed their probation period are the only ones eligible for privilege leave of 12 working days for each year.  An employee under probation period is not eligible to take privilege leave.  Privilege Leaves will be carry forwarded to next year.",
      //   availableLeaves: 0,
      //   consumedLeaves: 0,
      //   accruedSoFar: 0,
      // });

      setCompOffDetails();
    }
  };

  const fetchCompOffDetails = async () => {
    const response: any = await FilterFormDetailsWithEmployeeId(
      MY_COMP_OFFS_FORM_ID,
      empId
    );

    if (response.success && response?.data?.content?.length > 0) {
      setCompOffDetails(response?.data?.content);
    } else {
      setCompOffDetails();
    }
  };

  const handleApplyLeave = (leaveType) => {
    setShow(true);
    setLeaveChoice(leaveType);
  };

  const LeaveManagementTabs = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <TabContext value={selectedTab}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                sx={{
                  flexShrink: 0, // Prevent tabs from shrinking
                  "&.MuiTabs-root .MuiTabs-indicator": {
                    backgroundColor: "#F15830",
                    width: "5px",
                    borderRadius: "10px 0px 0px 10px",
                  },
                  "&.MuiTabs-root .Mui-selected": {
                    color: "#F15830",
                    fontWeight: "bold",
                  },
                  "& button:hover": { backgroundColor: "#E5E4E2" },
                  "@media (max-width: 960px)": {
                    // Apply styles for screens up to 960px width
                    flexDirection: "row",
                    minWidth: "auto",
                    flexShrink: 1,
                    "& .MuiTabs-flexContainer": {
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    },
                    "& .MuiTab-root": {
                      minWidth: "initial",
                    },
                  },
                }}
              >
                {tabList.map((tabListItem) => {
                  return (
                    <Tab
                      id={`lmsTab_${tabListItem.type}`}
                      label={tabListItem.label}
                      key={`lmsTab_${tabListItem.type}`}
                      value={tabListItem.type}
                      sx={{
                        "@media (min-width: 2000px)": {
                          fontSize: "calc(12px + 0.3rem)",
                        },
                        "@media (min-width: 1500px) and (max-width: 2000px)": {
                          fontSize: "calc(12px + 0.3rem)",
                        },
                        "@media (min-width: 1000px) and (max-width: 1500px)": {
                          fontSize: "calc(12px + 0.04rem)",
                        },
                        "@media (min-width: 600px) and (max-width: 1000px)": {
                          fontSize: "calc(12px + 0.004rem)",
                        },
                        "@media (max-width: 600px)": {
                          fontSize: "calc(12px + 0.0006rem)",
                        },
                        fontWeight: "600",
                        color: "black",
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
            {selectedTab === leaveManagementTabTypeEnums.holidayList && (
              <Box>
                <div className="years-list-div">
                  <FormControl>
                    <InputLabel id="year-label">Year</InputLabel>
                    <Select
                      size="small"
                      labelId="year-label"
                      id="yearLabel"
                      value={year}
                      onChange={handleYearChange}
                      label="Year"
                      sx={{ width: "80px" }}
                    >
                      {yearsList.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            )}
          </Box>
          <TabPanel
            value={leaveManagementTabTypeEnums.myLeaves}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <MyLeaves />
            </Box>
          </TabPanel>

          <TabPanel
            value={leaveManagementTabTypeEnums.wfhRequests}
            sx={{
              p: "unset",
              paddingTop: "20px",
              fontWeight: "1000",
              fontSize: "1",
            }}
          >
            <Box>
              <MyLeaves isWFH={true} />
            </Box>
          </TabPanel>

          <TabPanel
            value={leaveManagementTabTypeEnums.holidayList}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <HolidaysList year={year} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums.teamLeaves}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <TeamLeaves />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums.teamWfhRequests}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <TeamLeaves isWFH={true} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums.myCompOffs}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <MyCompOffs empId={empId} />
            </Box>
          </TabPanel>
          <TabPanel
            value={leaveManagementTabTypeEnums.teamCompOffRequest}
            sx={{ p: "unset", paddingTop: "20px" }}
          >
            <Box>
              <TeamCompOffRequest />
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    );
  };

  useEffect(() => {
    fetchLeavesDetail(LEAVE_DETAILS_FORM_ID, emailId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const handleApplyCompOffRequest = () => {
    setIsCompOffRequestModalOpen(true);
  };

  const closeCompOffRequestModal = () => {
    setIsCompOffRequestModalOpen(false);
  };

  useEffect(() => {
    if (!show && !isCompOffRequestModalOpen) {
      setRefresh((prev) => !prev);
    }
  }, [isCompOffRequestModalOpen, show]);

  return (
    <>
      {show ? (
        <ApplyLeaveModal
          show={show}
          setShow={setShow}
          leaveChoice={leaveChoice}
        />
      ) : null}

      {isCompOffRequestModalOpen ? (
        <ApplyCompOffRequestModal
          isModalOpen={isCompOffRequestModalOpen}
          closeModal={closeCompOffRequestModal}
          empId={empId}
        />
      ) : null}

      <div className="myDivLms">
        <div className="LeaveManagementWithoutProfileCard">
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item xs={12} md={12} lg={9.5}>
              <UserOverview
                handleApplyLeave={handleApplyLeave}
                handleApplyCompOffRequest={handleApplyCompOffRequest}
                updated={refresh}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={2.5}>
              <UpcomingHoliday />
            </Grid>
          </Grid>

          <div className="lmsLeaveTabsCards">
            <LeaveManagementTabs />
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementSystem;
