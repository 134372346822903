import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "./i18nextInit";

if (sessionStorage.getItem("react-token")) {
  let envs: any = sessionStorage.getItem("config");
  let config: any = JSON.parse(envs);
  ReactDOM.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  fetch(`${window.location.origin}${window.location.pathname}.json`)
    .then(async (r) => r.json())
    .then((config) => {
      ReactDOM.render(<App config={config} />, document.getElementById("root"));
    })
    .catch((error) => {
      ReactDOM.render(<div>{error}</div>, document.getElementById("root"));
    });
}
