import React, { useEffect } from "react";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CONSTANTS from "../../../../constants/constants";
import { JOB_DETAILS_FORM_ID } from "../../../../constants/formsConstants";
import { store } from "../../../../redux/store";
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from "../../../../services/formService";
import { useDispatch } from "react-redux";
import {
  BillableMsg,
  clientMsg,
  locationMsg,
  prevOrganizationMsg,
  roleMsg,
  startDateMsg,
  totalNoOfYearsMsg,
} from "./formConstants";
import { pushNotification } from "../../../../redux/actions";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "red",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "15px",
  },
}));

const Jobdetails = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const [formId, setFormId] = React.useState("");
  const classes = useStyles();
  const reduxState = store.getState();
  const dispatch = useDispatch();

  const clientList = [
    {
      title: "Techsophy",
      value: "techsophy",
    },
    {
      title: "Apple",
      value: "apple",
    },
  ];

  const initialValuesForForm = {
    prevOrganization: "",
    role: "",
    location: "",
    totalNumberOfYearsMonths: "",
  };

  const initialValuesForClient = {
    client: "",
    startDate: "",
    endDate: "",
    billable: "",
  };

  const [childArray]: any[] = React.useState([]);
  const [clientDetailsArray]: any[] = React.useState([]);

  const formOne = useFormik({
    initialValues: {
      previousExperience: childArray,
      clientDetails: clientDetailsArray,
    },
    validationSchema: Yup.object({
      previousExperience: Yup.array().of(
        Yup.object({
          prevOrganization: Yup.string().required(prevOrganizationMsg),
          role: Yup.string().required(roleMsg),
          location: Yup.string().required(locationMsg),
          totalNumberOfYearsMonths: Yup.string().required(totalNoOfYearsMsg),
        })
      ),
      clientDetails: Yup.array().of(
        Yup.object({
          client: Yup.string().required(clientMsg),
          startDate: Yup.date().required(startDateMsg),
          billable: Yup.string().required(BillableMsg),
        })
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },

    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    postFormValues(values);
  };

  const getError = (name: string) => {
    const error = getIn(formOne.errors, name);
    const touch = getIn(formOne.touched, name);
    return touch && error ? error : null;
  };

  const handleService = () => {
    let value = [
      ...formOne.values.previousExperience,
      { ...initialValuesForForm },
    ];
    formOne.setFieldValue("previousExperience", value, true);
  };

  const handleServiceRemove = (index) => {
    let value = formOne.values.previousExperience.slice();
    value.splice(index, 1);
    formOne.setFieldValue("previousExperience", value, true);
  };

  const handleServiceClient = () => {
    let value = [
      ...formOne.values.clientDetails,
      { ...initialValuesForClient },
    ];
    formOne.setFieldValue("clientDetails", value, true);
  };

  const handleServiceRemoveClient = (index) => {
    let value = formOne.values.clientDetails.slice();
    value.splice(index, 1);
    formOne.setFieldValue("clientDetails", value, true);
  };

  const formPayload = (values: any) => ({
    formId: JOB_DETAILS_FORM_ID,
    formData: {
      reportingTo: reduxState.reportingToEmailId,
      officialEmail: reduxState.officialEmailId,
      previousExperience: values.previousExperience,
      clientDetails: values.clientDetails,
    },
  });

  const postFormValues = async (values: any) => {
    const postData = formPayload(values);

    if (formId) {
      let payload = {
        formId: postData.formId,
        id: formId,
        formData: postData.formData,
      };
      const response = await postForm(payload);
      handleResponse(response);
    } else {
      const response = await postForm(postData);
      handleResponse(response);
    }
  };

  const handleResponse = (response: any) => {
    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setOpenFormExitPopup(false);
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  console.log(formOne.errors);

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      JOB_DETAILS_FORM_ID,
      reduxState.officialEmailId
    );

    if (response.success && response?.data?.content?.length > 0) {
      let mapData: any = response?.data?.content[0]?.formData;
      setFormId(response?.data?.content[0]?.id);
      let finalData = {
        previousExperience: mapData?.hasOwnProperty("previousExperience")
          ? mapData?.previousExperience
          : [],
        clientDetails: mapData?.hasOwnProperty("clientDetails")
          ? mapData?.clientDetails
          : [],
      };

      formOne.setValues(finalData);
    } else {
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={3}>
            <Typography color={"red"} width="100%">
              Previous Experience <Divider />
            </Typography>

            {formOne.values.previousExperience?.map((singleService, index) => (
              <div key={index}>
                <Grid xs={12}>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Prev Organization</label>
                      <TextField
                        id={`previousExperience[${index}].prevOrganization`}
                        size="small"
                        name={`previousExperience[${index}].prevOrganization`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={
                          formOne.values.previousExperience[index]
                            .prevOrganization
                        }
                        error={getError(
                          `previousExperience[${index}].prevOrganization`
                        )}
                        helperText={getError(
                          `previousExperience[${index}].prevOrganization`
                        )}
                        sx={{ width: "160px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Role</label>
                      <TextField
                        id={`previousExperience[${index}].role`}
                        size="small"
                        name={`previousExperience[${index}].role`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.previousExperience[index].role}
                        error={getError(`previousExperience[${index}].role`)}
                        helperText={getError(
                          `previousExperience[${index}].role`
                        )}
                        sx={{ width: "180px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Location</label>
                      <TextField
                        id={`previousExperience[${index}].location`}
                        size="small"
                        name={`previousExperience[${index}].location`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={
                          formOne.values.previousExperience[index].location
                        }
                        error={getError(
                          `previousExperience[${index}].location`
                        )}
                        helperText={getError(
                          `previousExperience[${index}].location`
                        )}
                        sx={{ width: "160px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Tenure</label>
                      <TextField
                        id={`previousExperience[${index}].totalNumberOfYearsMonths`}
                        size="small"
                        name={`previousExperience[${index}].totalNumberOfYearsMonths`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={
                          formOne.values.previousExperience[index]
                            .totalNumberOfYearsMonths
                        }
                        error={getError(
                          `previousExperience[${index}].totalNumberOfYearsMonths`
                        )}
                        helperText={getError(
                          `previousExperience[${index}].totalNumberOfYearsMonths`
                        )}
                        sx={{ width: "230px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} gap={2} mt={2}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Button onClick={() => handleServiceRemove(index)}>
                          <DeleteOutlineOutlinedIcon
                            sx={{ color: "#959595", marginTop: "19px" }}
                          />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} mt={1}></Grid>
              </div>
            ))}

            <button
              type="button"
              onClick={handleService}
              style={{
                marginTop: 15,
                height: "30px",
                background: "#808080",
              }}
            >
              <span style={{ color: "white", fontWeight: "bold" }}>
                + Add Experience
              </span>
            </button>
          </Grid>

          <Grid container gap={3} mt={4}>
            <Typography color={"red"} width="100%">
              Client Details <Divider />
            </Typography>

            {formOne.values.clientDetails?.map((singleService, index) => (
              <div key={index}>
                <Grid xs={12}>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>Client</label>
                      <FormControl sx={{ width: "170px" }} size="small">
                        <Select
                          id={`clientDetails[${index}].client`}
                          value={formOne.values.clientDetails[index].client}
                          onChange={(e) => {
                            formOne.setFieldValue(
                              `clientDetails[${index}].client`,
                              e.target.value,
                              true
                            );
                          }}
                          error={getError(`clientDetails[${index}].client`)}
                        >
                          {clientList.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ color: "red" }}>
                          {getError(`clientDetails[${index}].client`)}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>Start Date</label>
                      <TextField
                        type="date"
                        id={`clientDetails[${index}].startDate`}
                        size="small"
                        name={`clientDetails[${index}].startDate`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.clientDetails[index].startDate}
                        error={getError(`clientDetails[${index}].startDate`)}
                        helperText={getError(
                          `clientDetails[${index}].startDate`
                        )}
                        sx={{ width: "170px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </Box>

                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>End Date</label>
                      <TextField
                        type="date"
                        id={`clientDetails[${index}].endDate`}
                        size="small"
                        name={`clientDetails[${index}].endDate`}
                        onBlur={formOne.handleBlur}
                        onChange={formOne.handleChange}
                        value={formOne.values.clientDetails[index].endDate}
                        error={getError(`clientDetails[${index}].endDate`)}
                        helperText={getError(`clientDetails[${index}].endDate`)}
                        sx={{ width: "170px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </Box>

                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>Billable</label>
                      <FormControl sx={{ width: "170px" }} size="small">
                        <Select
                          id={`clientDetails[${index}].billable`}
                          value={formOne.values.clientDetails[index].billable}
                          onChange={(e) => {
                            formOne.setFieldValue(
                              `clientDetails[${index}].billable`,
                              e.target.value,
                              true
                            );
                          }}
                          error={getError(`clientDetails[${index}].billable`)}
                        >
                          <MenuItem value={"yes"}>{"Yes"}</MenuItem>
                          <MenuItem value={"no"}>{"No"}</MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: "red" }}>
                          {getError(`clientDetails[${index}].billable`)}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"} gap={2} mt={2}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Button
                          onClick={() => handleServiceRemoveClient(index)}
                        >
                          <DeleteOutlineOutlinedIcon
                            sx={{ color: "#959595", marginTop: "19px" }}
                          />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} mt={1}></Grid>
              </div>
            ))}

            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleServiceClient();
              }}
              style={{
                marginTop: 15,
                height: "30px",
                background: "#808080",
              }}
            >
              <span style={{ color: "white", fontWeight: "bold" }}>
                + Add Client
              </span>
            </button>
          </Grid>

          <Box className={classes.submitBtn}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
              // disabled={!isEmployeeAdded}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Jobdetails;
