import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, Button, useMediaQuery } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { leaveTypeNames } from "../../uiElementsAccess";

interface iCompOffRequestDialogProps {
  onApply: any;
  onClose: any;
  requestData: any;
  isDialogOpen: boolean;
  isActionButtonDisabled?: boolean;
}

const CompOffRequestDialog = ({
  isDialogOpen,
  requestData,
  onApply,
  onClose,
  isActionButtonDisabled,
}: iCompOffRequestDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle align="center" id="alert-dialog-title">
        Request to add a Compensatory Off
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 500,
            "@media (min-width: 2000px)": {
              fontSize: "calc(12px + 0.8rem)",
            },
            "@media (min-width: 1500px) and (max-width: 2000px)": {
              fontSize: "calc(12px + 0.4rem)",
            },
            "@media (min-width: 1000px) and (max-width: 1500px)": {
              fontSize: "calc(12px + 0.04rem)",
            },
            "@media (min-width: 600px) and (max-width: 1000px)": {
              fontSize: "calc(12px + 0.004rem)",
            },
            "@media (max-width: 600px)": {
              fontSize: "calc(12px + 0.0006rem)",
            },
          }}
        >
          <Box>
            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">Emp ID</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-end"}>
                {requestData.employeeId.join(" ")}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">Leave Type</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-end"}>
                {leaveTypeNames[requestData.leaveType]}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">From Date</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-end"}>
                {requestData.fromDate}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">To Date</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-end"}>
                {requestData.toDate}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">No of Days</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-end"}>
                {requestData.leaveCount}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"} gap={2}>
              <Box className={classes.labelText} justifyContent={"flex-start"}>
                <p className="applyLeaveHeader">Reason</p>
              </Box>
              &nbsp;:&nbsp;
              <Box className={classes.valueText} justifyContent={"flex-start"}>
                {requestData.reason}
              </Box>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onApply}
          disabled={isActionButtonDisabled}
        >
          Apply
        </Button>
        <Button
          variant="contained"
          onClick={onClose}
          autoFocus
          disabled={isActionButtonDisabled}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompOffRequestDialog;

const useStyles = makeStyles(() => ({
  labelText: {
    wordBreak: "break-word",
    width: "140px",
    marginRight: "30px",
    fontWeight: "bold",
    color: "black",
  },
  valueText: {
    wordBreak: "break-word",
    width: "200px",
    marginLeft: "30px",
  },
}));
