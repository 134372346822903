import React from "react";
import "./Dashboard.css"; // Import your CSS file
import CONSTANTS from "../../constants/constants";
import image1 from "../../assets/dashboardImages/Top-Img (1).png";
import image2 from "../../assets/dashboardImages/Top-Img (2).png";
import image3 from "../../assets/dashboardImages/Top-Img.png";
import { makeStyles } from "@mui/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import mydata from "../../assets/dashboardImages/Group (3).svg";
import myleaves from "../../assets/dashboardImages/Group 3.svg";
import experience from "../../assets/dashboardImages/Group 2.svg";
import myassets from "../../assets/dashboardImages/ICON.svg";
import myfinance from "../../assets/dashboardImages/Group 4.svg";
import admin from "../../assets/dashboardImages/Group (1).svg";
import product1 from "../../assets/dashboardImages/Rectangle 15.svg";
import product2 from "../../assets/dashboardImages/Rectangle 17.svg";
import product3 from "../../assets/dashboardImages/Rectangle 16.svg";
import product4 from "../../assets/dashboardImages/Rectangle 18.svg";
import Logo from "../../assets/dashboardImages/techsophy.svg";

const useStyles = makeStyles((theme) => ({
  headers: {
    height: "10%",
    width: "100%",
    display: "flex",
    backgroundColor: "#3464C1",
    color: "white",
  },
  body: {
    height: "90%",
    position: "relative",
    // marginTop:'1%'
  },
  carouselContainer: {
    width: "100%",
    position: "absolute",
    paddingBottom: "10px",
    // zIndex: 1,
    // background:"#000000",
  },

  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    height: "100%",
    textAlign: "center",
    marginTop: "0.5%",
    overflowY: "auto",
    maxHeight: "400px",
    variant: "scrollable",
    // marginLeft:'3%',
  },

  lastDiv: {
    width: "20%",
    padding: theme.spacing(2),
  },
  carouselImage: {
    width: "100%",
    height: "38vh",
    // objectFit: "cover",
    // top:'5%',
    margingBottom: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  imageDown: {
    // marginTop:'4%',
  },
  clickableDiv: {
    height: "12vh",
    borderRadius: "5%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "box-shadow 0.3s ease",
    flexDirection: "column",
    color: "inherit",
    textDecoration: "none",
  },

  icon: {
    width: "50px !important",
    height: "50px !important",
  },
  nameText: {
    fontSize: "15px",
  },
  cardhedings: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "Montserrat Bold",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "28px",
    color: "#162952",
    textAlign: "center",
  },
  footer: {
    color: "#007bff",
  },
  imageContainer: {
    position: "relative",
  },

  textOverlay: {
    position: "absolute",
    top: "58%",
    left: "24%",
    transform: "translate(-50%, -50%)",
    fontsize: "24px",
    color: " white",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
  };
  const images = [image1, image2, image3];
  const displayedButtons = [
    { title: "my data", icon: mydata, url: `${process.env.PUBLIC_URL}` },
    {
      title: "my leaves",
      icon: myleaves,
      url: `${process.env.PUBLIC_URL}/lms`,
    },
    { title: "experience", icon: experience, url: `${process.env.PUBLIC_URL}` },
    { title: "my assets", icon: myassets, url: `${process.env.PUBLIC_URL}` },
    { title: "my finance", icon: myfinance, url: `${process.env.PUBLIC_URL}` },
    { title: "admin", icon: admin, url: `${process.env.PUBLIC_URL}/admin` },
  ];
  const products = [
    { id: 1, title: "Task Management", image: product1 },
    { id: 2, title: "CyberSophy", image: product2 },
    { id: 3, title: "Visitor Pass", image: product3 },
    { id: 4, title: "Trovity", image: product4 },
  ];
  const navigate = useNavigate();
  const handleDivClick = (title: string) => {
    switch (title) {
      case "User-Profile":
        navigate("/user-profile");
        break;
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "leave-Management":
        navigate("/leave-management");
        break;
      case "ISMS-Policies":
        navigate("/isms-policies");
        break;
      default:
        break;
    }
  };
  // <div style={{ backgroundImage: `url('../../assets/dashboardimgs/bg.svg')` , width:'100%'}}>

  return (
    <div className="dashboard">
      <div className="header">
        <div className="headerimages">
          <img
            src={Logo}
            className="headerLogo"
            style={{ float: "left" }}
            alt="header-logo"
          />
          <div className="header-right">
            <div className="welcomemsg"> welcome</div>
            <div className="username">
              {" "}
              <b>{sessionStorage.getItem(CONSTANTS.USER_NAME)}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="scrollimages">
          <div className="background-image"></div>
          <div className="overlay-image">
            <div className={classes.carouselContainer}>
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div
                    key={index + 1}
                    className={
                      index === 1
                        ? `${classes.carouselImage} ${classes.imageDown}`
                        : classes.carouselImage
                    }
                  >
                    <div className={classes.imageContainer}>
                      <img
                        key={index + 1}
                        src={image}
                        alt={`${index + 1}`}
                        className={
                          index === 1
                            ? `${classes.carouselImage} ${classes.imageDown}`
                            : classes.carouselImage
                        }
                      />
                      <div className={classes.textOverlay}>
                        {index === 0 && (
                          <div>
                            <p>Elevate Productivity</p>
                            <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                              Master Tasks Effortlessly
                            </p>
                          </div>
                        )}
                        {index === 1 && (
                          <div>
                            <p>Elevate Productivity</p>
                            <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                              Master Tasks Effortlessly
                            </p>
                          </div>
                        )}
                        {index === 2 && (
                          <div>
                            <p>Empower Teams</p>
                            <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                              Master Tasks Effortlessly
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>

        <div className="apps-products">
          <div className="apps">
            {displayedButtons.map(({ title, icon, url }) => (
              <Link to={url} key={title} className="clickableDiv">
                <div onClick={() => handleDivClick(title)}>
                  {icon && <img src={icon} alt={title} className="icon" />}
                  <Typography variant="body2" className="nameText">
                    {title}
                  </Typography>
                </div>
              </Link>
            ))}
          </div>
          <div className="allproducts">
            <div className="productsHeading">Solutions</div>
            <div style={{ display: "flex", width: "100%" }}>
              {products.map((product) => (
                <div key={product.id} className="products">
                  <div>
                    <img
                      src={product.image}
                      alt={product.title}
                      className="proimg"
                    />
                  </div>
                  <div className="protitle">{product.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="footerleft">
          <div>
            <a href="/company">Company</a>
          </div>
          <div>
            <a href="/terms">Terms Of Use</a>
          </div>
          <div>
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div>
            <a href="/cookie">Cookie Policy</a>
          </div>

          <div className="footerright">
            <div>@Copyright © 2023 Techsophy</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
