import "./profileDetails.css";
import EmployeeModal from "../../EmployeeModal";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { pushNotification } from "../../../redux/actions";
import CONSTANTS, {
  EMP_360_HELP_Document,
  insuranceDashboardUrl,
} from "../../../constants/constants";
import CircularLoader from "../../../Loaders/CircularLoader";
import RectangularLoader from "../../../Loaders/ContentLoader/Loader";
import InputAdornment from "@mui/material/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  splitPhoneByCountryCode,
} from "../../../services/commonHelperService";
import { LocationDisplay } from "../../../components/common/GeoLocation";

interface iProfileDetailsProps {
  profileDetails: any;
  isLoading: boolean;
  isEmployeeModalOpen: boolean;
  setIsEmployeeModalOpen: any;
  menuAnchorElement: any;
  closeMenu: any;
  handleFileChange: any;
  handleOpenMenu: any;
  handleLinkedinPost: any;
  handleLinkedinData: any;
  linkedinUrl: any;
}

const styles = {
  modals: {
    employeeModal: {
      "&.MuiButtonBase-root .MuiButton-textSizeMedium": {
        "padding-left": "1.5em",
      },
    },
  },
  icons: {
    doneIcon: {
      cursor: "pointer",
      borderRadius: `50%`,
      padding: "2px",
      transition: "0.3s",
      "&:hover": {
        boxShadow: "0 0 12px gray",
        transition: "0.3s",
      },
    },
  },
  boxes: {
    helpDoc: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "18px",
    },
  },
};

const ProfileDetails = ({
  profileDetails,
  isLoading,
  isEmployeeModalOpen,
  setIsEmployeeModalOpen,
  menuAnchorElement,
  closeMenu,
  handleFileChange,
  handleOpenMenu,
  handleLinkedinPost,
  handleLinkedinData,
  linkedinUrl,
}: iProfileDetailsProps) => {
  const dispatch = useDispatch();
  const open = Boolean(menuAnchorElement);
  const fileTypes = ["JPG", "PNG", "JPEG"];
  const { profilePicture } = useSelector((state: any) => ({
    profilePicture: state.profilePicture,
  }));

  const [linkedInData, setLinkedInData] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [toolTipText, setToolTipText] = useState("Edit");
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);

  const setAndhandleLinkedinData = (event) => {
    handleLinkedinData(event);
  };
  const postLinkedinUrl = () => {
    handleLinkedinPost();
    setIsEditing(false);
    setToolTipText("Edit");
    setIsToolTipVisible(false);
  };

  useEffect(() => {
    setLinkedInData(linkedinUrl);
  }, [linkedinUrl]);

  const handleEditClick = () => {
    setIsEditing(true);
    setToolTipText("Save");
    setIsToolTipVisible(false);
  };

  const getDataContent = (label, value) => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="profile-details-item">
          <span className="profile-details-label">{label}</span>

          {isLoading ? (
            <RectangularLoader />
          ) : (
            <Tooltip
              title={value}
              TransitionComponent={Zoom}
              placement="bottom"
              arrow
            >
              <span className="profile-details-value">{value}</span>
            </Tooltip>
          )}
        </div>
      </Grid>
    );
  };

  const downloadHelpDocumentation = () => {
    window.open(EMP_360_HELP_Document, "_blank");
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorElement}
        open={open}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <>
          <Box display={"flex"} flexDirection={"column"} padding={2}>
            <Box mb={3} fontSize={18} fontWeight={"bold"}>
              Upload Profile Picture
            </Box>

            <FileUploader
              multiple={false}
              handleChange={handleFileChange}
              name="file"
              maxSize={2}
              types={fileTypes}
              onTypeError={(error) => {
                dispatch(
                  pushNotification({
                    isOpen: true,

                    message: `${error}, supported files type are "JPG", "PNG", "JPEG"`,

                    type: CONSTANTS.ERROR,
                  })
                );
              }}
            />
          </Box>
        </>
      </Menu>

      <div className="profile-details-card">
        <div className={"emp-details-container emp-location-container"}>
          <span className="emp-location">{/* <LocationDisplay /> */}</span>
        </div>
        <div className="edit-button-container">
          <EmployeeModal
            show={isEmployeeModalOpen}
            setShow={setIsEmployeeModalOpen}
            sx={styles.modals.employeeModal}
          />
        </div>

        {isLoading ? (
          <div className={"profile-avatar-loading-container"}>
            <CircularLoader />
          </div>
        ) : (
          <>
            <div className="profile-avatar">
              <div
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "50%",
                  padding: "13px",
                }}
              >
                <div style={{ border: "1px solid grey", borderRadius: "50%" }}>
                  <IconButton onClick={handleOpenMenu}>
                    <img
                      src={
                        profilePicture
                          ? `data:image/jpeg;base64,${profilePicture}`
                          : "https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg"
                      }
                      className="card-img-top"
                      alt="Profile-pcture-home-emp"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={"emp-details-container"}>
          {isLoading ? (
            <RectangularLoader loaderAlignX={80} />
          ) : (
            <span className="emp-name">{profileDetails?.fullName}</span>
          )}
        </div>

        <div className={"emp-details-container"}>
          {isLoading ? (
            <RectangularLoader loaderAlignX={80} />
          ) : (
            <span className="emp-designation">{profileDetails?.role}</span>
          )}
        </div>

        <div className={"emp-details-container"}>
          {isLoading ? (
            <RectangularLoader loaderAlignX={80} />
          ) : (
            <span className="emp-designation">
              {" "}
              <LocationDisplay />
            </span>
          )}
        </div>

        <Grid container spacing={2} className={"profile-detail-grid-container"}>
          {getDataContent("Emp ID", profileDetails?.empId)}
          {getDataContent("Email", profileDetails?.emailId)}
          {getDataContent(
            "Phone",

            profileDetails?.phone !== "..."
              ? splitPhoneByCountryCode("in", profileDetails?.phone)
              : profileDetails?.phone
          )}
          {getDataContent("Location", profileDetails?.location)}
          {getDataContent("Date of Birth", formatDate(profileDetails?.dob))}
          {getDataContent("Marital Status", profileDetails?.maritalStatus)}
          {getDataContent("Blood Group", profileDetails?.bloodGroup)}
          {getDataContent(
            "Emergency No",
            profileDetails?.emergencyNo !== "..."
              ? splitPhoneByCountryCode("in", profileDetails?.emergencyNo)
              : profileDetails?.emergencyNo
          )}
          {getDataContent("Reporting To", profileDetails?.reportingTo)}
          {getDataContent("Department", profileDetails?.department)}
          {getDataContent(
            "Date of Joining",
            formatDate(profileDetails?.dateOfJoining)
          )}
        </Grid>

        <div className={"linkedin-container"}>
          <TextField
            sx={{
              width: `${isEditing ? "80%" : "50%"}`,
            }}
            disabled={!isEditing}
            id="outlined-basic"
            variant="outlined"
            value={linkedInData}
            onChange={setAndhandleLinkedinData}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="icon"
                  />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={toolTipText} open={isToolTipVisible}>
                    {!isEditing ? (
                      <EditIcon
                        fontSize="medium"
                        sx={{
                          cursor: "pointer",
                          borderRadius: `50%`,
                          padding: "2px",
                          transition: "0.3s",
                          "&:hover": {
                            boxShadow: "0 0 12px gray",
                            transition: "0.3s",
                          },
                        }}
                        onClick={handleEditClick}
                        color={"primary"}
                      />
                    ) : (
                      <DoneIcon
                        fontSize="medium"
                        sx={styles.icons.doneIcon}
                        onClick={postLinkedinUrl}
                        color={"primary"}
                      />
                    )}
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </div>

        <Box sx={styles.boxes.helpDoc}>
          <div className="help-documentation">
            <Tooltip
              title={"Download EMP 360 Help Document"}
              TransitionComponent={Zoom}
              placement="bottom"
              arrow
            >
              <span
                className="help-documentation-text"
                onClick={downloadHelpDocumentation}
              >
                EMP360 Help Doc
              </span>
            </Tooltip>
          </div>
          <div className="help-documentation">
            <Tooltip
              title={"View your Insurance Dashboard"}
              TransitionComponent={Zoom}
              placement="bottom"
              arrow
            >
              <a
                href={insuranceDashboardUrl}
                className="help-documentation-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Insurance Dashboard
              </a>
            </Tooltip>
          </div>
          {/* <div className={"emp-details-container"}>
            {isLoading ? (
              <RectangularLoader loaderAlignX={80} />
            ) : (
              <span className="emp-location">
                <LocationDisplay />
              </span>
            )}
          </div> */}
        </Box>
      </div>
    </>
  );
};

export default ProfileDetails;
