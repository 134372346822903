import { TEMPLATE_UPLOAD, TEMPLATE_DOWNLOAD } from "../constants/endpoints";
import { ResponseProps } from "./request";
import { request } from "./request";
import { MetaInfo } from "../types/types";
import { DELETE_DOCUMENT } from "../constants/constants";
import axios from "axios";

// export const TEMPLATE_UPLOAD_ENDPOINT = `${sessionStorage.getItem(
//   "gatewayURL"
// )}${TEMPLATE_UPLOAD}`;
// console.log("myurl",TEMPLATE_UPLOAD_ENDPOINT)
// export const TEMPLATE_DOWNLOAD_ENDPOINNT = `${sessionStorage.getItem(
//   "gatewayURL"
// )}${TEMPLATE_DOWNLOAD}`;

export const uploadFileApi = async (
  fileName: string,
  file: File,
  documentPath: string,
  meta?: MetaInfo
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${fileName}`);
  const params = {
    documentName: fileName,
    file: fileOfBlob,
    documentPath: documentPath,
    documentDescription: "",
    metaInfo: JSON.stringify(meta),
  };

  const r: ResponseProps = (await request.postForm(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}${TEMPLATE_UPLOAD}`,
    params
  )) as ResponseProps;
  if (r && r.success) {
    return { success: true, data: r.data, message: r.message };
  }
  return { success: false, data: {}, message: "" };
};

export const downloadTemplateApi = async (
  id: string
): Promise<{ success: boolean; message?: string; blobFile?: any }> => {
  const response = (await request.getBlob(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}${TEMPLATE_DOWNLOAD}/${id}`
  )) as ResponseProps;

  if (response && response.success) {
    return { success: true, blobFile: response.data };
  }

  return { success: false };
};
// export default {};

export const deleteDMSDocument = async (id: string) => {
  const apiEndpoint = `${sessionStorage.getItem(
    "gatewayURL"
  )}${DELETE_DOCUMENT}${id}`;
  axios
    .delete(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
