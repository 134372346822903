import { iSwipeCardSystem } from "../types/types";

export const COMMON = {
  IND_LABEL: "IND",
  US_LABEL: "US",
  LOGO_LABEL: "Logo",
  GET_TO_KNOW: "KNOW about company",
  ABOUT_US: "About Us",
  CAREER: "Careers",
  PRICING: "Pricing",
  LEGAL_FINANCIALS: "Legal & Financials",
  GET_INVOLVED: "GET INVOLVED",
  SEEK_SUPPORT: "Seek Support",
  REVIEW_TERMS: "Review Terms",
  CONTACT_US: "Contact Us",
  HELP_CENTER: "Help Center",
  NEWS_LETTER: "Newsletter",
  PRESS_MEDIA: "Press & Media",
  FAQS: "FAQS",
  SUPPORT: "Support",
  PRIVACY_POLICY: "Privacy Policy",
  SITE_MAP: "Site Map",
  WEBSITE: "Website URL",
  FACEBOOK_URL: "Facebook URL",
  YOUTUBE_URL: "Youtube URL",
  TWITTER_URL: "Twitter URL",
  INSTAGRAM_URL: "Instagram URL",
  LINKEDIN_URL: "Linkedin URL",
  DOCUMENT_NO: "Document No",
  ANONYMOUS: "anonymous",
  USER_TYPE: "user-type",
  COMPANY: "Company",
  TERMS_OF_USE: "Terms of use",
  COOKIE_POLICY: "Cookie policy",
};

export const DELETE = "delete";
export const GET = "get";
export const POST = "post";
export const PUT = "put";

export const OPEN_SPINNER = "OPEN_SPINNER";
export const CLOSE_SPINNER = "CLOSE_SPINNER";
export const EMAIL = "Email";
export const SHARE = "Share";
export const DOWNLOAD = "Download";
export const STATUS = "Status";
export const ACTIVE = "Active";
export const IN_PROGRESS = "In Progress";
export const REQUIRED = "Required";
export const DATE_FILTERS = [
  "All Time",
  "Last 30 Days",
  "Last 90 Days",
  "Year Till Date",
  "Last Year",
  "Custom Date",
];
export const FUNDRAISER_FILTERS = [
  "Fundraisers",
  "Donations",
  "Donors",
  "Withdrawals",
  "All",
];

export const cardSwipeSystem: iSwipeCardSystem = {
  cardSwipeManagement: "Card swipe Management",
  cardSwipeDetails: "Card Swipe Details",
  clockInEntireDay: "Clock in (Entire Day)",
  clockOutEntireDay: "Clock out (Entire Day)",
  swipeInDataNotAvailable: "Alert! Swipe in Data not available.",
  swipeOutDataNotAvailable: "Alert! Swipe out Data not available.",
  selectEmployee: "Select Employee",
  employee1: "Employee 1",
  employee2: "Employee 2",
  employee3: "Employee 3",
  dateRange: "Date Range",
  oneWeeek: "1 Week",
  fifteenDays: "15 Days",
  oneMonth: "1 Month",
  threeMonths: "3 months",
  sixMonths: "6 months",
  oneYear: "1 Year",
  date: "date",
  day: "Day",
  project: "Project",
  clockIn: "Clock in",
  clockOut: "Clock out",
  firstSwipeIn: "First Swipe-in",
  lastSwipeIn: "Last Swipe-out",
  total: "Total",
  selectEmployeeToShowSwipeData:
    "Please Select an employee to view card swipe data",
};

export default COMMON;
