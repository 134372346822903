import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { openFile } from "../services/DocumentService";
import { ISMS_FORM_ID } from "../constants/formConstants";
import { fetchRuntimeFormData } from "../services/formService";
import { CircularProgress } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { ISMS_INFO } from "../constants/constants";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`vertical-tabpanel-${index}`}
//       aria-labelledby={`vertical-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `vertical-tab-${index}`,
//     "aria-controls": `vertical-tabpanel-${index}`,
//   };
// }

export default function Policies() {
  const [value, setValue] = React.useState(0);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [formData, setFormData] = React.useState<any>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleGetPdf = async (id) => {
    setPdfUrl("");
    const response = await openFile(id);

    setPdfUrl(response);
  };

  const handlePrefillData = async () => {
    const response = await fetchRuntimeFormData(ISMS_FORM_ID);
    if (response?.success) {
      setFormData(response?.data?.content[0]?.formData?.data);
      handleGetPdf(response?.data?.content[0]?.formData?.data[0]?.fileId);
    } else {
      setFormData([]);
    }
  };

  React.useEffect(() => {
    handlePrefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.container}>
      <Box height={"8"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={2}
          pt={1}
          sx={styles.policiesHeading}
          borderRadius={"10px 10px 0 0"}
        >
          ISMS Policies
          <Tooltip title={ISMS_INFO}>
            <InfoOutlinedIcon fontSize="small" sx={{ marginLeft: "10px" }} />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={styles.tabsContainer}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="policies"
          sx={styles.tabsColumnStyles}
        >
          {formData.map((item, index) => {
            return (
              <Tab
                id={index}
                label={item?.title}
                // icon={<ChevronRightIcon sx={{ fontSize: "17px" }} />}
                // iconPosition="start"
                onClick={() => handleGetPdf(item?.fileId)}
                sx={styles.tabStyles}
                wrapped
              />
            );
          })}
        </Tabs>
        <Box sx={styles.pdfContainer(pdfUrl)}>
          {pdfUrl ? (
            <iframe
              title="PDF Preview"
              width="100%"
              height="98%"
              frameBorder="0"
              src={pdfUrl}
              style={{}}
            />
          ) : (
            <Box
              sx={{
                p: 3,
              }}
              mt={"23vh"}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={6}
                width={"100%"}
                height={"100%"}
              >
                <CircularProgress size={100} sx={{ color: "red" }} />
                <Box sx={{ color: "#162952" }}>Loading...</Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: "100%",
    height: "100vh",
    padding: "0px 2vw 1vw 1vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgb(227, 231, 239)",
  },
  policiesHeading: {
    backgroundColor: "#ffff",
    font: "Roboto",
    color: "#162952",
    fontWeight: 300,
    fontSize: "18px",
    "@media (max-width: 960px)": {
      // Apply styles for screens up to 960px width
      marginLeft: "7vw",
    },
  },
  tabsContainer: {
    flexGrow: 1,
    bgcolor: "background.paper",
    display: "flex",
    height: "90%",
    gap: "20px",
    justifyContent: "center",
    borderRadius: "0 0 10px 10px",
    "@media (max-width: 960px)": {
      // Apply styles for screens up to 960px width
      display: "flex",
      flexDirection: "column",
    },
  },
  tabsColumnStyles: {
    borderRight: 1,
    borderColor: "divider",
    minWidth: "20vw", // Set a minimum width for the tabs
    flexShrink: 0, // Prevent tabs from shrinking
    "&.MuiTabs-root .MuiTabs-indicator": {
      backgroundColor: "#F15830",
      width: "5px",
      borderRadius: "10px 0px 0px 10px",
    },
    "&.MuiTabs-root .Mui-selected": {
      color: "#F15830",
    },
    "& button:hover": { backgroundColor: "#E5E4E2" },

    // "& button[aria-selected='false']": {
    //   border: "1px solid black",
    // },
    "@media (max-width: 960px)": {
      // Apply styles for screens up to 960px width
      display: "none",
      "& .MuiTabs-flexContainer": {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      },
      "& .MuiTab-root": {
        minWidth: "initial",
      },
    },
  },
  tabStyles: {
    fontSize: "0.9vw",
    alignItems: "start",
    textAlign: "start",
  },
  pdfContainer: (pdfUrl) => {
    return {
      flexGrow: "1",
      overflowY: "auto",
      // Adjust the maximum width based on the tabs width
      "@media (max-width: 600px)": {
        // Apply styles for screens up to 600px width
        maxWidth: "98%",
      },
      display: "flex",
      justifyContent: `${pdfUrl ? "center" : "flex-start"}`,
      alignItems: "center",
      flexDirection: "column",
    };
  },
};
