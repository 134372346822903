import { request } from "./request";

export const getRoles = async (): Promise<{
  success: boolean;
  formData?: any;
  message?: string;
}> => {
  const res: any = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/auth/realms/${sessionStorage.getItem(
      "realm"
    )}/protocol/openid-connect/userinfo`
  );
  if (res) {
    return { success: true, formData: res, message: "" };
  }
  return { success: false };
};
