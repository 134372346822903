import React from "react";
import Keycloak from "keycloak-js";
import { ThemeProvider } from "@mui/material/styles";
import { INITIAL_THEME } from "./theme";
import Navigator from "./components/navigation/Navigator";
import Wrapper from "./components/layout/wrapper";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CONSTANTS from "./constants/constants";
import createGenerateClassName from "@mui/styles/createGenerateClassName";
import { StylesProvider } from "@mui/styles";
import { BrowserRouter as Router } from "react-router-dom";

// !--------- KEYCLOAK IMPORT -------------
import { ReactKeycloakProvider } from "@react-keycloak/web";

const KeycloakWrapper = (props) => {
  const { config } = props;

  const generateClassName = createGenerateClassName({
    // disableGlobal: true,
    // productionPrefix: 'prod_form_mfe-',
    seed: "empMaster",
  });

  // !--------- KEYCLOAK CODE -------------

  const keycloak = new Keycloak({
    realm: `${config.realm}`,
    url: `${config.authURL}auth/`,
    clientId: `${config.clientId}`,
  });

  const setTokens = () => {
    const { token, refreshToken, idTokenParsed } = keycloak;
    if (token && refreshToken && idTokenParsed) {
      sessionStorage.setItem(CONSTANTS.USER_EMAIL, idTokenParsed.email);
      sessionStorage.setItem(CONSTANTS.FIRST_NAME, idTokenParsed.given_name);
      sessionStorage.setItem(CONSTANTS.LAST_NAME, idTokenParsed.family_name);
      sessionStorage.setItem(CONSTANTS.USER_ID, idTokenParsed.sub);
      sessionStorage.setItem(CONSTANTS.REACT_TOKEN, token);
      sessionStorage.setItem(CONSTANTS.USER_TYPE, "user");
      sessionStorage.setItem("realm", config.realm);
      // sessionStorage.setItem("authURL", config.authURL);
      // sessionStorage.setItem("clientId", config.clientId);
      sessionStorage.setItem("gatewayURL", config.gatewayURL);
      sessionStorage.setItem("keycloakURL", config.authURL);
      sessionStorage.setItem("suprsetDomain", config.suprsetDomain);
      sessionStorage.setItem("suprsetDashboardID", config.suprsetDashboardID);
      sessionStorage.setItem("userName", idTokenParsed.preferred_username);
      sessionStorage.setItem("publicURL", "/");
      sessionStorage.setItem("config", JSON.stringify(config));
    }
  };

  const refreshAccessToken = () => {
    keycloak
      .updateToken(50)
      .success((refreshed) => {
        if (refreshed) {
          setTokens();
        }
      })
      .error(() => {
        keycloak.logout();
        sessionStorage.clear();
      });
  };

  const handleEvent = (event) => {
    if (event === "onAuthSuccess") {
      setTokens();
    }

    if (event === "onTokenExpired") {
      refreshAccessToken();
    }

    if (event === "onAuthLogout") {
      sessionStorage.clear();
    }
  };
  // !----------------------------------------------

  const getAppChildren = () => (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={INITIAL_THEME}>
        <Router>
          <Provider store={store}>
            <Wrapper>
              <Navigator />
              {/* <LoadSxpChat /> */}
            </Wrapper>
          </Provider>
        </Router>
      </ThemeProvider>
    </StylesProvider>
  );

  return (
    <>
      <ReactKeycloakProvider
        initOptions={{
          onLoad: "login-required",
          checkLoginIframe: false,
        }}
        authClient={keycloak}
        onEvent={handleEvent}
      >
        <RenderOnAuthenticated>{getAppChildren()}</RenderOnAuthenticated>
      </ReactKeycloakProvider>
    </>
  );
};

export default KeycloakWrapper;
