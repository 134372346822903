import { request, ResponseProps } from "../services/request";
import { DELETE_DOCUMENT, DOWNLOAD_DOCUMENT } from "../constants/constants";
import axios from "axios";

export const uploadDocument = async (url, data) => {
  const r: ResponseProps = (await request.postForm(
    `${sessionStorage.getItem("gatewayURL")}${url}`,
    data
  )) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};

export const deleteDocument = async (id) => {
  const r: ResponseProps = (await request.delete(
    `${sessionStorage.getItem("gatewayURL")}${DELETE_DOCUMENT}${id}`
  )) as ResponseProps;

  if (r.success) {
    return { success: r.success, message: r.message, data: r.data };
  }

  return { success: false };
};

export const downloadDocument = async (id: string) => {
  const token = sessionStorage.getItem("react-token");

  const response = await axios.get(
    `${sessionStorage.getItem("gatewayURL")}${DOWNLOAD_DOCUMENT}${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,

        responseType: "blob", // VERY IMPORTANT 'arrayBuffer'
      },
    }
  );

  return response;
};

export const openFile = async (id: string) => {
  let Token = sessionStorage.getItem("react-token");
  return fetch(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/dms/v1/documents/download?id=${id}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      return fileURL;
    });
};

export const downloadFile = async (id: string, fileName: string) => {
  let Token = sessionStorage.getItem("react-token");
  fetch(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/dms/v1/documents/download?id=${id}`,
    {
      method: "get",
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    }
  )
    .then((res) => res.blob())
    .then((blobData) => {
      const fileURL = URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    });
};

// export const bulkUpload = async (file) => {
//   const apiEndpoint = `${process.env.REACT_APP_API_GATEWAY_URL}/emp360-backend/v1/upload?isCreatingUser=true`;
//   const formData = new FormData();
//   formData.append("file", file);
//   // formData.append("documentName", file.name);
//   return axios.post(apiEndpoint, formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
//     },
//   });
// };

export const bulkUpload = async (file) => {
  const formData = new FormData();

  formData.append("file", file);

  formData.append("documentName", file.name);

  return axios.post(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/upload?isCreatingUser=true`,
    formData,
    {
      responseType: "blob",

      headers: {
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
      },
    }
  );
};

export const bulkDownload = async () => {
  try {
    const Token = sessionStorage.getItem("react-token");
    const response = await fetch(
      `${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/employee-list/download`,
      {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${Token}`,
        }),
      }
    );
    const blobData = await response.blob();
    const fileURL = URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", "BulkDownload.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(fileURL);
    return {
      success: true,
      message: "Master Data has been downloaded successfully !",
    };
  } catch (error) {
    return { success: false, message: "Master Data Download Failed" };
  }
};

// export const bulkDownload = async () => {
//   let Token = sessionStorage.getItem("react-token");
//   fetch(
//     `${process.env.REACT_APP_API_GATEWAY_URL}/emp360-backend/v1/employee-list/download`,
//     {
//       method: "get",
//       headers: new Headers({
//         Authorization: `Bearer ${Token}`,
//       }),
//     }
//   )
//     .then((res) => res.blob())
//     .then((blobData) => {
//       const fileURL = URL.createObjectURL(blobData);
//       const link = document.createElement("a");
//       link.href = fileURL;
//       link.setAttribute("download", "BulkDownload.csv"); //or any other extension
//       document.body.appendChild(link);
//       link.click();
//       // clean up "a" element & remove ObjectURL
//       document.body.removeChild(link);
//       URL.revokeObjectURL(fileURL);
//       return { success: true, message: "Bulk Download Success" };
//     })
//     .catch((error) => {
//       return { success: false, message: "Bulk Download Success" };
//     });
// };

export const HolidaysUpload = async (file) => {
  const formData = new FormData();

  formData.append("file", file);

  return axios.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/uploadHolidays`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
      },
    }
  );
};
