import React from "react";
import Box from "@mui/material/Box";
import FormsDetails from "../formsDetail";
import { useDispatch } from "react-redux";
import { setOfficialEmailId, setReportingToEmailId } from "../../redux/actions";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { FormExitPopupActions } from "../FormExitPopup";

const style = {
  formDetailsBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    bgcolor: "#fff",
    boxShadow: 24,
    overflowY: "scroll",
    height: "90vh",
    display: "block",
  },
  buttons: {
    editButton: { float: "right" },
  },
  icons: {
    modeEditOutlinedIcon: { float: "right", color: "red" },
  },
};

const EmployeeModal = (props) => {
  const { show, setShow } = props;
  // const [isNcg, setIsNcg] = React.useState<any>(
  //   reduxState.roles.includes("NCG")
  // );
  // const [isFinance, setIsFinance] = React.useState<any>(
  //   reduxState.roles.includes("Finance")
  // );

  // const [isSoftwareArchitect, setIsSoftwareArchitect] = React.useState<any>(
  //   reduxState.roles.includes("Software Architect")
  // );
  // const [isTechnicalArchitect, setIsTechnicalArchitect] = React.useState<any>(
  //   reduxState.roles.includes("Technical Architect")
  // );

  // const [isHrAdmin, setIsHrAdmin] = React.useState<any>(
  //   reduxState.roles.includes("HR Admin")
  // );

  const dispatch = useDispatch();
  // const [show, setShow] = useState(false);

  const [openFormExitPopup, setOpenFormExitPopup] = React.useState(false);
  const [isOpenFormExitPopup, setIsOpenExitPopup] = React.useState(false);

  const handleCloseForm = () => {
    setIsOpenExitPopup(openFormExitPopup);
    if (!openFormExitPopup) {
      closeModal(false);
    }
  };
  const closeModal = (value: any) => {
    setShow(value);
    dispatch(setOfficialEmailId(""));
    dispatch(setReportingToEmailId(""));
  };

  const formExitCallBackAction = (action: any) => {
    if (action === FormExitPopupActions.CANCEL) setIsOpenExitPopup(false);
    else if (action === FormExitPopupActions.LEAVE) closeModal(false);
  };

  return (
    <>
      <Button onClick={() => setShow(true)} sx={style.buttons.editButton}>
        <ModeEditOutlinedIcon sx={style.icons.modeEditOutlinedIcon} />
      </Button>

      <Modal open={show} onClose={handleCloseForm}>
        <Box sx={style.formDetailsBox}>
          <FormsDetails
            setOpen={setShow}
            closeModal={setShow}
            openFormExitPopup={openFormExitPopup}
            setOpenFormExitPopup={setOpenFormExitPopup}
            isOpenFormExitPopup={isOpenFormExitPopup}
            setIsOpenExitPopup={setIsOpenExitPopup}
            formExitCallBackAction={formExitCallBackAction}
            handleCloseForm={handleCloseForm}
          />
        </Box>
      </Modal>
    </>
  );
};
export default EmployeeModal;
