export const modalAdminGroup = ["Admin"];
export const modalLeadGroup = [
  "Software Architect",
  "Technical Lead",
  "emp-lead",
];
export const modalFinanroup = ["emp-finance"];
export const modalNcgGroup = ["emp-ncg"];
export const modalHrAdminGroup = [
  "Lead HR",
  "Associate HR",
  "HR Admin",
  "HRAdmin",
  "emp-hrAdmin",
];

export const createEmployeeBtnGroup = [
  "emp-hrAdmin",
  "HRAdmin",
  "HR Admin",
  "Admin",
  "Lead HR",
  "Associate HR",
];

export const teamLeavesGroup = [
  "emp-lead",
  "Software Architect",
  "Technical Lead",
  "QA Manager",
  "Technical Architect",
];

export const addLeaveGroup = ["emp-hrAdmin"];

export const addCompOffGroup = ["emp-hrAdmin", "emp-lead"];

const leaveTypeEnums = {
  GENERAL: "general",
  PRIVILEGE: "privilege",
  COMP_OFF: "compOff",
  WFH: "WFH",
};

const leaveTypeNames = {
  general: "General",
  privilege: "Privilege",
  compOff: "Comp Off",
  WFH: "Work From Home",
};

const leaveTypes = [
  {
    value: leaveTypeEnums.GENERAL,
    title: "General",
  },
  {
    value: leaveTypeEnums.PRIVILEGE,
    title: "Privilege",
  },
];

const leaveManagementTabTypeEnums = {
  myLeaves: "MY_LEAVES",
  holidayList: "HOLIDAY_LIST",
  teamLeaves: "TEAM_LEAVES",
  myCompOffs: "MY_COMP_OFFS",
  teamCompOffRequest: "TEAM_COMP_OFF_REQUESTS",
  teamWfhRequests: "TEAM_WFH_REQUESTS",
  wfhRequests: "WFH_REQUESTS",
};

const leaveManagementTabTypeNames = {
  HOLIDAY_LIST: "Holiday List",
  TEAM_LEAVES: "Team Leaves",
  MY_LEAVES: "My Leaves",
  MY_COMP_OFFS: "Comp-Off Requests",
  TEAM_COMP_OFF_REQUESTS: "Team Comp Off Requests",
  TEAM_WFH_REQUESTS: "Team WFH Requests",
  WFH_REQUESTS: "WFH Requests",
};

const leaveManagementTabList = [
  {
    label: leaveManagementTabTypeNames.MY_LEAVES,
    type: leaveManagementTabTypeEnums.myLeaves,
  },
  {
    label: leaveManagementTabTypeNames.WFH_REQUESTS,
    type: leaveManagementTabTypeEnums.wfhRequests,
  },
  {
    label: leaveManagementTabTypeNames.HOLIDAY_LIST,
    type: leaveManagementTabTypeEnums.holidayList,
  },
  {
    label: leaveManagementTabTypeNames.MY_COMP_OFFS,
    type: leaveManagementTabTypeEnums.myCompOffs,
  },
];

const leaveManagementTeamLeavesTab = {
  label: leaveManagementTabTypeNames.TEAM_LEAVES,
  type: leaveManagementTabTypeEnums.teamLeaves,
};

const leaveManagementTeamCompOffRequestTab = {
  label: leaveManagementTabTypeNames.TEAM_COMP_OFF_REQUESTS,
  type: leaveManagementTabTypeEnums.teamCompOffRequest,
};

const leaveManagementTeamWfhRequestTab = {
  label: leaveManagementTabTypeNames.TEAM_WFH_REQUESTS,
  type: leaveManagementTabTypeEnums.teamWfhRequests,
};

export {
  leaveTypes,
  leaveTypeEnums,
  leaveTypeNames,
  leaveManagementTabTypeEnums,
  leaveManagementTabTypeNames,
  leaveManagementTabList,
  leaveManagementTeamLeavesTab,
  leaveManagementTeamCompOffRequestTab,
  leaveManagementTeamWfhRequestTab,
};
