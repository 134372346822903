import "./upcomingHoliday.css";
import { formatDate } from "../../../services/commonHelperService";
import React, { useEffect, useState } from "react";
import { fetchRuntimeFormData } from "../../../services/formService";
import { HOLIDAYS_FORMID } from "../../../constants/constants";
import RectangularLoader from "../../../Loaders/ContentLoader/Loader";

const UpcomingHoliday = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [holidayList, setHolidayList] = useState<any>([]);

  const fetchHolidayList = async () => {
    setIsLoading(true);
    const currentYear = new Date().getFullYear();

    try {
      const response = await fetchRuntimeFormData(HOLIDAYS_FORMID);

      if (response?.success) {
        let currentYearHolidayList =
          response?.data?.content[0]?.formData?.holidayList[currentYear];

        let upcomingHolidays = currentYearHolidayList.filter(
          (holiday) => new Date(holiday.Date) > new Date()
        );
        setHolidayList(upcomingHolidays);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHolidayList();
  }, []);

  const upcomingHoliday: any = holidayList.length ? holidayList[0] : null;

  const setBackgroundImage = () => {
    if (upcomingHoliday) {
      const container: HTMLElement | null =
        document.getElementById("holiday-container");
      if (container) {
        container.style.backgroundImage = `url("${upcomingHoliday.imageUrl}")`;
      }
    }
  };

  const getContent = () => {
    setBackgroundImage();
    return upcomingHoliday ? (
      <div className={"holiday-container"}>
        <div className={"holiday-heading"}>Next Holiday:</div>
        <div className={"holiday-text"}>{upcomingHoliday?.Purpose}</div>
        <div className={"holiday-date"}>
          {formatDate(upcomingHoliday?.Date)}
        </div>
      </div>
    ) : (
      <div className={"holiday-container"}>
        <span className={"holiday-text"}>
          No Upcoming Holiday in the Current Year.
        </span>
      </div>
    );
  };

  return (
    <div className={"upcoming-holiday-container"} id={"holiday-container"}>
      {isLoading ? (
        <div className={"holiday-container"}>
          <div className={"holiday-heading"}>Next Holiday:</div>
          <RectangularLoader loaderAlignX={80} />
        </div>
      ) : (
        <>{getContent()}</>
      )}
    </div>
  );
};

export default UpcomingHoliday;
