import React, { useEffect } from "react";
// import Sidebar from "ts-sidemenu/dist/Sidebar";
// import Home from "./Home";
import Group360 from "../utils/images/360Group.svg";
import { Box } from "@mui/material";

import Sidebar from "../components/navigation/SideBar";
import { getRoles } from "../services/roleService";
import { useDispatch, useSelector } from "react-redux";
import { setProfilePicture, setRolesData } from "../redux/actions";
import { sortAndIndexTabs, unifiedTabs } from "../appTabs";
import { getUserPreferences } from "../services/userService";
import { getMenuById, getMenusAndRoles } from "../services/MenuService";

const SideMenu = () => {
  const dispatch = useDispatch();
  const { profilePictureRefresh } = useSelector((state: any) => ({
    profilePictureRefresh: state.profilePictureRefresh,
  }));
  const [activeTab, setActiveTab] = React.useState(0);
  const [myTabs, setMyTabs] = React.useState<any>([]);

  const [userPicture, setUserPicture] = React.useState("");

  React.useEffect(() => {
    handleProfilePicture();
    if (myTabs.length <= 0) handleMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTabs, profilePictureRefresh]);

  const handleProfilePicture = async () => {
    const ProfileResponse = await getUserPreferences();
    setUserPicture(ProfileResponse?.data?.profilePicture);
    dispatch(setProfilePicture(ProfileResponse?.data?.profilePicture));
  };

  // const doLogout = () => {
  //   navigate(`/${sessionStorage.getItem("realm")}`);
  //   keycloak.logout();
  //   sessionStorage.clear();
  // };

  const logo = {
    title: "logo",
    icon: Group360,
  };

  const profileLogo = {
    title: "logo",
    icon: userPicture
      ? `data:image/jpeg;base64,${userPicture}`
      : "https://icon-library.com/images/default-user-icon/default-user-icon-13.jpg",
  };

  const tabStyles = {
    activeIconColor: "#F15830",
    activeIndicatorColor: "#F15830",
    scrollButtonColor: "#F15830",
    menusBackgroundColor: "#fff",
  };

  const handleLogoClick = (e) => {
    // console.log(e);
  };

  // Function to fetch menu details for all IDs
  const fetchMenusFromIds = async (ids) => {
    if (myTabs?.length <= 0)
      try {
        // Create an array of promises
        const fetchPromises = ids?.map((id) => getMenuById(id));

        // Wait for all promises to resolve
        const menus = await Promise.all(fetchPromises);

        // Filter out any null results if errors occurred
        return menus
          ?.filter((menu) => menu !== null)
          ?.map((menuPromise: any) => {
            const menuTabs = unifiedTabs?.filter(
              (tab) => menuPromise?.data?.label === tab.title
            );
            return menuTabs[0];
          });
      } catch (error) {
        return []; // Return empty array or handle error as needed
      }
  };

  const getMenuListFor = (menusAndRoles: any, roleName: string) => {
    return menusAndRoles
      ?.filter((value: any) => value?.role === roleName)
      ?.map((value: any) => value?.menus)[0];
  };
  const setMenuTabs = (
    menusAndRoles: any,
    roleName: string,
    isVisitorPassRolesAssigned: boolean
  ) => {
    const menuIdsList = getMenuListFor(menusAndRoles, roleName);
    const alltabsPromise = fetchMenusFromIds(menuIdsList);
    if (myTabs?.length <= 0)
      alltabsPromise?.then((value) => {
        var resultTabs = value;
        if (!isVisitorPassRolesAssigned) {
          resultTabs = resultTabs?.filter((tab) => tab?.title !== "Visitors");
        }
        const sortedTabs = sortAndIndexTabs(resultTabs);
        setMyTabs(sortedTabs);
      });
  };

  const handleMenus = async () => {
    const rolesData = await getRoles();
    dispatch(setRolesData(rolesData?.formData?.clientRoles));
    const menusAndRoles: any = await getMenusAndRoles();
    // const allMenus: any = await getAllMenus();

    let mapData = rolesData?.formData?.clientRoles;
    // if (mapData?.includes("Software Engineer")) {
    //   setMyTabs(employeeTabs);
    // } else if (mapData?.includes("emp-hrAdmin")) {
    //   setMyTabs(hrAdminTabs);
    // } else if (mapData?.includes("HRAdmin")) {
    //   setMyTabs(hrAdminTabs);
    // } else if (mapData?.includes("Associate HR")) {
    //   setMyTabs(employeeTabs);
    // } else if (mapData?.includes("Lead HR")) {
    //   setMyTabs(employeeTabs);
    // } else if (mapData?.includes("Associate Software Engineer")) {
    //   setMyTabs(employeeTabs);
    // } else if (mapData?.includes("emp-ncg")) {
    //   setMyTabs(ncgTabs);
    // } else if (mapData?.includes("emp-finance")) {
    //   setMyTabs(financeTabs);
    // } else if (mapData?.includes("emp-lead")) {
    //   setMyTabs(technicalArchitectTabs);
    // } else if (mapData?.includes("Software Architect")) {
    //   setMyTabs(softwareArchitectTabs);
    // } else if (mapData?.includes("Technical Lead")) {
    //   setMyTabs(softwareArchitectTabs);
    // } else if (mapData?.includes("QA Manager")) {
    //   setMyTabs(softwareArchitectTabs);
    // } else {
    //   setMyTabs(defaultTabs);
    // }
    const isVisitorPassRolesAssigned =
      mapData?.includes("ts-visitor-user") ||
      mapData?.includes("ts-visitor-reception") ||
      mapData?.includes("ts-visitor-admin");

    if (
      mapData?.includes("Software Engineer") ||
      mapData?.includes("Associate HR") ||
      mapData?.includes("Lead HR") ||
      mapData?.includes("Associate Software Engineer")
    ) {
      setMenuTabs(menusAndRoles, "Employee", isVisitorPassRolesAssigned);
    } else if (
      mapData?.includes("emp-hrAdmin") ||
      mapData?.includes("HRAdmin")
    ) {
      setMenuTabs(menusAndRoles, "HRAdmin", isVisitorPassRolesAssigned);
    } else if (
      mapData?.includes("emp-ncg") ||
      mapData?.includes("emp-finance") ||
      mapData?.includes("emp-lead")
    ) {
      setMenuTabs(
        menusAndRoles,
        "NcgFinanceEmpLead",
        isVisitorPassRolesAssigned
      );
    } else if (
      mapData?.includes("Software Architect") ||
      mapData?.includes("Technical Lead") ||
      mapData?.includes("QA Manager")
    ) {
      setMenuTabs(
        menusAndRoles,
        "SoftwareArchitectTechnicalLeadQAManager",
        isVisitorPassRolesAssigned
      );
    } else {
      setMenuTabs(menusAndRoles, "default", isVisitorPassRolesAssigned);
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Box>
        {/* <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={doLogout}>Logout</MenuItem>
        </Menu> */}
        {myTabs ? (
          <Sidebar
            tabs={myTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabStyles={tabStyles}
            logo={logo}
            profileLogo={profileLogo}
            handleLogoClick={handleLogoClick}
            // handleProfileLogoClick={handleProfileLogoClick}
          />
        ) : null}
      </Box>
    </>
  );
};

export default SideMenu;
