// DateOperations.tsx

import dayjs from "dayjs";

// Function to store date-time in ISO 8601 format
export const convertDateTimeToISOString = (dateTime: dayjs.Dayjs): string => {
  const isoString = dateTime.toISOString();

  // Store isoString in your database
  return isoString;
};

// export const convertDateTimeToISOString = (dateTime: dayjs.Dayjs): string => {
//   const offset = dateTime.utcOffset();
//   const localDateTime = dateTime.add(offset, 'minute');
//   const isoString = localDateTime.toISOString();
//   return isoString;
// };
// Convert ISO string to Dayjs object
export const convertISOStringToDayjs = (isoString: string): dayjs.Dayjs => {
  return dayjs(isoString);
};

// Function to render date-time for user display
export const renderDateTimeForUser = (dateTime: dayjs.Dayjs): string => {
  const formattedDateTime = dateTime.format("dddd, MMMM D, YYYY h:mm A");
  return formattedDateTime;
};

// Function to parse date-time from database string
export const parseDateTimeFromDatabase = (
  dateTimeString: string
): dayjs.Dayjs => {
  const parsedDateTime = dayjs(dateTimeString);
  return parsedDateTime;
};

export const getFormattedDateTime = (date: Date) => {
  //   const options: Intl.DateTimeFormatOptions = {
  //     weekday: "long", // 'long', 'short', 'narrow'
  //     year: "numeric", // 'numeric', '2-digit'
  //     month: "long", // 'numeric', '2-digit', 'long', 'short', 'narrow'
  //     day: "numeric", // 'numeric', '2-digit'
  //   };

  //   const dateString = date.toLocaleString("en-US", options);

  //   console.log("dateSTring", dateString); // Example output: "Tuesday, July 10, 2024"
  const dateString = renderDateTimeForUser(dayjs(date));

  const hours = date.getHours();
  const minutes = date.getMinutes();

  let time;
  if (hours >= 12) {
    time = `${hours === 12 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")} PM`;
  } else {
    time = `${hours === 0 ? 12 : hours}:${minutes
      .toString()
      .padStart(2, "0")} AM`;
  }

  return { dateString, time };
};

export const isWithin30Minutes = (startTime, endTime, selectedDate) => {
  // Parse the startTime, endTime, and currentDateTime as Date objects
  const start = new Date(startTime);
  const end = new Date(endTime);
  const currentDate = new Date().getDate();
  const selectedDateObj = new Date(selectedDate);
  // console.log(
  //   "isWithin30Minutes",
  //   start.getTime(),
  //   end.getTime(),
  //   currentDate,
  //   selectedDateObj
  // );

  // if (start.getTime() <= new Date().getTime()) return true;

  /*const current = new Date(currentDateTime);

  // Check if startTime is before the currentDateTime
  if (start < current) {
    return false;
  }*/

  // Calculate the time limit (30 minutes) in milliseconds
  const thirtyMinutesInMs = 30 * 60 * 1000;

  // Calculate the end of the allowed time range
  const timeLimit = new Date(start.getTime() + thirtyMinutesInMs);

  // Check if endTime is within the 30 minute range
  if (selectedDateObj.getDate() === currentDate)
    return start.getTime() <= new Date().getTime() || end <= timeLimit;
  return end <= timeLimit;
};

export function replaceDateInTimestring(
  date: string,
  timestring: string
): string {
  // Split the timestring at 'T'
  const [, timePart] = timestring.split("T");

  // Construct the new timestring with the given date and the original time part
  const newTimestring = `${date}T${timePart}`;

  return newTimestring;
}

export const formatISOToDate_Time = (isoString: string) => {
  if (isoString === "") return "-";
  const date = new Date(isoString);

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Determine AM/PM and convert hours
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // The hour '0' should be '12'

  // Format time
  const time = `${hours}:${minutes} ${ampm}`;

  // Format date
  const formattedDate = `${month}/${day}/${year} - ${time}`;

  return formattedDate;
};

// // Example usage
// const isoString = "2024-09-04T16:27:00Z";
// console.log(formatISODate(isoString)); // Output: "09/04/2024;4:27 PM"
