import React, { useEffect, useState } from "react";
import "./myLeaves.css";
import { Box, Button, Typography } from "@mui/material";
import { MYLEAVE_DETAILS_FORM_ID } from "../../constants/formConstants";
import { CancelAppliedLeave } from "../../services/lmsService";
import { FilterFormDetailsWithOfficalEmail } from "../../services/formService";
import { pushNotification } from "../../redux/actions";
import CONSTANTS from "../../constants/constants";
import { useDispatch } from "react-redux";
import { capitalize, formatDate } from "../../services/commonHelperService";
import ConfirmationModal from "../../components/common/ConfirmationModal/ConfirmationModal";
import { leaveTypeEnums } from "../uiElementsAccess";
import { GridColDef } from "@mui/x-data-grid";
import TsDatagrid from "../../components/TsDatagrid";
import dayjs from "dayjs";

const MyLeaves = ({ isWFH = false }) => {
  let emailId: any = sessionStorage.getItem("user-email");
  const dispatch = useDispatch();
  const [myLeavesData, setMyLeavesData] = useState<any>([]);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (id) => {
    setSelectedRowId(id);
    setIsConfirmationModalOpen(true);
  };
  const handleClose = () => {
    setIsConfirmationModalOpen(false);
    setSelectedRowId("");
  };

  useEffect(() => {
    handleGetLeavesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetLeavesData = async () => {
    setIsLoading(true);
    const response: any = await FilterFormDetailsWithOfficalEmail(
      MYLEAVE_DETAILS_FORM_ID,
      emailId
    );

    if (response.success && response?.data?.content?.length > 0) {
      let finalData = response?.data?.content?.map((item) => ({
        id: item?.id,
        fromDate: item?.formData?.fromDate,
        toDate: item?.formData?.toDate,
        totalDays: item?.formData?.totalDays,
        status: item?.formData?.status,
        comments: item?.formData?.reason,
        lop: item?.formData?.lopDays,
        leaveType: item?.formData?.leaveType,
        actions: "",
        appliedOn: item?.createdOn,
        approvedOn:
          Boolean(item?.formData?.approvedOn) &&
          item?.formData?.approvedOn !== ""
            ? item?.formData?.approvedOn
            : "-",
      }));
      setMyLeavesData(finalData);
      setIsLoading(false);
    } else {
      setMyLeavesData([]);
      setIsLoading(false);
    }
  };

  const handleCancelLeave = async (id) => {
    setIsButtonDisabled(true);
    const response = await CancelAppliedLeave(id);
    if (response.success) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setIsConfirmationModalOpen(false);
      setIsButtonDisabled(false);
      setSelectedRowId("");
      handleGetLeavesData();
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      setIsButtonDisabled(false);
      setSelectedRowId("");
    }
  };

  const filteredLeaveList = myLeavesData.filter((leaveItem) => {
    if (isWFH) {
      return leaveItem.leaveType === leaveTypeEnums.WFH;
    } else {
      return leaveItem.leaveType !== leaveTypeEnums.WFH;
    }
  });

  const getTableHeader = (title) => {
    return (
      <Typography sx={dataGridStyles.tableHeaderStyle}>{title}</Typography>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "fromDate",
      headerName: "From",
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row) => formatDate(row?.value),
      flex: 1,
    },
    {
      field: "toDate",
      headerName: "To",
      minWidth: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row) => formatDate(row?.value),
      flex: 1,
    },
    {
      field: "totalDays",
      headerName: "No.of.days",
      minWidth: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row) => capitalize(row?.value),
      flex: 1,
    },
    {
      field: "leaveType",
      headerName: "Leave Type",
      minWidth: 150,
      editable: false,
      hide: isWFH,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row) => capitalize(row?.value),
      flex: 1,
    },
    {
      field: "lop",
      headerName: "Lop Days",
      editable: false,
      minWidth: 150,
      hide: isWFH,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      flex: 1,
    },
    {
      field: "appliedOn",
      headerName: "Applied On",
      editable: false,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      valueFormatter: (row: any) => {
        const value = dayjs(new Date(row?.value))
          .format("DD/MM/YYYY - hh:mm a")
          .replace("am", "AM")
          .replace("pm", "PM");
        if (value === "Invalid Date") return "-";
        else return value;
      },
      flex: 1,
    },
    {
      field: "approvedOn",
      headerName: "Approved on",
      minWidth: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      // valueFormatter: (row: any) => {
      //   const value = dayjs(new Date(row?.value)).format("DD/MM/YYYY");
      //   if (value === "Invalid Date") return "-";
      //   else return value;
      // },
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      editable: false,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      flex: 1,
    },

    {
      field: "actions",
      headerName: "Actions",
      editable: false,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (props) => {
        const id = props?.id;
        const status = props?.row?.status;
        return (
          <Box display={"flex"} gap={3}>
            <Button
              variant="contained"
              size="small"
              sx={actionColumnStyle.cancelButtonStyle}
              onClick={() => handleOpen(id)}
              disabled={
                status &&
                (status === "cancelled" ||
                  status === "approved" ||
                  status === "rejected")
              }
            >
              Cancel
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          id="confirmationModal"
          title="Cancel Leave"
          message="Do you want to cancel this Leave?"
          open={isConfirmationModalOpen}
          onClose={handleClose}
          onSubmit={() => handleCancelLeave(selectedRowId)}
          isSubmitButtonDisabled={isButtonDisabled}
        />
      )}

      <div>
        <Box className="myLeavescontainer">
          <TsDatagrid
            rows={filteredLeaveList}
            columns={columns}
            pageSize={10}
            pageSizeArray={[10, 20, 30]}
            getSelectedRowsData={() => {}}
            handlePageChange={() => {}}
            handlePageSizeChange={() => {}}
            isCheckboxSelection={false}
            totalElements={filteredLeaveList.length}
            sx={dataGridStyles.tableStyle}
            components={{
              NoRowsOverlay: () => (
                <Box sx={dataGridStyles.overlayStyle}>
                  {isLoading ? " Loading Leaves" : "No Requests Available"}
                </Box>
              ),
            }}
          />
        </Box>
      </div>
    </>
  );
};

export default MyLeaves;

const actionColumnStyle = {
  cancelButtonStyle: {
    color: "#C04A2B",
    backgroundColor: "#EDEDED",
    width: "50px",
    textTransform: "capitalize",
  },
};

const dataGridStyles = {
  tableStyle: {
    height: "500px",
  },
  overlayStyle: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  tableHeaderStyle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};
