import axios from "axios";
import {
  DMS_ENDPOINT,
  RUNTIME_FORM_ENDPOINT,
  WORKFLOW_ENDPOINT,
  GENERAL_FORM_ENDPOINT,
} from "../constants/endpoints";
import { PAGINATION } from "../constants/formConstants";
import { request, ResponseProps } from "./request";
import wretch from "wretch";
import CONSTANTS from "../constants/constants";

export const RUNTIME_FORM_API_ENDPOINT = `${sessionStorage.getItem(
  "gatewayURL"
)}${RUNTIME_FORM_ENDPOINT}`;
export const GENERAL_FORM_API_ENDPOINT = `${sessionStorage.getItem(
  "gatewayURL"
)}${GENERAL_FORM_ENDPOINT}`;

export const DMS_API_ENDPOINT = `${sessionStorage.getItem(
  "gatewayURL"
)}${DMS_ENDPOINT}`;
export const WORKFLOW_URL = `${sessionStorage.getItem(
  "gatewayURL"
)}${WORKFLOW_ENDPOINT}`;

export const postForm = async (
  formDetails
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}${RUNTIME_FORM_ENDPOINT}`,
    formDetails
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const postFormGeneral = async (
  formDetails
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}${GENERAL_FORM_ENDPOINT}`,
    formDetails
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const fetchRuntimeFormDetails = async (
  id: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem("gatewayURL")}/form-runtime/v1/forms/${id}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const fetchRuntimeFormData = async (
  id: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${id}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const fetchFormDataWithId = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${formId}&filter=formData.officialEmail:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};
export const fetchFormDataWithIdForPersonal = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${formId}&filter=id:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }
  return { success: false };
};

export const fetchFormDataWithFilter = async (
  formId: string,
  id: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${formId}&filter=formData.officialEmail:${id}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};
export const postFormDataWithId = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}${RUNTIME_FORM_ENDPOINT}`,
    bodyPayload
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const patchFormData = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}) => {
  return axios.post(
    `${sessionStorage.getItem("gatewayURL")}/form-runtime/v1/form-data`,
    bodyPayload,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
      },
    }
  );
};

export const postFormData = async (bodyPayload: {
  formId: string;
  formData: any;
}) => {
  return axios.post(
    `${sessionStorage.getItem("gatewayURL")}form-runtime/v1/form-data`,
    bodyPayload
  );
};

export const filterFormDataUsingParentId = async (
  formId: string,
  parentDataId: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${formId}&filter=formData.officialEmail:${parentDataId}`
  );

  if (r.success) {
    const form = r.data;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const patchDataToForm = async (bodyPayload: {
  formId: string;
  id: string;
  formData: any;
}) => {
  return axios.patch(
    `${sessionStorage.getItem("gatewayURL")}/form-runtime/v1/form-data`,
    bodyPayload,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
      },
    }
  );
};

export const GenericProcess = async (
  processPayload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: any = (await request.post(WORKFLOW_URL, processPayload)) as any;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

export const FilterFormDetailsWithOfficalEmail = async (
  id: string,
  email: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${id}${PAGINATION}&filter=formData.officialEmail:"${email}"`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const getAllEmployeesInfo = async (
  page: number,
  size: number
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
}> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/relation?page=${page}&size=${size}&relations=1052917923916066816:officialEmail,1062244463782965248:officialEmail&formId=1058380750487334912`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const AddEmployeePost = async (
  payload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.post(
    `${sessionStorage.getItem("gatewayURL")}/emp360-backend/v1/new-employee`,
    payload
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: r.message };
};

// export const deactiveEmployeePost = async (
//   email: string,
//   generalFormId: string,
//   leaveFormId: string,
//   lwd: string,
//   deativationRemarks: string
// ): Promise<{ success: boolean; data?: any; message?: string }> => {
//   const r: ResponseProps = (await request.post(
//     `${sessionStorage.getItem(
//       "gatewayURL"
//     )}/emp360-backend/v1/makeEmployeeInactive?email=${email}&generalFormId=${generalFormId}&leaveFormId=${leaveFormId}&lwd=${lwd}`,
//     null
//   )) as ResponseProps;

//   if (r.success) {
//     const form = r.data as any;
//     return { success: r.success, data: form, message: r.message };
//   }

//   return { success: false, message: r.message };
// };
export const deactiveEmployeePost = async (
  payload: any
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await wretch(
      `${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/makeEmployeeInactive`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .post(payload)
      .json();
    if (response.success) {
      const form = response.data as any;
      return {
        success: response.success,
        data: form,
        message: response.message,
      };
    }
    return { success: false, message: response.message };
  } catch (error: any) {}
  return { success: false, message: "Some internal error occured" };
};

export const FilterFormDetailsWithEmployeeId = async (
  id: string,
  empId: string,
  compOffId?: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/form-runtime/v1/form-data?formId=${id}${PAGINATION}&filter=formData.employeeId:${empId}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false };
};

export const postLiveLocation = async (location) => {
  const url = `${sessionStorage.getItem(
    "gatewayURL"
  )}/emp360-backend/v1/post-location`;

  try {
    const response = await wretch(url)
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .query({ location })
      .post()
      .res(); // Use .res() to handle the response directly

    if (response.status !== 200) {
      console.log(`Failed to post location, status code: ${response.status}`);
    } else {
      console.log("Location posted successfully:", response);
    }
  } catch (error: any) {
    console.error("Error posting location:", error.message);
    // Handle error properly here, e.g., showing a user-friendly message or retry logic
  }
};
