import React, { useEffect } from "react";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  fetchRuntimeFormData,
  FilterFormDetailsWithOfficalEmail,
} from "../../services/formService";
import { ASSETS_FORM_ID } from "../../constants/formConstants";
import {
  additionalDetailsMsg,
  assetMsg,
  brandMsg,
  conditionMsg,
  issueDateMsg,
  serialNumberMsg,
} from "./formConstants";
import { SKILLSET_VALUES_FORM_ID } from "../../constants/formsConstants";
import CustomDatePicker from "./CustomDatePicker";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "red",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "15px",
  },
}));

const Assets = (props: any) => {
  const { setOpenFormExitPopup } = props;
  // const [id, setId] = React.useState<any>();
  let emailId: any = sessionStorage.getItem("user-email");

  const classes = useStyles();
  const [childArray] = React.useState<any>([]);

  let minimumDate = new Date();
  minimumDate.setDate(minimumDate.getDate() - 14);

  const [assestType, setAssestType] = React.useState<any>([
    {
      title: "Laptop",
      value: "laptop",
    },
    {
      title: "Desktop",
      value: "desktop",
    },
    {
      title: "Keyboard",
      value: "keyboard",
    },
    {
      title: "Mouse",
      value: "mouse",
    },
    {
      title: "Mobile",
      value: "mobile",
    },
    {
      title: "Monitor",
      value: "monitor",
    },
  ]);

  const [operatingSystemTypes, setOperatingSystemTypes] = React.useState<any>([
    {
      title: "Ubuntu",
      value: "ubuntu",
    },
    {
      title: "Windows",
      value: "windows",
    },
    {
      title: "Android",
      value: "android",
    },
  ]);

  const [brandNames, setBrandNames] = React.useState<any>([
    {
      title: "Lenovo",
      value: "lenovo",
    },
    {
      title: "Apple",
      value: "apple",
    },
    {
      title: "Hp",
      value: "hp",
    },
    {
      title: "Dell",
      value: "dell",
    },
    {
      title: "Asus",
      value: "asus",
    },
  ]);

  const handleGetAssestsData = async () => {
    const response = await fetchRuntimeFormData(SKILLSET_VALUES_FORM_ID);

    if (response?.success) {
      setAssestType(response?.data?.content[0]?.formData?.deviceType);
      setBrandNames(response?.data?.content[0]?.formData?.brand);
      setOperatingSystemTypes(response?.data?.content[0]?.formData?.os);
    } else {
      setAssestType(assestType);
      setBrandNames(brandNames);
      setOperatingSystemTypes(operatingSystemTypes);
    }
  };

  const formChild = useFormik({
    initialValues: {
      members: childArray,
    },
    validationSchema: Yup.object({
      members: Yup.array().of(
        Yup.object({
          asset: Yup.string().required(assetMsg),
          brand: Yup.string().required(brandMsg),
          operatingSystem: Yup.string(),
          serialNumber: Yup.string().required(serialNumberMsg),
          condition: Yup.string().required(conditionMsg),
          issueDate: Yup.string().required(issueDateMsg),
          additionalDetails: Yup.string().required(additionalDetailsMsg),
        })
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const initialValuesForForm = {
    asset: "",
    brand: "",
    operatingSystem: "",
    serialNumber: "",
    condition: "",
    issueDate: "",
    additionalDetails: "",
  };

  // const [serviceList, setServiceList] = React.useState<any>([]);

  const getError = (name: string) => {
    const error = getIn(formChild.errors, name);
    const touch = getIn(formChild.touched, name);
    return touch && error ? error : null;
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    // postFormValues(values);
  };

  // const handleService = () => {
  //   formChild.setValues((prevValues) => ({
  //     members: [...prevValues.members, { ...initialValuesForForm }],
  //   }));
  //   setServiceList([...serviceList, { service: "" }]);
  // };

  const handleService = () => {
    // formOne.setValues((prevValues) => ({
    //   childrenData: [...prevValues.childrenData, { ...initialValuesForForm }],
    // }));
    // let value = [...formChild.values.members, { ...initialValuesForForm }];
    let value = [...formChild.values.members, { ...initialValuesForForm }];
    formChild.setFieldValue("members", value, true);
  };

  const handleServiceRemove = (index) => {
    let value = formChild.values.members;
    value.splice(index, 1);
    formChild.setFieldValue("members", value, true);
  };

  // const formPayload = (values: any) => ({
  //   formId: ASSETS_FORM_ID,
  //   formData: {
  //     reportingTo: reduxState.reportingToEmailId,
  //     officialEmail: sessionStorage.getItem("user-email"),
  //     // parentDataId: reduxState.parentDataId,
  //     assets: values.members,
  //   },
  // });

  // const postFormValues = async (values: any) => {
  //   const postData = formPayload(values);

  //   if (reduxState.parentDataId) {
  //     let payload = {
  //       formId: postData.formId,
  //       id: id,
  //       formData: postData.formData,
  //     };
  //     const response = await patchFormData(payload);
  //     if (response) {
  //       setValue((prevState) => prevState + 1);
  //       setOpenFormExitPopup(false);
  //     } else {
  //     }
  //   } else {
  //     const response = await postForm(postData);

  //     if (response.success) {
  //       // dispatch(setParentDataId(response.data.id));
  //       setValue((prevState) => prevState + 1);
  //       setOpenFormExitPopup(false);
  //     } else {
  //     }
  //   }
  // };

  useEffect(() => {
    prefillData();
    handleGetAssestsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChild.values]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      ASSETS_FORM_ID,
      emailId
    );
    if (response.success && response?.data?.content?.length > 0) {
      // setId(response?.data?.content[0]?.id);
      let mapData: any = response?.data?.content[0]?.formData;

      let finalData = {
        members: mapData?.assets || [],
      };

      formChild.setValues(finalData);

      // setServiceList(mapData.assets);
    } else {
      formChild.setValues({
        members: [],
      });

      // setServiceList([]);
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={formChild.handleSubmit}>
          <Grid container gap={3}>
            <Typography
              color={"#f15830"}
              width="100%"
              sx={{
                "@media (min-width: 2000px)": {
                  fontSize: "calc(12px + 1.4rem)",
                },
                "@media (min-width: 1500px) and (max-width: 1999px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 1000px) and (max-width: 1499px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 600px) and (max-width: 999px)": {
                  fontSize: "calc(12px + 0.4rem)",
                },
                "@media (max-width: 600px)": {
                  fontSize: "calc(12px + 0.2rem)",
                },
              }}
            >
              Assets <Divider />
            </Typography>

            {formChild?.values?.members?.map((child, index) => (
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection: "row",
                    // gap: 5,
                    // marginTop: 1,
                    // height: "auto"
                  }
                }
                key={index}
              >
                <Grid xs={12}>
                  <Box display={"flex"} flexDirection={"row"} gap={1}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Asset</label>
                      <FormControl sx={{ width: "150px" }} size="small">
                        <Select
                          id={`members[${index}]?.asset`}
                          value={formChild?.values?.members[index]?.asset}
                          onChange={(e) => {
                            formChild.setFieldValue(
                              `members[${index}]?.asset`,
                              e.target.value,
                              true
                            );
                          }}
                          error={getError(`members[${index}]?.asset`)}
                        >
                          {assestType.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ color: "red" }}>
                          {getError(`members[${index}]?.asset`)}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Brand</label>
                      <FormControl sx={{ width: "150px" }} size="small">
                        <Select
                          id={`members[${index}]?.brand`}
                          value={formChild?.values?.members[index]?.brand}
                          onChange={(e) => {
                            formChild.setFieldValue(
                              `members[${index}]?.brand`,
                              e.target.value,
                              true
                            );
                          }}
                          error={getError(`members[${index}]?.brand`)}
                        >
                          {brandNames.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText sx={{ color: "red" }}>
                          {getError(`members[${index}]?.brand`)}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                    {formChild?.values?.members[index]?.brand !== "apple" ? (
                      <Box display={"flex"} flexDirection={"column"}>
                        <label>Operating System</label>
                        <FormControl sx={{ width: "150px" }} size="small">
                          <Select
                            id={`members[${index}]?.operatingSystem`}
                            value={
                              formChild?.values?.members[index]?.operatingSystem
                            }
                            onChange={(e) => {
                              formChild.setFieldValue(
                                `members[${index}]?.operatingSystem`,
                                e.target.value,
                                true
                              );
                            }}
                            error={getError(
                              `members[${index}]?.operatingSystem`
                            )}
                          >
                            {operatingSystemTypes.map((item) => (
                              <MenuItem value={item}>{item}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText sx={{ color: "red" }}>
                            {getError(`members[${index}]?.operatingSystem`)}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    ) : null}
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Condition</label>
                      <TextField
                        id={`members[${index}]?.condition`}
                        size="small"
                        name={`members[${index}]?.condition`}
                        onBlur={formChild.handleBlur}
                        onChange={formChild.handleChange}
                        value={formChild?.values.members[index]?.condition}
                        error={getError(`members[${index}]?.condition`)}
                        helperText={getError(`members[${index}]?.condition`)}
                        sx={{ width: "180px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Serial Number</label>
                      <TextField
                        id={`members[${index}]?.serialNumber`}
                        size="small"
                        name={`members[${index}]?.serialNumber`}
                        onBlur={formChild.handleBlur}
                        onChange={formChild.handleChange}
                        value={formChild?.values?.members[index]?.serialNumber}
                        error={getError(`members[${index}]?.serialNumber`)}
                        helperText={getError(`members[${index}]?.serialNumber`)}
                        sx={{ width: "180px" }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} mt={1}>
                  <Box display={"flex"} flexDirection={"row"} gap={4} mt={2}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>Issue Date *</label>
                      <CustomDatePicker
                        value={formChild?.values?.members[index]?.issueDate}
                        handleDateChange={(dateString) => {
                          formChild.setFieldValue(
                            "issueDate",
                            dateString,
                            true
                          );
                        }}
                        isError={getError(`members[${index}]?.issueDate`)}
                        errorText={getError(`members[${index}]?.issueDate`)}
                        datePickerdisabled={false}
                        textFieldDisabled={true}
                        padding={""}
                        rest={{}}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label>Additional Details</label>
                      <TextField
                        id={`members[${index}]?.additionalDetails`}
                        size="small"
                        name={`members[${index}]?.additionalDetails`}
                        onBlur={formChild.handleBlur}
                        onChange={formChild.handleChange}
                        value={
                          formChild?.values?.members[index]?.additionalDetails
                        }
                        error={getError(`members[${index}]?.additionalDetails`)}
                        helperText={getError(
                          `members[${index}]?.additionalDetails`
                        )}
                        multiline
                        sx={{ width: "340px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Button onClick={() => handleServiceRemove(index)}>
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: "#959595", marginTop: "36px" }}
                        />
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Box mt={3}>
                  <Divider />
                </Box>
              </div>
            ))}

            <Button
              variant="contained"
              disabled
              type="button"
              onClick={handleService}
              style={{
                marginTop: 15,
                height: "30px",
              }}
            >
              <span>+ Add Asset</span>
            </Button>
          </Grid>
          {/* <Box
            display={"flex"}
            justifyContent={"center"}
            ml={6}
            mt={4}
            width={600}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
            
          </Box> */}
        </form>
      </Box>
    </>
  );
};

export default Assets;
