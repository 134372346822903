import Dashboard from "./NewDashBoard";

const EmpDashboard = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default EmpDashboard;
