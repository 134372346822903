export const PERSONAL_FORM_ID = "1052917923916066816";
export const FINANCE_FORM_ID = "1052902544577703936";
export const JOB_DETAILS_FORM_ID = "1054102178536927232";
export const DOCUMENTS_UPLOAD_FORM_ID = "1056218068715876352";
export const ASSETS_FORM_ID = "1055040071442935808";
export const SKILLS_FORM_ID = "1056284352191180800";
export const GENERAL_FORM_ID = "1058380750487334912";
export const SUMMARY_FORM_ID = "1063352788801073152";
export const INTEREST_N_HOBBIES_FORM_ID = "1063353853156044800";
export const LEAVES_FORM_ID = "1062244463782965248";
export const ISMS_FORM_ID = "1123108085833592832";
export const MYLEAVE_DETAILS_FORM_ID = "1068415584538505216";
export const LEAVE_DETAILS_FORM_ID = "1062244463782965248";
export const PAGINATION = "&page=0&size=10000";
export const SKILLSET_MSG = "Please choose the most exceptional skill sets";
export const MY_COMP_OFFS_FORM_ID = "1202914782921961472";
