import React from "react";
import "./index.scss";
import KeycloakWrapper from "./KeycloakWrapper";

const App = (props) => {
  const { config } = props;

  console.log(config);
  return (
    <div className="app-wrapper">
      <KeycloakWrapper config={config} />
    </div>
  );
};

export default App;
