import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ReactComponent as BloodGroup } from "../../../../utils/images/bloodGroup.svg";
import CONSTANTS, {
  AADHAR_NO,
  EMAIL_REGEX,
  EMP_ID,
  FIRST_NAME,
  GENERAL_FORMID,
  LAST_NAME,
  PAN_NO,
  phoneNumberRegex,
} from "../../../../constants/constants";
import { useDispatch } from "react-redux";
import { pushNotification, setLoader } from "../../../../redux/actions";
import { AddEmployeePost } from "../../../../services/formService";
import {
  aadharNumberMsg,
  bloodGroupMsg,
  dobAsPerCertificateMsg,
  dobAsPerCertificateMsg1,
  dobAsPerCertificateMsg2,
  dobAsPerCertificateMsg3,
  Empidmsg,
  empIdMsg1,
  empTypeMsg,
  firstNameMsg,
  firstNameMsg1,
  joiningDateMsg,
  lastNameMsg,
  lastNameMsg1,
  officialEmailMsg,
  officialEmailMsg1,
  panNumberMsg,
  phoneNumberMsg1,
  reportingToMsg,
  reportingToMsg1,
} from "./formConstants";
import { LEAVE_DETAILS_FORM_ID } from "../../../../constants/formConstants";
import {
  departmentRequiredMsg,
  designationRequriedMsg,
  lopDaysRequiredMsg,
  probationRequiredMsg,
  statusRequriedMsg,
} from "../../HrAdmin/forms/formConstants";
import CustomDatePicker from "../../../forms/CustomDatePicker";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "#f15830",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "15px",
  },
  addBtn: {
    marginTop: "15px",
  },
  createEmployeeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  phoneInputStyles: {
    width: "100% !important",

    "@media (min-width: 320px )": {
      width: "100% !important",
    },
    "@media (min-width: 375px)": {
      width: "85% !important",
    },
    "@media (min-width: 425px)": {
      width: "85% !important",
    },
    "@media (min-width: 600px)": {
      width: "100% !important",
    },
    "@media (min-width: 768px)": {
      width: "100% !important",
    },
    "@media (min-width: 1440px)": {
      width: "100% !important",
    },
    "@media (min-width: 2560px)": {
      width: "100% !important",
    },
  },
}));

const PersonalForm = (props: any) => {
  const { closeModal, setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const bloodTypes = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  // const [isSubmitted, setIsSubmitted] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  // const handleButtonClick = () => {
  //   setButtonDisabled(true);
  //   // Perform any additional actions on button click
  //   };
  // const handleChange = (event) => {
  //   // Handle form field changes
  //   setButtonDisabled(false); // Enable the button when the form values change
  //   };

  const [countryCode, setCountryCode] = useState("");
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [countryRegex, setCountryRegex] = useState(
    phoneNumberRegex[countryCode]
  );
  const handlePhoneChange = async (value, country) => {
    if (country && "dialCode" in country) {
      // const countryCode = `+${country?.dialCode}`;
      // const phoneNumber = value?.slice(country?.dialCode?.length);
      // const formattedNumber = `${countryCode}-${phoneNumber}`;
      // setFormattedPhoneNumber(formattedNumber);
    }
    setCountryCode(country?.countryCode);
    setCountryRegex(phoneNumberRegex[country?.countryCode]);
  };
  const formOne = useFormik({
    initialValues: {
      empId: "",
      employeeType: "",
      officialEmail: "",
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      dobAsPerCertificate: "",
      bloodGroup: "",
      panNumber: "",
      aadharNumber: "",
      reportingTo: "",
      dateOfJoining: "",
      designation: "",
      department: "",
      lopDays: "",
      probation: "",
      status: "",
    },

    validationSchema: Yup.object({
      // empId: Yup.string().required(empIdMsg1).min(3, empIdMsg2),
      empId: Yup.string().required(empIdMsg1).matches(EMP_ID, Empidmsg),
      employeeType: Yup.string().required(empTypeMsg),
      officialEmail: Yup.string()
        .required(officialEmailMsg)
        .matches(EMAIL_REGEX, officialEmailMsg1),
      // firstName: Yup.string().required(firstNameMsg).min(4, firstNameMsg1),
      // lastName: Yup.string().required(lastNameMsg).min(3, lastNameMsg1),
      firstName: Yup.string()
        .required(firstNameMsg)
        .matches(FIRST_NAME, firstNameMsg1),
      lastName: Yup.string()
        .required(lastNameMsg)
        .matches(LAST_NAME, lastNameMsg1),
      phoneNumber: Yup.string().matches(countryRegex, phoneNumberMsg1),
      // .required(phoneNumberMsg),
      // .matches(PHONE_REGEX, phoneNumberMsg1)
      // .length(10),
      dobAsPerCertificate: Yup.date()
        .required(dobAsPerCertificateMsg)
        .test(
          dobAsPerCertificateMsg1,
          dobAsPerCertificateMsg2,
          (date) => moment().diff(moment(date), dobAsPerCertificateMsg3) >= 18
        ),

      bloodGroup: Yup.string().required(bloodGroupMsg),

      panNumber: Yup.string()
        .required(panNumberMsg)
        .max(10)
        .matches(PAN_NO)
        .length(10),
      aadharNumber: Yup.string()
        .required(aadharNumberMsg)
        .matches(AADHAR_NO, "Please enter a valid Aadhaar Number")
        .length(12),
      reportingTo: Yup.string()
        .required(reportingToMsg)
        .min(3, reportingToMsg1),

      dateOfJoining: Yup.date().required(joiningDateMsg),
      designation: Yup.string().required(designationRequriedMsg),
      // .test(
      //   joiningDateMsg1,
      //   joiningDateMsg2
      //   // (date) => date === moment()

      // ),
      department: Yup.string().required(departmentRequiredMsg),
      probation: Yup.string().required(probationRequiredMsg),
      lopDays: Yup.number().required(lopDaysRequiredMsg).min(0),
      status: Yup.string().required(statusRequriedMsg),
    }),

    onSubmit: (values, { setSubmitting }) => {
      // values.phoneNumber = formattedPhoneNumber;
      values.phoneNumber =
        values.phoneNumber.length === 10
          ? defaultCountryCode + values.phoneNumber
          : values.phoneNumber;
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    setButtonDisabled(true);
    // setIsSubmitted(true);
    // values.phoneNumber = formattedPhoneNumber;
    postFormValues(values);
    // formOne.resetForm();
  };

  const formPayload = (values: any) => ({
    userRegistration: {
      formId: GENERAL_FORMID,
      aadharNumber: values.aadharNumber,
      bloodGroup: values.bloodGroup,
      dateOfJoining: values.dateOfJoining,
      dob: values.dobAsPerCertificate,
      empId: values.empId,
      employeeType: values.employeeType,
      firstName: values.firstName,
      gender: values.gender,
      lastName: values.lastName,
      officialEmail: values.officialEmail,
      panNumber: values.panNumber,
      phone: values.phoneNumber,
      reportingTo: values.reportingTo,
      designation: values.designation,
      status: values.status,
      userName: values.officialEmail,
      department: values.department,
      // probation: values.probation,
      // lopDays: values.lopDays,

      groups: ["camunda-admin", "ts-user", "e360-user"],

      roles: ["uma_authorization"],
    },

    leaveDetails: {
      formId: LEAVE_DETAILS_FORM_ID,
      formData: {
        firstName: values.firstName,
        status: values.status,

        lastName: values.lastName,
        dob: values.dobAsPerCertificate,

        leaveDetails: {
          general: 0,

          privilege: 0,
        },

        dateOfJoining: values.dateOfJoining,

        employeeId: values.empId,

        accruedLeaveDetails: {
          general: 0,

          privilege: 0,
        },

        consumedLeaveDetails: {
          general: 0,

          privilege: 0,
        },

        probation: values.probation,
        probationPeriod: values.probation === "applicable" ? 2 : 0,
        lopDays: values.lopDays,
        officialEmail: values.officialEmail,
        reportingTo: values.reportingTo,
      },
    },
  });

  // useEffect(() => {
  //   prefillData();
  // }, [reduxState.officialEmailId]);

  // const prefillData = async () => {
  //   const response = await fetchFormDataWithIdForPersonal(
  //     GENERAL_FORMID,
  //     reduxState.officialEmailId
  //   );
  //   if (response.success) {
  //     let mapData: any = response.data[0].formData;

  //     let finalData = {
  //       empId: mapData.general.empId,
  //       employeeType: mapData.general.employeeType,
  //       firstName: mapData.general.firstName,
  //       lastName: mapData.general.lastName,
  //       gender: mapData.general.personalGender,
  //       phoneNumber: mapData.general.phone,
  //       dobAsPerCertificate: mapData.general.dobAsPerCertificate,
  //       bloodGroup: mapData.general.bloodGroup,
  //       panNumber: mapData.general.panNumber,
  //       aadharNumber: mapData.general.aadhaarNumber,
  //     };
  //     // formOne.setValues(finalData);

  //     // setValue((prevState) => prevState + 1);
  //   } else {
  //   }
  // };
  useEffect(() => {
    const caller = async () => {
      // await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  const postFormValues = async (values: any) => {
    dispatch(setLoader(true));
    const postData = formPayload(values);
    const response = await AddEmployeePost(postData);
    if (response.success) {
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setOpenFormExitPopup(false);
      closeModal();
      // navigate('/employes-list');
    } else {
      dispatch(setLoader(false));
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
      // setErrorMessage(response.message ?? "");
    }
  };

  const defaultCountryCode = "+91";
  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={2}>
            <Typography color={"#f15830"} width="100%">
              General <Divider />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Employee Type*</label>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="employeeType"
                      id="employee-type"
                      value={formOne.values.employeeType}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          "employeeType",
                          e.target.value,
                          true
                        );
                        setButtonDisabled(false);
                      }}
                      error={
                        formOne.touched.employeeType &&
                        Boolean(formOne.errors.employeeType)
                      }
                    >
                      <MenuItem value={"FTE"}>FTE</MenuItem>
                      <MenuItem value={"CE"}>CE</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.employeeType &&
                        formOne.errors.employeeType}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Emp ID *</label>
                  <TextField
                    id="emp-id"
                    name="empId"
                    size="small"
                    helperText={formOne.touched.empId && formOne.errors.empId}
                    error={
                      formOne.touched.empId && Boolean(formOne.errors.empId)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.empId}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Official Email *</label>
                  <TextField
                    size="small"
                    id="officialEmail"
                    name="officialEmail"
                    helperText={
                      formOne.touched.officialEmail &&
                      formOne.errors.officialEmail
                    }
                    error={
                      formOne.touched.officialEmail &&
                      Boolean(formOne.errors.officialEmail)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.officialEmail}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}> Designation*</label>
                  <TextField
                    id="Designation"
                    name="designation"
                    size="small"
                    helperText={
                      formOne.touched.designation && formOne.errors.designation
                    }
                    error={
                      formOne.touched.designation &&
                      Boolean(formOne.errors.designation)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.designation}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>
                    Reporting Manager Email*
                  </label>
                  <TextField
                    id="reporting-to"
                    name="reportingTo"
                    size="small"
                    helperText={
                      formOne.touched.reportingTo && formOne.errors.reportingTo
                    }
                    error={
                      formOne.touched.reportingTo &&
                      Boolean(formOne.errors.reportingTo)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.reportingTo}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Joining Date*</label>

                  <CustomDatePicker
                    value={formOne.values.dateOfJoining}
                    errorText={
                      formOne.touched.dateOfJoining &&
                      formOne.errors.dateOfJoining
                    }
                    isError={
                      formOne.touched.dateOfJoining &&
                      Boolean(formOne.errors.dateOfJoining)
                    }
                    handleDateChange={(dateString) => {
                      // console.log("myoff", event);
                      formOne.handleChange(dateString);
                      formOne.setFieldValue("dateOfJoining", dateString, true);
                      setButtonDisabled(false);
                    }}
                    datePickerdisabled={false}
                    textFieldDisabled={true}
                    rest={{}}
                    padding={"17.8px 14px"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}> Department*</label>
                  <TextField
                    id="Department"
                    name="department"
                    size="small"
                    helperText={
                      formOne.touched.department && formOne.errors.department
                    }
                    error={
                      formOne.touched.department &&
                      Boolean(formOne.errors.department)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.department}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>On Probation*</label>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="probation"
                      id="probation"
                      value={formOne.values.probation}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          "probation",
                          e.target.value,
                          true
                        );
                        setButtonDisabled(false);
                      }}
                      error={
                        formOne.touched.probation &&
                        Boolean(formOne.errors.probation)
                      }
                    >
                      <MenuItem value={"applicable"}>Yes</MenuItem>
                      <MenuItem value={"completed"}>No</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.probation && formOne.errors.probation}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>LOP Days*</label>
                  <TextField
                    id="LOPDays"
                    name="lopDays"
                    size="small"
                    helperText={
                      formOne.touched.lopDays && formOne.errors.lopDays
                    }
                    error={
                      formOne.touched.lopDays && Boolean(formOne.errors.lopDays)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.lopDays}
                    type={"number"}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>First Name*</label>
                  <TextField
                    id="first-name"
                    name="firstName"
                    size="small"
                    helperText={
                      formOne.touched.firstName && formOne.errors.firstName
                    }
                    error={
                      formOne.touched.firstName &&
                      Boolean(formOne.errors.firstName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.firstName}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Last Name*</label>
                  <TextField
                    size="small"
                    id="last-name"
                    name="lastName"
                    helperText={
                      formOne.touched.lastName && formOne.errors.lastName
                    }
                    error={
                      formOne.touched.lastName &&
                      Boolean(formOne.errors.lastName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.lastName}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: "black",
                        fontFamily: "Roboto",
                        fontSize: "15px",
                      }}
                    >
                      Gender *
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="gender"
                      id="gender-radio"
                      onBlur={formOne.handleBlur}
                      onChange={(event) => {
                        formOne.handleChange(event);
                        setButtonDisabled(false);
                      }}
                      value={formOne.values.gender}
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Male
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Female
                          </Typography>
                        }
                      />

                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Other
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.gender && formOne.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Phone*</label>
                  <PhoneInput
                    inputClass={classes.phoneInputStyles}
                    country={"in"}
                    onlyCountries={["in"]}
                    inputProps={{
                      id: "phone-number",
                      name: "phoneNumber",
                    }}
                    disableDropdown={false}
                    onBlur={formOne.handleBlur}
                    onChange={(value, country) => {
                      formOne.setFieldValue("phoneNumber", value);
                      handlePhoneChange(value, country);
                      setButtonDisabled(false);
                    }}
                    value={formOne?.values?.phoneNumber}
                    isValid={!formOne.errors.phoneNumber}
                  />
                  {formOne.errors.phoneNumber && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {formOne.errors.phoneNumber}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>
                    DOB (as per Certificate)*
                  </label>

                  <CustomDatePicker
                    value={formOne.values.dobAsPerCertificate}
                    errorText={
                      formOne.touched.dobAsPerCertificate &&
                      formOne.errors.dobAsPerCertificate
                    }
                    isError={
                      formOne.touched.dobAsPerCertificate &&
                      Boolean(formOne.errors.dobAsPerCertificate)
                    }
                    handleDateChange={(dateString) => {
                      formOne.handleChange(dateString);
                      formOne.setFieldValue(
                        "dobAsPerCertificate",
                        dateString,
                        true
                      );
                      setButtonDisabled(false);
                    }}
                    datePickerdisabled={false}
                    textFieldDisabled={true}
                    rest={{}}
                    padding={"17.6px 14px"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Blood Group *</label>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <Select
                      labelId="blood-group"
                      id="bloodGroup"
                      value={formOne.values.bloodGroup}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          "bloodGroup",
                          e.target.value,
                          true
                        );
                        setButtonDisabled(false);
                      }}
                      error={
                        formOne.touched.bloodGroup &&
                        Boolean(formOne.errors.bloodGroup)
                      }
                    >
                      {bloodTypes.map((item) => (
                        <MenuItem value={item}>
                          {item} <BloodGroup />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.bloodGroup && formOne.errors.bloodGroup}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Status*</label>
                  <FormControl sx={{ width: "100%" }} size="small">
                    <Select
                      labelId="status"
                      id="status-type"
                      value={formOne.values.status}
                      onChange={(e) => {
                        formOne.setFieldValue("status", e.target.value, true);
                        setButtonDisabled(false);
                      }}
                      error={
                        formOne.touched.status && Boolean(formOne.errors.status)
                      }
                    >
                      <MenuItem value={"Active"}>Active</MenuItem>
                      <MenuItem value={"Inactive"}>Inactive</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.status && formOne.errors.status}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>PAN Number *</label>
                  <TextField
                    id="pan-Number"
                    name="panNumber"
                    size="small"
                    helperText={
                      formOne.touched.panNumber && formOne.errors.panNumber
                    }
                    error={
                      formOne.touched.panNumber &&
                      Boolean(formOne.errors.panNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.panNumber}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Aadhaar Number *</label>
                  <TextField
                    id="aadhar-Number"
                    name="aadharNumber"
                    size="small"
                    helperText={
                      formOne.touched.aadharNumber &&
                      formOne.errors.aadharNumber
                    }
                    error={
                      formOne.touched.aadharNumber &&
                      Boolean(formOne.errors.aadharNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={(event) => {
                      formOne.handleChange(event);
                      setButtonDisabled(false);
                    }}
                    value={formOne.values.aadharNumber}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
            {/*<Grid xs={12}>*/}
            {/*  <Box*/}
            {/*    display={"flex"}*/}
            {/*    flexDirection={"row"}*/}
            {/*    gap={2}*/}
            {/*    flexWrap={"wrap"}*/}
            {/*  >*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Employee Type*</label>*/}
            {/*      <FormControl sx={{ width: "200px" }} size="small">*/}
            {/*        <Select*/}
            {/*          labelId="employeeType"*/}
            {/*          id="employee-type"*/}
            {/*          value={formOne.values.employeeType}*/}
            {/*          onChange={(e) => {*/}
            {/*            formOne.setFieldValue(*/}
            {/*              "employeeType",*/}
            {/*              e.target.value,*/}
            {/*              true*/}
            {/*            );*/}
            {/*            setButtonDisabled(false);*/}
            {/*          }}*/}
            {/*          error={*/}
            {/*            formOne.touched.employeeType &&*/}
            {/*            Boolean(formOne.errors.employeeType)*/}
            {/*          }*/}
            {/*        >*/}
            {/*          <MenuItem value={"FTE"}>FTE</MenuItem>*/}
            {/*          <MenuItem value={"CE"}>CE</MenuItem>*/}
            {/*        </Select>*/}
            {/*        <FormHelperText sx={{ color: "red" }}>*/}
            {/*          {formOne.touched.employeeType &&*/}
            {/*            formOne.errors.employeeType}*/}
            {/*        </FormHelperText>*/}
            {/*      </FormControl>*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Emp ID *</label>*/}
            {/*      <TextField*/}
            {/*        id="emp-id"*/}
            {/*        name="empId"*/}
            {/*        size="small"*/}
            {/*        helperText={formOne.touched.empId && formOne.errors.empId}*/}
            {/*        error={*/}
            {/*          formOne.touched.empId && Boolean(formOne.errors.empId)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.empId}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}

            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Official Email *</label>*/}
            {/*      <TextField*/}
            {/*        size="small"*/}
            {/*        id="officialEmail"*/}
            {/*        name="officialEmail"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.officialEmail &&*/}
            {/*          formOne.errors.officialEmail*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.officialEmail &&*/}
            {/*          Boolean(formOne.errors.officialEmail)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.officialEmail}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12}>*/}
            {/*  <Box*/}
            {/*    display={"flex"}*/}
            {/*    flexDirection={"row"}*/}
            {/*    gap={2}*/}
            {/*    flexWrap={"wrap"}*/}
            {/*  >*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}> Designation*</label>*/}
            {/*      <TextField*/}
            {/*        id="Designation"*/}
            {/*        name="designation"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.designation && formOne.errors.designation*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.designation &&*/}
            {/*          Boolean(formOne.errors.designation)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.designation}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>*/}
            {/*        Reporting Manager Email**/}
            {/*      </label>*/}
            {/*      <TextField*/}
            {/*        id="reporting-to"*/}
            {/*        name="reportingTo"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.reportingTo && formOne.errors.reportingTo*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.reportingTo &&*/}
            {/*          Boolean(formOne.errors.reportingTo)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.reportingTo}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*    /!* <Box display={"flex"} flexDirection={"column"} >*/}
            {/*      <label className={classes.label}>Joining Date*</label>*/}
            {/*      <TextField*/}
            {/*        id="joining-date"*/}
            {/*        name="joiningDate"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*           formOne.touched.joiningDate && formOne.errors.joiningDate*/}
            {/*        }*/}
            {/*        error={*/}
            {/*           formOne.touched.joiningDate &&*/}
            {/*           Boolean(formOne.errors.joiningDate)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={formOne.handleChange}*/}
            {/*        value={formOne.values.joiningDate}*/}
            {/*        sx={{ width: "345px"}}*/}
            {/*      />*/}
            {/*    </Box> *!/*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Joining Date*</label>*/}
            {/*      <TextField*/}
            {/*        id="dateOf-Joining"*/}
            {/*        name="dateOfJoining"*/}
            {/*        type="date"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.dateOfJoining &&*/}
            {/*          formOne.errors.dateOfJoining*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.dateOfJoining &&*/}
            {/*          Boolean(formOne.errors.dateOfJoining)*/}
            {/*        }*/}
            {/*        value={formOne.values.dateOfJoining}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        sx={{ width: 200 }}*/}
            {/*        InputLabelProps={{*/}
            {/*          shrink: true,*/}
            {/*        }}*/}
            {/*        onKeyDown={(e) => {*/}
            {/*          e.preventDefault();*/}
            {/*        }}*/}
            {/*        size="small"*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12}>*/}
            {/*  <Box*/}
            {/*    display={"flex"}*/}
            {/*    flexDirection={"row"}*/}
            {/*    gap={2}*/}
            {/*    flexWrap={"wrap"}*/}
            {/*  >*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}> Department*</label>*/}
            {/*      <TextField*/}
            {/*        id="Department"*/}
            {/*        name="department"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.department && formOne.errors.department*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.department &&*/}
            {/*          Boolean(formOne.errors.department)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.department}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}

            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>On Probation*</label>*/}
            {/*      <FormControl sx={{ width: "200px" }} size="small">*/}
            {/*        <Select*/}
            {/*          labelId="probation"*/}
            {/*          id="probation"*/}
            {/*          value={formOne.values.probation}*/}
            {/*          onChange={(e) => {*/}
            {/*            formOne.setFieldValue(*/}
            {/*              "probation",*/}
            {/*              e.target.value,*/}
            {/*              true*/}
            {/*            );*/}
            {/*            setButtonDisabled(false);*/}
            {/*          }}*/}
            {/*          error={*/}
            {/*            formOne.touched.probation &&*/}
            {/*            Boolean(formOne.errors.probation)*/}
            {/*          }*/}
            {/*        >*/}
            {/*          <MenuItem value={"applicable"}>Yes</MenuItem>*/}
            {/*          <MenuItem value={"completed"}>No</MenuItem>*/}
            {/*        </Select>*/}
            {/*        <FormHelperText sx={{ color: "red" }}>*/}
            {/*          {formOne.touched.probation && formOne.errors.probation}*/}
            {/*        </FormHelperText>*/}
            {/*      </FormControl>*/}
            {/*    </Box>*/}

            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>LOP Days*</label>*/}
            {/*      <TextField*/}
            {/*        id="LOPDays"*/}
            {/*        name="lopDays"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.lopDays && formOne.errors.lopDays*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.lopDays && Boolean(formOne.errors.lopDays)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.lopDays}*/}
            {/*        type={"number"}*/}
            {/*        sx={{ width: "240px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12}>*/}
            {/*  <Box*/}
            {/*    display={"flex"}*/}
            {/*    flexDirection={"row"}*/}
            {/*    gap={2}*/}
            {/*    flexWrap={"wrap"}*/}
            {/*  >*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>First Name*</label>*/}
            {/*      <TextField*/}
            {/*        id="first-name"*/}
            {/*        name="firstName"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.firstName && formOne.errors.firstName*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.firstName &&*/}
            {/*          Boolean(formOne.errors.firstName)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.firstName}*/}
            {/*        sx={{ width: "210px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Last Name*</label>*/}
            {/*      <TextField*/}
            {/*        size="small"*/}
            {/*        id="last-name"*/}
            {/*        name="lastName"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.lastName && formOne.errors.lastName*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.lastName &&*/}
            {/*          Boolean(formOne.errors.lastName)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.lastName}*/}
            {/*        sx={{ width: "210px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <FormControl>*/}
            {/*        <FormLabel*/}
            {/*          id="demo-row-radio-buttons-group-label"*/}
            {/*          sx={{*/}
            {/*            color: "black",*/}
            {/*            fontFamily: "Roboto",*/}
            {/*            fontSize: "15px",*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          Gender **/}
            {/*        </FormLabel>*/}
            {/*        <RadioGroup*/}
            {/*          row*/}
            {/*          aria-labelledby="demo-row-radio-buttons-group-label"*/}
            {/*          name="gender"*/}
            {/*          id="gender-radio"*/}
            {/*          onBlur={formOne.handleBlur}*/}
            {/*          onChange={(event) => {*/}
            {/*            formOne.handleChange(event);*/}
            {/*            setButtonDisabled(false);*/}
            {/*          }}*/}
            {/*          value={formOne.values.gender}*/}
            {/*        >*/}
            {/*          <FormControlLabel*/}
            {/*            value="Male"*/}
            {/*            control={<Radio />}*/}
            {/*            label="Male"*/}
            {/*          />*/}
            {/*          <FormControlLabel*/}
            {/*            value="Female"*/}
            {/*            control={<Radio />}*/}
            {/*            label="Female"*/}
            {/*          />*/}

            {/*          <FormControlLabel*/}
            {/*            value="Other"*/}
            {/*            control={<Radio />}*/}
            {/*            label="Other"*/}
            {/*          />*/}
            {/*        </RadioGroup>*/}
            {/*        <FormHelperText sx={{ color: "red" }}>*/}
            {/*          {formOne.touched.gender && formOne.errors.gender}*/}
            {/*        </FormHelperText>*/}
            {/*      </FormControl>*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid xs={12}>*/}
            {/*  <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Phone*</label>*/}
            {/*      <PhoneInput*/}
            {/*        inputClass={classes.phoneInputStyles}*/}
            {/*        country={'in'}*/}
            {/*        inputProps={{*/}
            {/*        id:"phone-number",*/}
            {/*        name:"phoneNumber"*/}
            {/*        }}*/}
            {/*        disableDropdown={false}*/}
            {/*      */}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(value, country) => {*/}
            {/*          formOne.setFieldValue("phoneNumber", value);*/}
            {/*          handlePhoneChange(value, country);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={*/}
            {/*          formOne?.values?.phoneNumber*/}
            {/*        }*/}
            {/*        isValid={!formOne.errors.phoneNumber}                   */}
            {/*      />*/}
            {/*      {formOne.errors.phoneNumber && (*/}
            {/*        <div style={{ color: 'red', marginTop: '5px' }}>*/}
            {/*          {formOne.errors.phoneNumber}*/}
            {/*        </div>*/}
            {/*      )}                  */}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>*/}
            {/*        DOB (as per Certificate)**/}
            {/*      </label>*/}
            {/*      <TextField*/}
            {/*        id="dobAsPer-Certificate"*/}
            {/*        name="dobAsPerCertificate"*/}
            {/*        type="date"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.dobAsPerCertificate &&*/}
            {/*          formOne.errors.dobAsPerCertificate*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.dobAsPerCertificate &&*/}
            {/*          Boolean(formOne.errors.dobAsPerCertificate)*/}
            {/*        }*/}
            {/*        value={formOne.values.dobAsPerCertificate}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        sx={{ width: 240 }}*/}
            {/*        InputLabelProps={{*/}
            {/*          shrink: true,*/}
            {/*        }}*/}
            {/*        onKeyDown={(e) => {*/}
            {/*          e.preventDefault();*/}
            {/*        }}*/}
            {/*        size="small"*/}
            {/*      />*/}
            {/*    </Box>*/}

            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Blood Group *</label>*/}
            {/*      <FormControl sx={{ minWidth: 190 }} size="small">*/}
            {/*        <Select*/}
            {/*          labelId="blood-group"*/}
            {/*          id="bloodGroup"*/}
            {/*          value={formOne.values.bloodGroup}*/}
            {/*          onChange={(e) => {*/}
            {/*            formOne.setFieldValue(*/}
            {/*              "bloodGroup",*/}
            {/*              e.target.value,*/}
            {/*              true*/}
            {/*            );*/}
            {/*            setButtonDisabled(false);*/}
            {/*          }}*/}
            {/*          error={*/}
            {/*            formOne.touched.bloodGroup &&*/}
            {/*            Boolean(formOne.errors.bloodGroup)*/}
            {/*          }*/}
            {/*        >*/}
            {/*          {bloodTypes.map((item) => (*/}
            {/*            <MenuItem value={item}>*/}
            {/*              {item} <BloodGroup />*/}
            {/*            </MenuItem>*/}
            {/*          ))}*/}
            {/*        </Select>*/}
            {/*        <FormHelperText sx={{ color: "red" }}>*/}
            {/*          {formOne.touched.bloodGroup && formOne.errors.bloodGroup}*/}
            {/*        </FormHelperText>*/}
            {/*      </FormControl>*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}

            {/*<Grid xs={12}>*/}
            {/*  <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Status*</label>*/}
            {/*      <FormControl sx={{ width: "200px" }} size="small">*/}
            {/*        <Select*/}
            {/*          labelId="status"*/}
            {/*          id="status-type"*/}
            {/*          value={formOne.values.status}*/}
            {/*          onChange={(e) => {*/}
            {/*            formOne.setFieldValue("status", e.target.value, true);*/}
            {/*            setButtonDisabled(false);*/}
            {/*          }}*/}
            {/*          error={*/}
            {/*            formOne.touched.status && Boolean(formOne.errors.status)*/}
            {/*          }*/}
            {/*        >*/}
            {/*          <MenuItem value={"Active"}>Active</MenuItem>*/}
            {/*          <MenuItem value={"Inactive"}>Inactive</MenuItem>*/}
            {/*        </Select>*/}
            {/*        <FormHelperText sx={{ color: "red" }}>*/}
            {/*          {formOne.touched.status && formOne.errors.status}*/}
            {/*        </FormHelperText>*/}
            {/*      </FormControl>*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>PAN Number *</label>*/}
            {/*      <TextField*/}
            {/*        id="pan-Number"*/}
            {/*        name="panNumber"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.panNumber && formOne.errors.panNumber*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.panNumber &&*/}
            {/*          Boolean(formOne.errors.panNumber)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.panNumber}*/}
            {/*        sx={{ width: "200px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*    <Box display={"flex"} flexDirection={"column"}>*/}
            {/*      <label className={classes.label}>Aadhaar Number *</label>*/}
            {/*      <TextField*/}
            {/*        id="aadhar-Number"*/}
            {/*        name="aadharNumber"*/}
            {/*        size="small"*/}
            {/*        helperText={*/}
            {/*          formOne.touched.aadharNumber &&*/}
            {/*          formOne.errors.aadharNumber*/}
            {/*        }*/}
            {/*        error={*/}
            {/*          formOne.touched.aadharNumber &&*/}
            {/*          Boolean(formOne.errors.aadharNumber)*/}
            {/*        }*/}
            {/*        onBlur={formOne.handleBlur}*/}
            {/*        onChange={(event) => {*/}
            {/*          formOne.handleChange(event);*/}
            {/*          setButtonDisabled(false);*/}
            {/*        }}*/}
            {/*        value={formOne.values.aadharNumber}*/}
            {/*        sx={{ width: "300px" }}*/}
            {/*      />*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/* <Grid xs={12}>
              <Box
                mt={1}
                display={"flex"}
                flexDirection={"row"}
                gap={2}
                justifyContent={"center"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <label
                    style={{ marginRight: "15px", marginTop: "8px" }}
                    className={classes.label}
                  >
                    Created By:
                  </label>
                  <FormControl sx={{ width: "170px" }} size="small">
                    <Select
                      id={"createdBy"}
                      value={formOne.values.createdBy}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          `createdBy`,
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne.touched.createdBy &&
                        Boolean(formOne.errors.createdBy)
                      }
                    >
                      {hrsList.map((item) => (
                        <MenuItem value={item.value}>{item.title}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.createdBy && formOne.errors.createdBy}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Grid> */}
          </Grid>

          <Box className={classes.createEmployeeBtn}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
              disabled={isButtonDisabled}
            >
              Create Employee
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PersonalForm;
