import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  ButtonConstants,
  StatusConstants,
  VisitorModel,
} from "./types/ReceptionTypes";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { splitPhoneByCountryCode } from "./utils/utilityFuntions";
import { getFormattedDateTime } from "../../utils/DateOperations";
import TsDatagrid from "../../components/TsDatagrid";

interface VisitorLogTableProps {
  isAdmin: any;
  handleCheckIn: any;
  handleCheckOut: any;
  rows: any;
  handleRowsPerPageChange: any;
  page: any;
  handlePageChange: any;
  rowsPerPageArray: any;
  rowsPerPage: any;
  containerStyle?: any;
  statusOptions: any;
  visitorStatus: any;
  setVisitorStatus: any;
  setSelectedDate: any;
  isLoading: any;
  onClickNextPage: any;
  onClickPreviousPage: any;
  totalPages: any;
  handleApproveOrRejct: any;
}

// const getFormattedTime = (date: Date) => {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   let time;
//   if (hours >= 12) {
//     time = `${hours === 12 ? 12 : hours % 12}:${minutes
//       .toString()
//       .padStart(2, "0")} PM`;
//   } else {
//     time = `${hours === 0 ? 12 : hours}:${minutes
//       .toString()
//       .padStart(2, "0")} AM`;
//   }

//   return time;
// };

const getDisability = (checkInOut: any, status: any): boolean | undefined => {
  return (
    Boolean(checkInOut) ||
    status === StatusConstants.PENDING_APPROVAL ||
    status === StatusConstants.REJECTED
  );
};

const getStatusProperties = (status: VisitorModel["status"]) => {
  switch (status) {
    case StatusConstants.PENDING_APPROVAL:
      return {
        label: "Pending Approval",
        backgroundColor: "#ffecd9", // Light orange
        textColor: "#f29339", // Dark brown
        // backgroundColor: "#FFE5B4", // Light orange
        // textColor: "#8B4513", // Dark brown
      };
    case StatusConstants.APPROVED:
      return {
        label: ButtonConstants.APPROVED,
        backgroundColor: "#d9ffd1", // Light green
        textColor: "#108a10", // Dark green
      };
    case StatusConstants.CHECKED_IN:
      return {
        label: "Checked In",
        backgroundColor: "#e5f7ff", // Light blue
        textColor: "#0277BD", // Dark blue
      };
    case StatusConstants.CHECKED_OUT:
      return {
        label: "Checked Out",
        backgroundColor: "#e2e2e2", // Light blue
        textColor: "#333", // Dark blue
        // backgroundColor: "#FFCDD2", // Light red
        // textColor: "#D32F2F", // Dark red
      };
    case StatusConstants.REJECTED:
      return {
        label: ButtonConstants.REJECTED,
        backgroundColor: "#fadddd", // Light pink
        // backgroundColor: "#F4CCCC", // Light pink
        textColor: "#CC0000", // Dark red
      };
    default:
      return {
        label: "Unknown Status",
        backgroundColor: "#E0E0E0", // Light gray
        textColor: "#9E9E9E", // Dark gray
      };
  }
};

const styles = {
  containers: {
    main: {
      borderRadius: "12px",
      padding: "8px",
      boxShadow: "0 0 4px #777",
    },
  },
  scheduleVisitTimeHeader: {
    containerMain: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      gap: "18px",
    },
    datePicker: {
      "& .MuiOutlinedInput-input": {
        padding: "3px 8px !important",
        fontSize: "14px",
        minWidth: "90px",
        maxWidth: "90px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "16px !important",
        display: "block !important",
      },
    },
  },
  status: {
    containerMain: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: 250,
      gap: "20px",
      "& .MuiSvgIcon-root": {
        fontSize: "16px !important",
        display: "block !important",
      },
    },
    option: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      padding: "6px 12px",
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        background: "#e9e9e9",
      },
    },
    textField: {
      width: "100%",
      flexGrow: "1",
      fontSize: "10px !important",
      "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        padding: "0px !important",
        fontSize: "14px",
      },
    },
    label: {
      fontSize: "14px",
      padding: "3px 8px",
      borderRadius: "14px",

      cursor: "pointer",
    },
  },
  checkInOut: {
    containerMain: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "12px",
      height: "100%",
      width: "100%",
    },
    buttonCheckIn: {
      fontSize: "14px",
      textTransform: "capitalize",
      borderRadius: "1000px",
      background: "#02921a",
      "&:hover": {
        background: "#0b771d",
      },
    },
    buttonCheckout: {
      fontSize: "14px",
      textTransform: "capitalize",
      borderRadius: "1000px",
      background: "#ad2d17",
      "&:hover": {
        background: "#811a08",
      },
    },
  },
  datagrid: {
    overlayStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  },
  visitor: {
    visitorPrimaryDetails: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "start",
      maxWidth: 180,
    },
    typography: {
      maxWidth: "100%",
      lineHeight: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    statusContainer: {
      width: "100%",
      height: "26px",
      fontSize: "10px",
    },
  },
  boldFont: {
    fontWeight: "bold",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  occupyEntireHeight: {
    height: "100%",
  },

  pagination: {
    paginationMain: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
      margin: "0 24px",
    },
    rowsPerPageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
    rowsPerPageText: {
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
    selectBox: {
      height: "30px",
      fontSize: "14px",
      "& .MuiOutlinedInput-root, .MuiSelect-root": {
        border: "1px solid red",
      },
    },
    pageInfoContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "4px",
    },
    pageText: {
      fontSize: "14px",
    },
    navigationContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "6px",
    },
    arrowIcon: {
      fontSize: "16px",
      cursor: "pointer",
      borderRadius: "100%",
      transition: "all 0.3s",
      "&:hover": {
        background: "#00000042",
        transition: "all 0.3s",
      },
      height: "24px",
      width: "24px",
      padding: "3px",
    },
  },
};

const VisitorLogTable = ({
  isAdmin,
  handleCheckIn,
  handleCheckOut,
  rows,
  handleRowsPerPageChange,
  page,
  handlePageChange,
  rowsPerPageArray,
  containerStyle = {},
  statusOptions = [],
  visitorStatus,
  setVisitorStatus,
  setSelectedDate,
  isLoading,
  onClickNextPage,
  onClickPreviousPage,
  totalPages,
  rowsPerPage,
  handleApproveOrRejct,
}: VisitorLogTableProps) => {
  const getTableHeader = (title: any) => {
    return <Typography sx={styles.boldFont}>{title}</Typography>;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 280,
      sortable: false,
      editable: false,
      headerAlign: "left",
      align: "left",
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        return (
          <Box sx={[styles.flexCenter, styles.occupyEntireHeight]}>
            <Avatar
              src={params?.row?.photoUrl}
              alt={params?.row?.firstName + " " + params?.row?.lastName}
              sx={{ width: 40, height: 40, marginRight: 1 }}
            />
            <Box sx={styles.visitor.visitorPrimaryDetails}>
              <Typography variant="body1" noWrap sx={styles.visitor.typography}>
                {params?.row?.firstName + " " + params?.row?.lastName}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={styles.visitor.typography}
              >
                {splitPhoneByCountryCode("in", params.row.visitorMobile)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      sortable: false,
      width: 200,
      editable: false,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 200,
      maxWidth: 380,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: "hostName",
      headerName: "Host",
      sortable: false,
      width: 200,
      editable: false,
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 150,
      maxWidth: 250,
      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
    },
    {
      field: "scheduledDateTime",
      headerName: "Scheduled Visit Date-Time",
      width: 200,
      sortable: false,
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 400,
      maxWidth: 500,
      valueFormatter: (row: any) =>
        getFormattedDateTime(new Date(row?.value)).dateString,
      renderHeader: (props) => {
        return (
          <Box sx={styles.scheduleVisitTimeHeader.containerMain}>
            {getTableHeader(props?.colDef?.headerName)}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={styles.scheduleVisitTimeHeader.datePicker}
                onChange={(dateDayJs: any) => {
                  try {
                    const formattedDate = dateDayJs.format("YYYY-MM-DD");
                    setSelectedDate(formattedDate);
                  } catch (error: any) {
                    setSelectedDate(null);
                  }
                }}
                slotProps={{
                  actionBar: { actions: ["clear", "today"] },
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 300,
      maxWidth: 350,
      renderHeader: (props) => {
        return (
          <Box sx={styles.status.containerMain}>
            {getTableHeader(props?.colDef?.headerName)}
            <Autocomplete
              sx={styles.visitor.statusContainer}
              options={statusOptions}
              getOptionLabel={(option: any) => option}
              value={visitorStatus}
              renderOption={(option: any) => {
                return (
                  <Typography
                    sx={styles.status.option}
                    onClick={() => {
                      setVisitorStatus(option.key);
                    }}
                  >
                    {option.key}
                  </Typography>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="hostEmail"
                  size="small"
                  sx={styles.status.textField}
                />
              )}
            />
          </Box>
        );
      },
      renderCell: (params) => {
        const statusProperties = getStatusProperties(params?.value);
        return (
          <Typography
            sx={[
              styles.status.label,
              {
                color: statusProperties.textColor,
                background: statusProperties.backgroundColor,
              },
            ]}
          >
            {statusProperties.label}
          </Typography>
        );
      },
    },
    {
      field: "checkInOut",
      headerName: "Check in/out",
      sortable: false,
      editable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 250,
      maxWidth: 300,

      renderHeader: (props) => getTableHeader(props?.colDef?.headerName),
      renderCell: (params) => {
        const checkinDisability =
          getDisability(params?.row?.checkin, params?.row?.status) ||
          params?.row?.status === StatusConstants.CHECKED_IN ||
          params?.row?.status === StatusConstants.CHECKED_OUT;
        const checkoutDisability =
          getDisability(params?.row?.checkout, params?.row?.status) ||
          params?.row?.status === StatusConstants.CHECKED_OUT;

        const approveEnable =
          params?.row?.status === StatusConstants.PENDING_APPROVAL ||
          params?.row?.status !== StatusConstants.REJECTED;
        const rejectEnable =
          params?.row?.status === StatusConstants.PENDING_APPROVAL ||
          params?.row?.status === StatusConstants.REJECTED;

        if (isAdmin === null) return <>Loading</>;

        return isAdmin ? (
          <Box sx={styles.checkInOut.containerMain}>
            <Button
              variant="contained"
              sx={styles.checkInOut.buttonCheckIn}
              onClick={() => {
                handleCheckIn(params, new Date().toISOString());
              }}
              disabled={checkinDisability}
            >
              {!Boolean(params?.row?.checkin)
                ? "Check in"
                : getFormattedDateTime(new Date(params?.row?.checkin)).time}
            </Button>
            {Boolean(params?.row?.checkin) && (
              <Button
                variant="contained"
                sx={styles.checkInOut.buttonCheckout}
                onClick={() => {
                  handleCheckOut(params, new Date().toISOString());
                }}
                disabled={checkoutDisability}
              >
                {!Boolean(params?.row?.checkout)
                  ? "Check out"
                  : getFormattedDateTime(new Date(params?.row?.checkout)).time}
              </Button>
            )}
          </Box>
        ) : (
          <Box
            sx={[
              styles.checkInOut.containerMain,
              {
                justifyContent: "center",
              },
            ]}
          >
            {approveEnable && (
              <Button
                variant="contained"
                sx={styles.checkInOut.buttonCheckIn}
                onClick={() => {
                  handleApproveOrRejct(params, StatusConstants.APPROVED);
                }}
                disabled={
                  params?.row?.status !== StatusConstants.PENDING_APPROVAL
                }
              >
                {rejectEnable
                  ? ButtonConstants.APPROVE
                  : ButtonConstants.APPROVED}
              </Button>
            )}
            {rejectEnable && (
              <Button
                variant="contained"
                sx={styles.checkInOut.buttonCheckout}
                onClick={() => {
                  handleApproveOrRejct(params, StatusConstants.REJECTED);
                }}
                disabled={
                  params?.row?.status !== StatusConstants.PENDING_APPROVAL
                }
              >
                {approveEnable
                  ? ButtonConstants.REJECT
                  : ButtonConstants.REJECTED}
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={[styles.containers.main, containerStyle]}>
      <TsDatagrid
        columns={columns}
        totalElements={rows?.length}
        rows={rows}
        isCheckboxSelection={false}
        rowHeight={70}
        hideFooter={false}
        rowsPerPageOptions={rowsPerPageArray}
        onPageSizeChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{
          NoRowsOverlay: () => (
            <Box sx={styles.datagrid.overlayStyle}>
              {isLoading ? " Loading Visitors" : "No Vsitors Came"}
            </Box>
          ),
          Pagination: () => {
            return (
              <Box sx={styles.pagination.paginationMain}>
                <Box sx={styles.pagination.rowsPerPageContainer}>
                  <Typography sx={styles.pagination.rowsPerPageText}>
                    Rows per page:{" "}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rowsPerPage}
                    label="Rows per page"
                    onChange={(e) => handleRowsPerPageChange(e.target.value)}
                    sx={styles.pagination.selectBox}
                  >
                    {rowsPerPageArray.map((element: any) => (
                      <MenuItem key={element} value={element}>
                        {element}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={styles.pagination.pageInfoContainer}>
                  <Typography sx={styles.pagination.pageText}>
                    {page}
                  </Typography>
                  <Typography sx={styles.pagination.pageText}>of</Typography>
                  <Typography sx={styles.pagination.pageText}>
                    {totalPages}
                  </Typography>
                </Box>
                <Box sx={styles.pagination.navigationContainer}>
                  <ArrowBackIosIcon
                    sx={styles.pagination.arrowIcon}
                    onClick={onClickPreviousPage}
                  />
                  <ArrowForwardIosIcon
                    sx={styles.pagination.arrowIcon}
                    onClick={onClickNextPage}
                  />
                </Box>
              </Box>
            );
          },
        }}
      />
    </Box>
  );
};

export default VisitorLogTable;
