import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddLeaveForm from "./addLeaveForm";
import { leaveTypeEnums } from "../../uiElementsAccess";

const AddLeaveModal = (props) => {
  const { isModalOpen, leaveType, selectedEmpIdList, closeModal } = props;

  const classes = useStyles();

  const getTitle = () => {
    return leaveType === leaveTypeEnums.COMP_OFF ? "Add Comp Off" : "Add Leave";
  };

  return (
    <>
      <Modal open={isModalOpen} onClose={closeModal}>
        <div className={`${classes.modalRoot} modal-container`}>
          <Box className={classes.screenHeadingContainer}>
            <Box display={"flex"} alignSelf={"center"} margin={"0 auto"}>
              <Typography
                variant="h6"
                sx={{
                  "@media (min-width: 2000px)": {
                    fontSize: "calc(12px + 1.8rem)",
                  },
                  "@media (min-width: 1500px) and (max-width: 2000px)": {
                    fontSize: "calc(12px + 1.2rem)",
                  },
                  "@media (min-width: 1000px) and (max-width: 1500px)": {
                    fontSize: "calc(12px + 0.08rem)",
                  },
                  "@media (min-width: 600px) and (max-width: 1000px)": {
                    fontSize: "calc(12px + 0.008rem)",
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "calc(12px + 0.001rem)",
                  },
                }}
              >
                {getTitle()}
              </Typography>
            </Box>
            <Box>
              <Button sx={{ color: "#FFFF" }} onClick={closeModal}>
                <CloseOutlinedIcon />
              </Button>
            </Box>
          </Box>
          <div>
            <AddLeaveForm
              leaveType={leaveType}
              selectedEmpIdList={selectedEmpIdList}
              title={getTitle()}
              closeModal={closeModal}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddLeaveModal;

const useStyles = makeStyles(() => ({
  screenHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#162952",
    height: "50px",
    color: "#FFFFFF",
  },
  modalRoot: {
    maxHeight: "90%",
    width: "75%",
  },
  formContainer: {},
}));
