import React, { useEffect, useState } from "react";
import { INTEREST_N_HOBBIES_FORM_ID } from "../constants/formConstants";
import { useDispatch } from "react-redux";
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from "../services/formService";
import { pushNotification } from "../redux/actions";
import CONSTANTS from "../constants/constants";
import { Button } from "@mui/material";
import CustomTextField from "./CustomTextField";
import { makeStyles } from "@material-ui/core/styles";

const HobbiesCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hobbiesId, setHobbiesId] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [enableHobbies, setEnableHobbies] = useState(true);
  const [buttonText, setButtonText] = useState("Edit");
  let userEmail = sessionStorage.getItem("user-email");

  useEffect(() => {
    fetchInterestDetail(INTEREST_N_HOBBIES_FORM_ID, userEmail);
  }, [userEmail]);

  const handleHobbiesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHobbies(e.target.value);
  };

  const postHobbies = async () => {
    if (hobbiesId) {
      let payload = {
        formId: INTEREST_N_HOBBIES_FORM_ID,
        id: hobbiesId,
        formData: {
          officialEmail: sessionStorage.getItem("user-email"),
          interestNHobbies: hobbies,
        },
      };
      const response: any = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setEnableHobbies(true);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
        setEnableHobbies(true);
      }
    } else {
      let payload = {
        formId: INTEREST_N_HOBBIES_FORM_ID,
        formData: {
          officialEmail: sessionStorage.getItem("user-email"),
          interestNHobbies: hobbies,
        },
      };
      const response = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setEnableHobbies(true);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
        setEnableHobbies(true);
      }
    }
  };

  const fetchInterestDetail = async (formId: string, emailId: any) => {
    const response = await FilterFormDetailsWithOfficalEmail(formId, emailId);
    if (response.success && response.data.content.length > 0) {
      setHobbies(response.data.content[0].formData.interestNHobbies);
      setHobbiesId(response.data.content[0].id);
    } else {
      setHobbies("");
      setHobbiesId("");
    }
    return { success: false };
  };
  useEffect(() => {
    if (enableHobbies) {
      setButtonText("Edit");
      // postHobbies();
    } else {
      setButtonText("Save");
    }
  }, [enableHobbies]);

  const buttonStyle = {
    textTransform: "capitalize",
    fontSize: "14px !important",
    "&:hover": {
      boxShadow: "0 0 5px #2c2c2c5e !important",
    },
    ...(enableHobbies ? { color: "#F15830" } : { color: "#24af4e" }),
  };

  return (
    <div className="summary">
      <div className={classes.summaryContainer}>
        <div>
          <Button
            onClick={
              enableHobbies
                ? () => setEnableHobbies((prev) => !prev)
                : postHobbies
            }
            sx={buttonStyle}
          >
            {buttonText}
          </Button>
        </div>

        <p className="summaryTitleAsset">Interests & Hobbies</p>
      </div>
      {!enableHobbies ? (
        <CustomTextField
          handleContentTextChange={handleHobbiesChange}
          contentText={hobbies}
          color={"#162952"}
          fontsize={"16px"}
          height={"170px"}
          width={"100%"}
        />
      ) : (
        <div className={classes.customParagraphStyle}>
          <p>{hobbies}</p>
        </div>
      )}
    </div>
  );
};

export default HobbiesCard;

const useStyles = makeStyles({
  customParagraphStyle: {
    width: "100%",
    height: "170px",
    display: "flex",
    justifyContent: "start",
    overflow: "auto",
    scrollbarWidth: "none",
    textAlign: "justify",
    color: "#162952",
    cursor: "default",
  },

  summaryContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "50px",
    marginBottom: "5px",
  },
});
