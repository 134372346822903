import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import PersonalForm from "./forms/general";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormExitPopup from "../../FormExitPopup";

const useStyles = makeStyles(() => ({
  empTabs: {
    backgroundColor: "#f8e7e3",
    height: "100vh",
    "&.MuiTabs-root .MuiTabs-indicator": {
      backgroundColor: "red",
    },
  },
  empTab: {
    "&.Mui-selected": {
      color: "black !important",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={900} sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function FormsDetails(props: any) {
  const classes = useStyles();
  const {
    setOpenFormExitPopup,
    isOpenFormExitPopup,
    formExitCallBackAction,
    handleCloseForm,
    setIsOpenExitPopup,
  } = props;

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleValue = (newValue, buttonName) => {
  //   if (buttonName === "next") {
  //     setValue(newValue + 1);
  //   }
  //   if (buttonName === "back") {
  //     setValue(newValue - 1);
  //   }
  // };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "auto",
      }}
    >
      {isOpenFormExitPopup && (
        <FormExitPopup
          callbackAction={formExitCallBackAction}
          isShowFormExitPopup={isOpenFormExitPopup}
          setIsShowFormExitPopup={setIsOpenExitPopup}
        />
      )}
      <Button
        sx={{ color: "red", position: "absolute", marginLeft: "93%" }}
        onClick={handleCloseForm}
      >
        <CloseOutlinedIcon sx={{ margin: "2px" }} />
      </Button>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons={false}
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.empTabs}
      >
        <Tab
          className={classes.empTab}
          label="GENERAL"
          {...a11yProps(0)}
          icon={
            value === 0 ? (
              <svg
                width="30"
                height="30"
                viewBox="0 0 48 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.4289 51H3.94373C2.32096 51 1 49.6737 1 48.0444V3.95561C1 2.32629 2.32096 1 3.94373 1H38.4258C40.0485 1 41.3695 2.32629 41.3695 3.95561V28.5288C41.3695 29.0015 40.9901 29.3824 40.5193 29.3824C40.0485 29.3824 39.6691 29.0015 39.6691 28.5288V3.95561C39.6691 3.26526 39.1133 2.70721 38.4258 2.70721H3.94373C3.25615 2.70721 2.70035 3.26953 2.70035 3.95561V48.0444C2.70035 48.7347 3.25615 49.2928 3.94373 49.2928H38.4289C39.1165 49.2928 39.6723 48.7347 39.6723 48.0444V45.7428C39.6723 45.2702 40.0517 44.8892 40.5225 44.8892C40.9933 44.8892 41.3727 45.2702 41.3727 45.7428V48.0444C41.3727 49.6737 40.0517 51 38.4289 51Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M13.9589 16.5428C11.3119 16.5428 9.15869 14.3896 9.15869 11.7425C9.15869 9.09549 11.3119 6.94229 13.9589 6.94229C16.606 6.94229 18.7592 9.09549 18.7592 11.7425C18.7592 14.3896 16.606 16.5428 13.9589 16.5428ZM13.9589 8.64526C12.2528 8.64526 10.8617 10.0364 10.8617 11.7425C10.8617 13.4487 12.2528 14.8398 13.9589 14.8398C15.6651 14.8398 17.0562 13.4487 17.0562 11.7425C17.0562 10.0364 15.6651 8.64526 13.9589 8.64526Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M20.8555 23.4441C20.384 23.4441 20.004 23.0641 20.004 22.5926C20.004 19.258 17.2899 16.547 13.9585 16.547C10.627 16.547 7.91293 19.2612 7.91293 22.5926C7.91293 23.0641 7.53296 23.4441 7.06145 23.4441C6.58994 23.4441 6.20996 23.0641 6.20996 22.5926C6.20996 20.5245 7.01568 18.5768 8.48024 17.1143C9.94586 15.6487 11.8894 14.8441 13.9585 14.8441C16.0276 14.8441 17.9743 15.6498 19.4367 17.1143C20.9024 18.58 21.707 20.5235 21.707 22.5926C21.707 23.0609 21.327 23.4441 20.8555 23.4441Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M36.7414 11.6297H25.3603C24.8888 11.6297 24.5088 11.2498 24.5088 10.7782C24.5088 10.3067 24.8888 9.92676 25.3603 9.92676H36.7414C37.213 9.92676 37.5929 10.3067 37.5929 10.7782C37.5929 11.2498 37.2098 11.6297 36.7414 11.6297Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M36.7414 21.524H25.3603C24.8888 21.524 24.5088 21.144 24.5088 20.6725C24.5088 20.201 24.8888 19.821 25.3603 19.821H36.7414C37.213 19.821 37.5929 20.201 37.5929 20.6725C37.5929 21.144 37.2098 21.524 36.7414 21.524Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M19.7538 31.9515H6.95598C6.48447 31.9515 6.10449 31.5715 6.10449 31.1C6.10449 30.6285 6.48447 30.2485 6.95598 30.2485H19.757C20.2285 30.2485 20.6085 30.6285 20.6085 31.1C20.6085 31.5715 20.2253 31.9515 19.7538 31.9515Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M20.7362 40.7825H6.95598C6.48447 40.7825 6.10449 40.4025 6.10449 39.931C6.10449 39.4595 6.48447 39.0795 6.95598 39.0795H20.7362C21.2077 39.0795 21.5877 39.4595 21.5877 39.931C21.5877 40.4025 21.2045 40.7825 20.7362 40.7825Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M36.9539 47.1718C34.2505 47.1718 31.7067 46.1181 29.794 44.2055C27.8814 42.2928 26.8276 39.749 26.8276 37.0455C26.8276 34.3421 27.8814 31.7982 29.794 29.8856C31.7067 27.973 34.2505 26.9192 36.9539 26.9192C39.6574 26.9192 42.2012 27.973 44.1139 29.8856C46.0265 31.7982 47.0802 34.3421 47.0802 37.0455C47.0802 39.749 46.0265 42.2928 44.1139 44.2055C42.2012 46.1181 39.6574 47.1718 36.9539 47.1718ZM36.9539 28.6233C32.3101 28.6233 28.5317 32.4017 28.5317 37.0455C28.5317 41.6893 32.3101 45.4678 36.9539 45.4678C41.5977 45.4678 45.3762 41.6893 45.3762 37.0455C45.3762 32.4017 41.5977 28.6233 36.9539 28.6233Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
                <path
                  d="M35.3325 40.7857C35.1058 40.7857 34.8887 40.6973 34.7291 40.5377L32.1991 38.0077C31.8659 37.6745 31.8659 37.1349 32.1991 36.805C32.5322 36.4718 33.0718 36.4718 33.4018 36.805L35.3315 38.7346L40.5011 33.5651C40.8342 33.2319 41.3738 33.2319 41.7038 33.5651C42.0369 33.8982 42.0369 34.4378 41.7038 34.7678L35.9318 40.5398C35.7753 40.692 35.5593 40.7846 35.3325 40.7846V40.7857Z"
                  fill="#F15830"
                  stroke="#F15830"
                  stroke-width="1.2"
                />
              </svg>
            ) : (
              <svg
                width="30"
                height="30"
                viewBox="0 0 48 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M38.4289 51H3.94373C2.32096 51 1 49.6737 1 48.0444V3.95561C1 2.32629 2.32096 1 3.94373 1H38.4258C40.0485 1 41.3695 2.32629 41.3695 3.95561V28.5288C41.3695 29.0015 40.9901 29.3824 40.5193 29.3824C40.0485 29.3824 39.6691 29.0015 39.6691 28.5288V3.95561C39.6691 3.26526 39.1133 2.70721 38.4258 2.70721H3.94373C3.25615 2.70721 2.70035 3.26953 2.70035 3.95561V48.0444C2.70035 48.7347 3.25615 49.2928 3.94373 49.2928H38.4289C39.1165 49.2928 39.6723 48.7347 39.6723 48.0444V45.7428C39.6723 45.2702 40.0517 44.8892 40.5225 44.8892C40.9933 44.8892 41.3727 45.2702 41.3727 45.7428V48.0444C41.3727 49.6737 40.0517 51 38.4289 51Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M13.9589 16.5428C11.3119 16.5428 9.15869 14.3896 9.15869 11.7425C9.15869 9.09549 11.3119 6.94229 13.9589 6.94229C16.606 6.94229 18.7592 9.09549 18.7592 11.7425C18.7592 14.3896 16.606 16.5428 13.9589 16.5428ZM13.9589 8.64526C12.2528 8.64526 10.8617 10.0364 10.8617 11.7425C10.8617 13.4487 12.2528 14.8398 13.9589 14.8398C15.6651 14.8398 17.0562 13.4487 17.0562 11.7425C17.0562 10.0364 15.6651 8.64526 13.9589 8.64526Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M20.8555 23.4441C20.384 23.4441 20.004 23.0641 20.004 22.5926C20.004 19.258 17.2899 16.547 13.9585 16.547C10.627 16.547 7.91293 19.2612 7.91293 22.5926C7.91293 23.0641 7.53296 23.4441 7.06145 23.4441C6.58994 23.4441 6.20996 23.0641 6.20996 22.5926C6.20996 20.5245 7.01568 18.5768 8.48024 17.1143C9.94586 15.6487 11.8894 14.8441 13.9585 14.8441C16.0276 14.8441 17.9743 15.6498 19.4367 17.1143C20.9024 18.58 21.707 20.5235 21.707 22.5926C21.707 23.0609 21.327 23.4441 20.8555 23.4441Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M36.7414 11.6297H25.3603C24.8888 11.6297 24.5088 11.2498 24.5088 10.7782C24.5088 10.3067 24.8888 9.92676 25.3603 9.92676H36.7414C37.213 9.92676 37.5929 10.3067 37.5929 10.7782C37.5929 11.2498 37.2098 11.6297 36.7414 11.6297Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M36.7414 21.524H25.3603C24.8888 21.524 24.5088 21.144 24.5088 20.6725C24.5088 20.201 24.8888 19.821 25.3603 19.821H36.7414C37.213 19.821 37.5929 20.201 37.5929 20.6725C37.5929 21.144 37.2098 21.524 36.7414 21.524Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M19.7538 31.9515H6.95598C6.48447 31.9515 6.10449 31.5715 6.10449 31.1C6.10449 30.6285 6.48447 30.2485 6.95598 30.2485H19.757C20.2285 30.2485 20.6085 30.6285 20.6085 31.1C20.6085 31.5715 20.2253 31.9515 19.7538 31.9515Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M20.7362 40.7825H6.95598C6.48447 40.7825 6.10449 40.4025 6.10449 39.931C6.10449 39.4595 6.48447 39.0795 6.95598 39.0795H20.7362C21.2077 39.0795 21.5877 39.4595 21.5877 39.931C21.5877 40.4025 21.2045 40.7825 20.7362 40.7825Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M36.9539 47.1718C34.2505 47.1718 31.7067 46.1181 29.794 44.2055C27.8814 42.2928 26.8276 39.749 26.8276 37.0455C26.8276 34.3421 27.8814 31.7982 29.794 29.8856C31.7067 27.973 34.2505 26.9192 36.9539 26.9192C39.6574 26.9192 42.2012 27.973 44.1139 29.8856C46.0265 31.7982 47.0802 34.3421 47.0802 37.0455C47.0802 39.749 46.0265 42.2928 44.1139 44.2055C42.2012 46.1181 39.6574 47.1718 36.9539 47.1718ZM36.9539 28.6233C32.3101 28.6233 28.5317 32.4017 28.5317 37.0455C28.5317 41.6893 32.3101 45.4678 36.9539 45.4678C41.5977 45.4678 45.3762 41.6893 45.3762 37.0455C45.3762 32.4017 41.5977 28.6233 36.9539 28.6233Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
                <path
                  d="M35.3325 40.7857C35.1058 40.7857 34.8887 40.6973 34.7291 40.5377L32.1991 38.0077C31.8659 37.6745 31.8659 37.1349 32.1991 36.805C32.5322 36.4718 33.0718 36.4718 33.4018 36.805L35.3315 38.7346L40.5011 33.5651C40.8342 33.2319 41.3738 33.2319 41.7038 33.5651C42.0369 33.8982 42.0369 34.4378 41.7038 34.7678L35.9318 40.5398C35.7753 40.692 35.5593 40.7846 35.3325 40.7846V40.7857Z"
                  fill="#CDA59C"
                  stroke="#B1897F"
                  stroke-width="1.2"
                />
              </svg>
            )
          }
        ></Tab>
      </Tabs>

      <TabPanel value={value} index={0}>
        <PersonalForm
          setValue={setValue}
          onClick={handleCloseForm}
          setOpenFormExitPopup={setOpenFormExitPopup}
        />
      </TabPanel>
    </Box>
  );
}
