import React from "react";
import AddEmployeeIcon from "../assets/icon/AddEmployeeIcon.svg";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import AddEmployeeModal from "./ModalViews/AddEmployee/addEmployeeModal";

const useStyles = makeStyles(() => ({
  empAddEmployeeBtn: {
    height: "38px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: " #f15830 !important",
    background: "#ffffff !important",
    border: " 1px solid #f15830 !important",
    borderRadius: "10px !important",
  },
}));

const PopUpForm = (props) => {
  const { show, setShow } = props;
  // const [isNcg, setIsNcg] = React.useState<any>(
  //   reduxState.roles.includes("NCG")
  // );
  // const [isFinance, setIsFinance] = React.useState<any>(
  //   reduxState.roles.includes("Finance")
  // );

  // const [isSoftwareArchitect, setIsSoftwareArchitect] = React.useState<any>(
  //   reduxState.roles.includes("Software Architect")
  // );
  // const [isTechnicalArchitect, setIsTechnicalArchitect] = React.useState<any>(
  //   reduxState.roles.includes("Technical Architect")
  // );

  const classes = useStyles();
  // const [show, setShow] = useState(false);

  return (
    <>
      {/* {isNcg || isFinance || isSoftwareArchitect || isTechnicalArchitect ? (
        <div></div>
      ) : ( */}
      <Button
        className={classes.empAddEmployeeBtn}
        onClick={() => setShow(true)}
      >
        <img
          style={{ marginRight: "0.6vw", width: "16px" }}
          src={AddEmployeeIcon}
          alt="icon"
        ></img>
        Add Employee
      </Button>
      {/* )} */}

      {show ? <AddEmployeeModal show={show} setShow={setShow} /> : null}

      {/* <Modal
        open={show}
        onClose={() => {
          setShow(false);
          dispatch(setOfficialEmailId(""));
        }}
      >
        <Box sx={style}>
          <FormsDetails setOpen={setShow} addBtn={true} />
        </Box>
      </Modal> */}
    </>
  );
};
export default PopUpForm;
