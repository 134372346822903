import { request, ResponseProps } from "./request";

export const leaveReportRequest = async (
  formDate: string,
  toDate: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/report?fromDate=${formDate}&toDate=${toDate}`
  )) as ResponseProps;
  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: "Some error occured" };
};

export const leaveHistoryRequest = async (
  formDate: string,
  toDate: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  const r: ResponseProps = (await request.get(
    `${sessionStorage.getItem(
      "gatewayURL"
    )}/emp360-backend/v1/leave-history/audit?fromDate=${formDate}&&toDate=${toDate}`
  )) as ResponseProps;

  if (r.success) {
    const form = r.data as any;
    return { success: r.success, data: form, message: r.message };
  }

  return { success: false, message: "Some error occured" };
};
