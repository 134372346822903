import { Typography } from "@mui/material";
import React from "react";

const styles = {
  typography: {
    greeting: {
      lineHeight: "1.2",
      marginTop: "12px",
      // color: "black",
      fontWeight: "bold",
      fontSize: "38px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      textAlign: "start",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      maxWidth:"750px"
    
    },
  },
};

const Greeting = ({ userName }: any) => {
  const getGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) return "Good Morning";
    if (hours < 16) return "Good Afternoon";
    return "Good Evening";
  };

  return (
    <div>
      <Typography className="shimmer" sx={styles.typography.greeting}>
        {getGreeting()}, {userName}!
      </Typography>
    </div>
  );
};

export default Greeting;
