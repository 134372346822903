import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FileUploader } from "react-drag-drop-files";
import { HolidaysUpload } from "../services/DocumentService";
import { pushNotification } from "../redux/actions";
import CONSTANTS, { HOLIDAYS_FORMID } from "../constants/constants";
import { useDispatch } from "react-redux";
import { fetchRuntimeFormData } from "../services/formService";
import {
  leaveHistoryRequest,
  leaveReportRequest,
} from "../services/AdminService";

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#F15830", // Custom color for the calendar icon
        },
      },
    },
  },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  screen: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgb(227, 231, 239)",
    padding: "2vw",
  },
  containerMain: {
    backgroundColor: "#fff",
    height: "100%",
    font: "Roboto",
    borderRadius: "10px",
    overflow: "auto",
  },
  fieldTitle: {
    font: "Roboto",
    fontWeight: 500,
    fontSize: "23px",
    lineHeight: "26.95px",
    color: "#162952",
  },
  fieldInput: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 5,
    flexDirection: "column",
    gap: 6,
  },
  holidayCard: {
    border: "1px solid #ACACAC",
    borderRadius: "5px",
    width: "15vw",
    // height: "70px",
    backgroundColor: "#F8E7E3",
  },
  leaveReportSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    flexWrap: "wrap",
  },
  leaveReportButton: {
    backgroundColor: "#162952",
    color: "#ffff",
  },
  yearDropdown: {
    float: "right",
    marginBottom: "5px",
  },
};

const Admin = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const fileTypes = ["CSV"];
  const [yearsList, setYearsList] = React.useState<any>([]);
  const [selectedYear, setSelectedYear] = React.useState("");

  const [holidays, setHolidays] = React.useState<any>({});

  const [openLeaveType, setOpenLeaveType] = React.useState(false);
  const handleCloseLeaveType = () => setOpenLeaveType(false);

  const [openEmployeeType, setOpenEmployeeType] = React.useState(false);
  const handleCloseEmployeeType = () => setOpenEmployeeType(false);

  const [openHolidays, setOpenHolidays] = React.useState(false);
  const handleOpenHolidays = () => setOpenHolidays(true);
  const handleCloseHolidays = () => setOpenHolidays(false);

  const handleFileChange = async (file: any) => {
    const response: any = await HolidaysUpload(file);

    if (response?.data?.success) {
      setOpenHolidays(false);
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.data?.message,

          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.data?.message,

          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handleGetHolidaysList = async () => {
    const response: any = await fetchRuntimeFormData(HOLIDAYS_FORMID);
    if (response?.success) {
      let mapData: any = response?.data?.content[0]?.formData?.holidayList;
      setYearsList(Object.keys(mapData));
      setHolidays(mapData);
      setSelectedYear(Object.keys(mapData)[1]);
    }
  };

  useEffect(() => {
    handleGetHolidaysList();
  }, [openHolidays]);

  const handleGetLeaveReport = async (startDate, endDate) => {
    setIsloading(true);
    const response = await leaveReportRequest(startDate, endDate);
    if (response.success) {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setStartDate("");
      setEndDate("");
    } else {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const handleGetLeaveHistory = async (startDate, endDate) => {
    setIsloading(true);
    const response = await leaveHistoryRequest(startDate, endDate);
    if (response.success) {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.SUCCESS,
        })
      );
      setStartDate("");
      setEndDate("");
    } else {
      setIsloading(false);
      dispatch(
        pushNotification({
          isOpen: true,
          message: response.message,
          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  return (
    <Box sx={styles.screen}>
      <Box sx={styles.containerMain}>
        <Modal
          keepMounted
          open={openHolidays}
          onClose={handleCloseHolidays}
          id="Holidays"
        >
          <Box sx={style}>
            <FileUploader
              multiple={false}
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
            />
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openLeaveType}
          onClose={handleCloseLeaveType}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              sx={styles.fieldTitle}
            >
              Enter Details
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              <Box sx={styles.fieldInput}>
                <label>Task Type</label>
                <TextField size="small" id="leave-type" />
              </Box>
              <Box sx={styles.fieldInput}>
                <FormGroup sx={{ flexDirection: "row" }}>
                  <FormControlLabel control={<Checkbox />} label="Is enable" />
                </FormGroup>
              </Box>
              <Box>
                <Button>Submit</Button>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={openEmployeeType}
          onClose={handleCloseEmployeeType}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
              sx={styles.fieldTitle}
            >
              Enter Details
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              <Box sx={styles.fieldInput}>
                <label>Emp Type</label>
                <TextField size="small" id="leave-type" />
              </Box>
              <Box sx={styles.fieldInput}>
                <FormGroup sx={{ flexDirection: "row" }}>
                  <FormControlLabel control={<Checkbox />} label="Is enable" />
                </FormGroup>
              </Box>
              <Box>
                <Button>Submit</Button>
              </Box>
            </Typography>
          </Box>
        </Modal>
        <Box padding={5}>
          <Box>
            <Box sx={styles.fieldInput} padding={2}>
              <Box sx={styles.fieldTitle} mb={2}>
                Leave Report
              </Box>

              {isLoading ? (
                <Box sx={styles.loaderContainer}>
                  <CircularProgress size={100} sx={{ color: "red" }} />
                  <Box sx={{ color: "#162952" }}>
                    Please wait we are sending the request...
                  </Box>
                </Box>
              ) : (
                <Box sx={styles.leaveReportSection}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <label>Select Date</label>

                    <Box display={"flex"} flexDirection="row">
                      <Box border={"1px solid #ACACAC"} borderRadius={"5px"}>
                        <ThemeProvider theme={theme}>
                          <Box
                            padding={1}
                            display={"flex"}
                            flexDirection={"row"}
                            gap={2}
                          >
                            <Box display={"flex"} flexDirection={"column"}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture
                                  onChange={(date: any) => {
                                    setStartDate(
                                      moment(date.toISOString()).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  label={"From Date"}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      disabled: true,
                                    },
                                  }}
                                  sx={{
                                    width: 200,
                                  }}
                                  format={"DD/MM/YYYY"}
                                />
                              </LocalizationProvider>

                              <FormHelperText sx={{ color: "red" }}>
                                {}
                              </FormHelperText>
                            </Box>

                            <Box mt={0.5}>to</Box>
                            <Box display={"flex"} flexDirection={"column"}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture
                                  onChange={(date: any) => {
                                    setEndDate(
                                      moment(date.toISOString()).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  label={"To Date"}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      disabled: true,
                                    },
                                  }}
                                  sx={{ width: 200 }}
                                  format={"DD/MM/YYYY"}
                                />
                              </LocalizationProvider>
                              <FormHelperText sx={{ color: "red" }}>
                                {}
                              </FormHelperText>
                            </Box>
                          </Box>
                        </ThemeProvider>
                      </Box>
                      {/* <Box padding={1}>
                      <Button
                        style={styles.leaveReportButton}>
                        DOWNLOAD
                      </Button>
                    </Box> */}
                      <Box padding={1}>
                        <Button
                          disabled={!startDate || !endDate}
                          onClick={() =>
                            handleGetLeaveReport(startDate, endDate)
                          }
                          style={styles.leaveReportButton}
                        >
                          LEAVE REPORT
                        </Button>
                      </Box>

                      <Box padding={1}>
                        <Button
                          disabled={!startDate || !endDate}
                          onClick={() =>
                            handleGetLeaveHistory(startDate, endDate)
                          }
                          style={styles.leaveReportButton}
                        >
                          LEAVE HISTORY
                        </Button>
                      </Box>
                    </Box>
                    {/* <Box>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Email report"
                    />
                  </Box> */}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} flexDirection={"column"} padding={2} gap={1}>
            <Box sx={styles.fieldTitle} mb={2}>
              Holidays
              <Box sx={styles.yearDropdown}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="year-label">Year</InputLabel>
                  <Select
                    size="small"
                    labelId="year-label"
                    id="yearLabel"
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => {
                      setSelectedYear(e.target.value);
                    }}
                    sx={{ width: "100px" }}
                  >
                    {yearsList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box className="ContainerScrollTm">
              <Box display={"flex"} flexDirection={"row"} gap={2}>
                {holidays[selectedYear]?.map((item: any, index) => {
                  return (
                    <Box>
                      <Card sx={styles.holidayCard}>
                        <Box padding={1}>
                          <Box color={"#F15830"} fontSize={"1.2vw"}>
                            {new Date(item?.Date).toLocaleDateString("en-US", {
                              weekday: "short",
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            })}
                          </Box>
                          <Box color="#000000" fontSize={"1vw"}>
                            {item?.Purpose}
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box padding={1}>
              <Button
                style={styles.leaveReportButton}
                onClick={handleOpenHolidays}
              >
                Upload Holiday
              </Button>
            </Box>
          </Box>

          {/* <Divider />
          <Box display={"flex"} flexDirection={"column"} padding={2}>
            <Box
              sx={{
                font: "Roboto",
                fontWeight: 500,
                fontSize: "23px",
                lineHeight: "26.95px",
                color: "#162952",
              }}
              mb={2}
            >
              Configure Leave Type
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"row"}>
                <Box>
                  <FormGroup sx={{ flexDirection: "row" }}>
                    <Box
                      position="relative"
                      onMouseEnter={handleMouseEnterLeaveType}
                      onMouseLeave={handleMouseLeaveLeaveType}
                      minWidth={"8vw"}
                    >
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="General"
                      />
                      {isHoveredLeaveType && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ backgroundColor: "#F15830" }}
                        >
                          <Box display="flex" flexDirection={"row"} gap={1}>
                            <Button
                              size="sm"
                              onClick={handleEditLeaveType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              size="sm"
                              onClick={handleDeleteLeaveType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </FormGroup>
                </Box>
                <MUIButton
                  onClick={handleOpenLeaveType}
                  variant="outlined"
                  startIcon={<AddBoxIcon color="primary" />}
                  sx={{ color: "#ACACAC", borderColor: "#ACACAC" }}
                >
                  Add New Leave Type
                </MUIButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} flexDirection={"column"} padding={2}>
            <Box
              sx={{
                font: "Roboto",
                fontWeight: 500,
                fontSize: "23px",
                lineHeight: "26.95px",
                color: "#162952",
              }}
              mb={2}
            >
              Configure Employee / Employment Type
            </Box>
            <Box>
              <Box display={"flex"} flexDirection={"row"}>
                <Box>
                  <FormGroup sx={{ flexDirection: "row" }}>
                    <Box
                      position="relative"
                      onMouseEnter={handleMouseEnterEmployeeType}
                      onMouseLeave={handleMouseLeaveEmployeeType}
                      minWidth={"8vw"}
                    >
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Intern"
                      />
                      {isHoveredEmployeeType && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          right={0}
                          bottom={0}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{ backgroundColor: "#F15830" }}
                        >
                          <Box display="flex" flexDirection={"row"} gap={1}>
                            <Button
                              size="sm"
                              onClick={handleEditEmployeeType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <EditIcon />
                            </Button>
                            <Button
                              size="sm"
                              onClick={handleDeleteEmployeeType}
                              style={{
                                backgroundColor: "#F15830",
                                border: "1px solid white",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </FormGroup>
                </Box>
                <MUIButton
                  onClick={handleOpenEmployeeType}
                  variant="outlined"
                  startIcon={<AddBoxIcon color="primary" />}
                  sx={{ color: "#ACACAC", borderColor: "#ACACAC" }}
                >
                  Add New Emp Type
                </MUIButton>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
