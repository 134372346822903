import "./CardSwipeSystem.css";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Row from "./Row";
import TablePaginationActions from "./TablePaginationActions";
import rows from "../../constants/cardSwipeData";
import { iSwipeData } from "../../types/types";
import { cardSwipeSystem } from "../../constants/common";

const CardSwipeSystem = () => {
  const [employee, setEmployee] = useState("");
  const [filterValue, setFilterValue] = useState("wk");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [employeeRowData, setEmployeeRowData] = useState<iSwipeData[]>(
    rows.filter((emp, i) => i < 7)
  );

  const handleEmployeeChange = (event: SelectChangeEvent) => {
    setEmployee(event.target.value as string);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue((event.target as HTMLInputElement).value);
    if (event.target.value === "wk") {
      setEmployeeRowData(rows.filter((emp, i) => i < 7));
      setPage(0);
    } else if (event.target.value === "15d") {
      setEmployeeRowData(rows.filter((emp, i) => i < 15));
      setPage(0);
    } else if (event.target.value === "1m") {
      setEmployeeRowData(rows.filter((emp, i) => i < 30));
      setPage(0);
    } else if (event.target.value === "3m") {
      setEmployeeRowData(rows.filter((emp, i) => i < 90));
      setPage(0);
    } else if (event.target.value === "6m") {
      setEmployeeRowData(rows.filter((emp, i) => i < 180));
      setPage(0);
    } else if (event.target.value === "1y") {
      setEmployeeRowData(rows.filter((emp, i) => i < 365));
      setPage(0);
    }
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - employeeRowData.length)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  return (
    <div className="card-swipe-management">
      <h5>{cardSwipeSystem.cardSwipeManagement}</h5>
      <Box sx={{ maxWidth: 250, my: 2 }}>
        <FormControl fullWidth>
          <InputLabel>{cardSwipeSystem.selectEmployee}</InputLabel>
          <Select
            value={employee}
            label={cardSwipeSystem.selectEmployee}
            onChange={handleEmployeeChange}
          >
            <MenuItem value={"Employee1"}>{cardSwipeSystem.employee1}</MenuItem>
            <MenuItem value={"Employee2"}>{cardSwipeSystem.employee2}</MenuItem>
            <MenuItem value={"Employee3"}>{cardSwipeSystem.employee3}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {employee.length > 0 ? (
        <>
          <FormControl>
            <FormLabel>{cardSwipeSystem.dateRange}</FormLabel>
            <RadioGroup row value={filterValue} onChange={handleFilterChange}>
              <FormControlLabel
                value="wk"
                control={<Radio />}
                label={cardSwipeSystem.oneWeeek}
              />
              <FormControlLabel
                value="15d"
                control={<Radio />}
                label={cardSwipeSystem.fifteenDays}
              />
              <FormControlLabel
                value="1m"
                control={<Radio />}
                label={cardSwipeSystem.oneMonth}
              />
              <FormControlLabel
                value="3m"
                control={<Radio />}
                label={cardSwipeSystem.threeMonths}
              />
              <FormControlLabel
                value="6m"
                control={<Radio />}
                label={cardSwipeSystem.sixMonths}
              />
              <FormControlLabel
                value="1y"
                control={<Radio />}
                label={cardSwipeSystem.oneYear}
              />
            </RadioGroup>
          </FormControl>
          <TableContainer component={Paper}>
            <Table sx={{ border: "2px solid grey" }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.date}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.day}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.project}
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.clockIn}
                    <Tooltip title={cardSwipeSystem.firstSwipeIn}>
                      <IconButton>
                        <PriorityHighIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.clockOut}
                    <Tooltip title={cardSwipeSystem.lastSwipeIn}>
                      <IconButton>
                        <PriorityHighIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {cardSwipeSystem.total}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? employeeRowData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : employeeRowData
                ).map((row) => (
                  <Row key={Math.random() * 100} row={row} />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      7,
                      15,
                      30,
                      { label: "All", value: -1 },
                    ]}
                    count={employeeRowData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>{cardSwipeSystem.selectEmployeeToShowSwipeData}</>
      )}
    </div>
  );
};

export default CardSwipeSystem;
