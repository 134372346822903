import wretch from "wretch";
import CONSTANTS from "../constants/constants";

const CREATE_VISITOR_END_POINT = "/create";
const FILTER_VISITORS_END_POINT = "/filter";
const CHECK_IN_VISITORS_END_POINT = "/checkin";
const CHECK_OUT_VISITORS_END_POINT = "/checkout";
const APPROVE_REJECT_VISITORS_END_POINT = "/approve-reject";
const DOWNLOAD_ALL_VISITORS_END_POINT = "/download/all";

// Function to create a new visitor
export const createOrUpdate = async (visitor: any) => {
  try {
    const response = await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${CREATE_VISITOR_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .post(visitor)
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};

// Function to get visitors with filters
export const getVisitors = async (
  searchText: string,
  date: string,
  status: string,
  page: number,
  pageSize: number
) => {
  try {
    const params = {
      searchText,
      date,
      status,
      page: page.toString(),
      pageSize: pageSize.toString(),
    };
    const response = await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${FILTER_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .query(params)
      .get()
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};

// Function to check in a visitor
export const checkinVisitor = async (id: number, datetime: string) => {
  try {
    const response = await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${CHECK_IN_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, datetime })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};

// Function to check out a visitor
export const checkoutVisitor = async (id: number, datetime: string) => {
  try {
    const response = await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${CHECK_OUT_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, datetime })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};
export const approveOrRejectVisitor = async (id: number, status: string) => {
  try {
    const response = await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${APPROVE_REJECT_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .post({ id, status })
      .json();
    return response;
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};

// Function to download visitors as an Excel file
export const downloadVisitorsExcel = async () => {
  try {
    await wretch(
      `${`${sessionStorage.getItem(
        "gatewayURL"
      )}/emp360-backend/v1/visitors`}${DOWNLOAD_ALL_VISITORS_END_POINT}`
    )
      .auth(`Bearer ${sessionStorage.getItem(CONSTANTS.REACT_TOKEN)}`)
      .get()
      .blob((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "visitors.xlsx"); // You can set the file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  } catch (error: any) {
    throw new Error(
      error?.message ? JSON.parse(error?.message).message : "Some error occured"
    );
  }
};
