import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FINANCE_FORM_ID,
  JOB_DETAILS_FORM_ID,
  PERSONAL_FORM_ID,
} from "../constants/formsConstants";
import MoreIcon from "./moreIcon";
import "./accordion.css";
import dayjs from "dayjs";
import {
  EmployeeTenureCount,
  formatDate,
  getFullName,
  splitPhoneByCountryCode,
} from "../services/commonHelperService";

export const relations = [
  PERSONAL_FORM_ID,
  FINANCE_FORM_ID,
  JOB_DETAILS_FORM_ID,
];

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  accordionSection1: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "20px",

    // gap: 60,
    "@media only screen and (min-width: 1024px) and (max-width: 1440px)": {
      gap: "60px",
      /* margin-left: 50px; */
    },
    "@media only screen and (min-width: 768px) and (max-width: 1024px)": {
      gap: "23px",
    },

    "@media only screen and (min-width: 1440px) and (max-width: 2560px)": {
      gap: "80px",
    },
    "@media only screen and (min-width: 1600px)": {
      gap: "110px",
    },

    "@media only screen and (min-width: 2560px)": {
      gap: "240px",
    },
  },
  accordionDeactivateStyle: {
    opacity: "50%",
  },
  deactivateAccordionText: {
    position: "absolute",
    top: 0,
    right: 0,
    background: "linear-gradient(268.1deg, #FFFFFF 7.66%, #BEBEBE 89.89%)",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "15px",
    color: "#B16B59",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "15px",
    letterSpacing: "0.185em",
    textAlign: "left",
  },
  lastWorkingDayAccordionText: {
    position: "absolute",
    bottom: 0,
    right: 0,
    background: "linear-gradient(268.1deg, #FFFFFF 7.66%, #BEBEBE 89.89%)",
    borderRadius: "0px 10px 17px 0px",
    color: "#B16B59",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "15px",
    letterSpacing: "0.185em",
    textAlign: "left",
  },
  employeeTileContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  employeeTile: {
    display: "flex",
    width: "100%",
    alignItems: "center",

    "@media only screen and (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  nameDetailsContainer: {
    display: "flex",
    width: "40%",
    alignItems: "center",

    "@media only screen and (max-width: 768px)": {
      borderBottom: "1px solid #DBDBDB",
      borderRight: 0,
      width: "100%",
    },
  },
  demographicDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRight: "1px solid #DBDBDB",
    borderLeft: "1px solid #DBDBDB",

    "@media only screen and (max-width: 768px)": {
      borderRight: 0,
      borderLeft: 0,
    },
  },
  basicDetailsContainer: {
    borderBottom: "1px solid #DBDBDB",
    width: "100% !important",
    margin: "unset !important",
  },
  leaveDetailsContainer: {
    width: "100% !important",
    margin: "unset !important",
  },
  employeeDetail: {
    fontFamily: "Roboto",
    fontSize: "13px",
    color: "#162952",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",

    "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
      fontSize: "14px",
    },
  },
  leaveDetail: {
    fontFamily: "Roboto",
    fontSize: "12px",
    color: "#162952",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",

    "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
      fontSize: "14px",
    },
  },
  leaveCount: {
    fontFamily: "Roboto",
    fontSize: "15px",
    color: "#162952",
    fontWeight: "bold",

    "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
      fontSize: "14px",
    },
  },
  nameAndDesignationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  employeeNameText: {
    fontFamily: "Roboto",
    fontSize: "15px",
    color: "#162952",
    fontWeight: "bold",
    textTransform: "capitalize",

    "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
      fontSize: "16px",
    },
  },
  designationText: {
    fontFamily: "Roboto",
    fontSize: "12px",
    color: "#162952",
    textTransform: "capitalize",

    "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
      fontSize: "14px",
    },
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={4} {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
  width: "100%",
  marginBottom: "2.5vh",
  borderRadius: "15px",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "15px",
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const styles = {
  typography: {
    designationText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      textAlign: "start",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      maxWidth: "300px",
      fontFamily: "Roboto",
      fontSize: "12px",
      color: "#162952",
      textTransform: "capitalize",

      "@media only screen and (min-width: 1441px) and (max-width: 2560px)": {
        fontSize: "14px",
      },
    },
  },
  icons: {
    locationIcon: { color: "#F15830", fontSize: "small" },
  },
};

const TsAccordion = (props: any) => {
  const {
    setIsAdmin,
    isLead,
    setIsLead,
    setIsFinance,
    setIsNcg,
    setIsHrAdmin,
    allEmployeesData,
    setShowView,
    setRefresh,
    selectedEmployeeList,
    selectEmployee,
  } = props;
  const classes = useStyles();
  // const [action, setAction] = React.useState<any>("");

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: bgColor2,
  }));

  const [expanded, setExpanded] = React.useState<string | false>("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      event.preventDefault();
      event.stopPropagation();
      setExpanded(newExpanded ? panel : false);
    };

  const bgColor2 = "#FFEDE9";

  const sectionStyles = {
    keyFontFamily: "Roboto",
    keyFontSize: "16px",
    valueFontFamily: "Roboto",
    valueFontSize: "14px",
    keyTextColor: "#AB8277",
    valueTextColor: "#162952",
    rowGap: 7.5,
    iconGap: 5,
  };

  // const actions = [
  //   {
  //     actionId: "edit",
  //     actionName: "View/Edit",
  //   },
  //   {
  //     actionId: "deactivate",
  //     actionName: "Deactivate",
  //   },
  // ];

  // const handleStatusChange = async (statusData, Id) => {
  //   let payload = {
  //     formId: PERSONAL_FORM_ID,
  //     id: Id,
  //     formData: {
  //       status: statusData,
  //     },
  //   };
  //   const response = await patchDataToForm(payload);
  // };

  // const handleActionClick = (event, row, id) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   console.log("row", row);
  //   console.log("id", id);
  //   if (id === "edit") {
  //     dispatch(setParentDataId(row.id));
  //     handleClose();
  //     setShow(true);
  //   } else if (id === "view") {
  //   } else {
  //     handleStatusChange("deactivate", row.id);
  //     handleClose();
  //   }
  // };

  const handleProfileImageClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  const handleEmployeeSelect = (event, empId: any) => {
    event.preventDefault();
    event.stopPropagation();
    selectEmployee(empId);
  };

  const isEmployeeSelected = (empId) => {
    return selectedEmployeeList.includes(empId);
  };

  const isSelectDisabled = (status) => {
    return status === "Inactive";
  };

  const formatDateAndTime = (dateString) => {
    const date = dayjs(dateString);
    const formattedTime = date?.isValid() ? date.format("hh:mm A") : "";
    const formattedDate = date?.isValid() ? date.format("D[th] MMM, YYYY") : "";
    return `${formattedTime}, ${formattedDate}`;
  };

  const getEmployeeDetails = (data, index) => {
    const locationText =
      data?.liveLocation?.split(";")[0] || "No location found";
    const dateText = data?.liveLocation?.split(";")[1];
    const formattedDateAndTime = dateText ? formatDateAndTime(dateText) : "";
    return (
      <Box className={classes.employeeTileContainer} key={index}>
        <Box className={classes.employeeTile}>
          <div className={classes.nameDetailsContainer}>
            <div>
              <Checkbox
                size="small"
                checked={isEmployeeSelected(data.empId)}
                onClick={(event) => {
                  handleEmployeeSelect(event, data.empId);
                }}
                disabled={isSelectDisabled(data.status)}
              />
            </div>
            <div>
              <Button
                id="profile-img-btn"
                name="profileBtn"
                onClick={handleProfileImageClick}
              >
                <Avatar
                  alt="Profile-Image"
                  sx={{
                    width: 45,
                    height: 45,
                    backgroundColor: stringToColor(
                      `${getFullName([data?.firstName, data?.lastName])}`
                    ),
                  }}
                  {...stringAvatar(
                    getFullName([data?.firstName, data?.lastName])
                  )}
                />
              </Button>
            </div>
            <div className={classes.nameAndDesignationContainer}>
              <span className={classes.employeeNameText}>
                {getFullName([data?.firstName, data?.lastName])}&nbsp;-&nbsp;
                {data.empId}
              </span>
              <span className={classes.designationText}>
                {data.designation}
              </span>
              <div
                className={classes.nameAndDesignationContainer}
                style={{
                  gap: "2px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LocationOnIcon sx={styles.icons.locationIcon} />
                <span className={classes.designationText}>
                  <Tooltip
                    title={
                      data?.liveLocation !== undefined
                        ? `${locationText} (${formattedDateAndTime})`
                        : "No location found"
                    }
                  >
                    <Typography
                      className={classes.designationText}
                      sx={[styles.typography.designationText]}
                    >
                      {data?.liveLocation !== undefined
                        ? `${locationText} (${formattedDateAndTime})`
                        : "No location found"}
                    </Typography>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className={classes.demographicDetailsContainer}>
            <Grid
              container
              spacing={1}
              className={classes.basicDetailsContainer}
            >
              <Grid item xs={6} md={3} lg={12 / 5} xl={12 / 5}>
                <Tooltip title={`Phone : ${data?.phone}`} arrow>
                  <span className={classes.employeeDetail}>
                    {data?.phone
                      ? splitPhoneByCountryCode("in", data?.phone)
                      : "---"}
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={3} lg={12 / 5} xl={12 / 5}>
                <Tooltip title={`Email : ${data?.officialEmail}`} arrow>
                  <span className={classes.employeeDetail}>
                    {data?.officialEmail || "---"}
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={3} lg={12 / 5} xl={12 / 5}>
                <Tooltip title={`DOB: ${formatDate(data?.dob)}`} arrow>
                  <span className={classes.employeeDetail}>
                    {formatDate(data?.dob)}
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={6} md={3} lg={12 / 5} xl={12 / 5}>
                <span className={classes.employeeDetail}>
                  {data?.location || "---"}
                </span>
              </Grid>
              <Grid item xs={6} md={3} lg={12 / 5} xl={12 / 5}>
                <span className={classes.employeeDetail}>
                  {data?.employeeType || "---"}
                </span>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              className={classes.leaveDetailsContainer}
            >
              <Grid item xs={12} sm={6} md={4}>
                <span className={classes.leaveDetail}>
                  <span className={classes.leaveCount}>
                    {data?.leaveDetails?.general || 0}
                  </span>
                  &nbsp; General Leaves
                </span>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <span className={classes.leaveDetail}>
                  <span className={classes.leaveCount}>
                    {data?.leaveDetails?.privilege || 0}
                  </span>
                  &nbsp; Privilege Leaves
                </span>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <span className={classes.leaveDetail}>
                  <span className={classes.leaveCount}>
                    {data?.compOffCount || 0}
                  </span>
                  &nbsp; Comp-Off
                </span>
              </Grid>
            </Grid>
          </div>
        </Box>
        <Box>
          <MoreIcon
            row={data}
            // setShow={setShow}
            // setAction={setAction}
            isLead={isLead}
            setIsLead={setIsLead}
            // isAdmin={isAdmin}
            setIsAdmin={setIsAdmin}
            // isFinance={isFinance}
            setIsFinance={setIsFinance}
            // isNcg={isNcg}
            setIsNcg={setIsNcg}
            // isHrAdmin={isHrAdmin}
            setIsHrAdmin={setIsHrAdmin}
            emailId={data?.officialEmail}
            // showView={showView}
            setShowView={setShowView}
            setRefresh={setRefresh}
          />
        </Box>
      </Box>
    );
  };

  const getEmployeeTenure = (date, lastWorkingDay) => {
    const { days, months, years } = EmployeeTenureCount(date, lastWorkingDay);

    return `${years} Years ${months} Months ${days} Days`;
  };

  return (
    <>
      {allEmployeesData?.map((row, index) => {
        return (
          <>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              key={`employeeTile_${row.empId}`}
            >
              <AccordionSummary
                aria-controls={`panel1d-content${index}`}
                id={`panel1d-header${index}`}
                className={`${
                  row?.status === "Inactive"
                    ? classes.accordionDeactivateStyle
                    : ""
                }`}
              >
                {row?.status === "Inactive" ? (
                  <Box className={classes.deactivateAccordionText}>
                    <Box padding={0.4}>InActive</Box>
                  </Box>
                ) : null}

                {row?.status === "Inactive" ? (
                  <Box className={classes.lastWorkingDayAccordionText}>
                    <Box padding={0.4}>LWD: {row?.lwd}</Box>
                  </Box>
                ) : null}
                {getEmployeeDetails(row, index)}
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  className={classes.main}
                  sx={{ justifyContent: "space-evenly", alignItems: "center" }}
                >
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Gender
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      {row?.gender || "---"}
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Marital Status
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      {row?.family?.marriedStatus || "---"}
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Blood Group
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.bloodGroup} arrow>
                        <p>{row?.bloodGroup || "---"}</p>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      DOJ
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.dateOfJoining} arrow>
                        <p>
                          {dayjs(row?.dateOfJoining).format("DD-MMM-YYYY") ||
                            "---"}
                        </p>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Emergency
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip
                        title={row?.emergencyDetails?.emergencyDetails1?.phone}
                        arrow
                      >
                        <p>
                          {row?.emergencyDetails?.emergencyDetails1?.phone ||
                            "---"}
                        </p>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Reporting To
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.reportingTo || "---"} arrow>
                        <Box
                          sx={{
                            width: "100px",
                            overflow: "hidden",
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row?.reportingTo || "---"}
                        </Box>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Department
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.department} arrow>
                        <p>{row?.department || "---"}</p>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
                <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Box
                  className={classes.main}
                  sx={{ justifyContent: "space-evenly", alignItems: "center" }}
                >
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      General Leaves
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <p> {row?.leaveDetails?.general}</p>
                    </Typography>
                  </Box>

                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Privilege Leaves
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <p> {row?.leaveDetails?.privilege}</p>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // width: "150px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                      }}
                    >
                      Personal Email
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.personalEmail} arrow>
                        <p>{row?.personalEmail || "---"}</p>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // width: "180px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                      }}
                    >
                      Employee Tenure
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      <Tooltip title={row?.general?.permanentAddresse} arrow>
                        <p>{getEmployeeTenure(row?.dateOfJoining, row?.lwd)}</p>
                      </Tooltip>
                    </Typography>
                  </Box>
                  {row?.status === "Inactive" && (
                    <Box mr={sectionStyles.rowGap}>
                      <Typography
                        sx={{
                          fontFamily: sectionStyles.keyFontFamily,
                          fontSize: sectionStyles.keyFontSize,
                          color: sectionStyles.keyTextColor,
                        }}
                      >
                        LWD
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: sectionStyles.valueFontFamily,
                          fontSize: sectionStyles.valueFontSize,
                          color: sectionStyles.valueTextColor,
                          width: "100px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Tooltip title={row?.general?.permanentAddress} arrow>
                          <p>
                            {row?.lwd
                              ? new Date(row?.lwd)
                                  .toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")
                              : "..."}
                          </p>
                        </Tooltip>
                      </Typography>
                    </Box>
                  )}
                  <Box mr={sectionStyles.rowGap}>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.keyFontFamily,
                        fontSize: sectionStyles.keyFontSize,
                        color: sectionStyles.keyTextColor,
                        // wordWrap: "break-word",
                        // whiteSpace: "initial",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: sectionStyles.valueFontFamily,
                        fontSize: sectionStyles.valueFontSize,
                        color: sectionStyles.valueTextColor,
                        // width: "180px",
                        // overflow: "hidden",
                        // textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip title={row?.general?.permanentAddress} arrow>
                        <p>{row?.general?.permanentAddress}</p>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </>
  );
};

export default TsAccordion;
