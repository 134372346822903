import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ReactComponent as BloodGroup } from "../../utils/images/bloodGroup.svg";
import {
  AADHAR_NO,
  EMAIL_REGEX,
  GENERAL_FORMID,
  PAN_NO,
  phoneNumberRegex,
} from "../../constants/constants";
import { useDispatch } from "react-redux";
import { setReportingToEmailId } from "../../redux/actions";
import { FilterFormDetailsWithOfficalEmail } from "../../services/formService";
import {
  aadharNumberMsg,
  bloodGroupMsg,
  dobAsPerCertificateMsg,
  dobAsPerCertificateMsg1,
  dobAsPerCertificateMsg2,
  dobAsPerCertificateMsg3,
  empIdMsg1,
  empIdMsg2,
  empTypeMsg,
  firstNameMsg,
  firstNameMsg1,
  genderMsg,
  joiningDateMsg,
  lastNameMsg,
  lastNameMsg1,
  officialEmailMsg,
  officialEmailMsg1,
  panNumberMsg,
  phoneNumberMsg1,
  reportingToMsg,
  reportingToMsg1,
} from "./formConstants";
import { designationRequriedMsg } from "../ModalViews/HrAdmin/forms/formConstants";
import CustomDatePicker from "./CustomDatePicker";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "#f15830",
  },
  label: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  addBtn: {
    marginTop: "15px",
  },
  createEmployeeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  PhoneNumber_country_img: {
    height: "1rem",
    width: "1rem",
  },
  phoneInputStyles: {
    width: "100% !important",

    "@media (min-width: 320px )": {
      width: "100% !important",
    },
    "@media (min-width: 375px)": {
      width: "85% !important",
    },
    "@media (min-width: 425px)": {
      width: "85% !important",
    },
    "@media (min-width: 600px)": {
      width: "100% !important",
    },
    "@media (min-width: 768px)": {
      width: "100% !important",
    },
    "@media (min-width: 1440px)": {
      width: "100% !important",
    },
    "@media (min-width: 2560px)": {
      width: "100% !important",
    },
  },
}));

const PersonalForm = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  let emailId: any = sessionStorage.getItem("user-email");
  const [disableAllFields] = useState(true);
  const bloodTypes = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];

  const [countryCode, setCountryCode] = useState("");
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [countryRegex, setCountryRegex] = useState(
    phoneNumberRegex[countryCode]
  );
  const handlePhoneChange = async (value, country) => {
    if (country && "dialCode" in country) {
      // const countryCode = `+${country.dialCode}`;
      // const phoneNumber = value.slice(countryCode.length);
      // const formattedNumber = `${countryCode} ${phoneNumber}`;
      // setFormattedPhoneNumber(formattedNumber);
    }
    setCountryCode(country.countryCode);
    setCountryRegex(phoneNumberRegex[country.countryCode]);
  };
  const formOne = useFormik({
    initialValues: {
      empId: "",
      employeeType: "",
      officialEmail: "",
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      dobAsPerCertificate: "",
      bloodGroup: "",
      panNumber: "",
      aadharNumber: "",
      reportingTo: "",
      dateOfJoining: "",
      designation: "",
    },
    validationSchema: Yup.object({
      empId: Yup.string().required(empIdMsg1).min(3, empIdMsg2),
      employeeType: Yup.string().required(empTypeMsg),
      officialEmail: Yup.string()
        .required(officialEmailMsg)
        .matches(EMAIL_REGEX, officialEmailMsg1),
      firstName: Yup.string().required(firstNameMsg).min(3, firstNameMsg1),
      lastName: Yup.string().required(lastNameMsg).min(3, lastNameMsg1),
      gender: Yup.string().required(genderMsg),
      phoneNumber: Yup.string()
        .required(phoneNumberMsg1)
        .matches(countryRegex, phoneNumberMsg1),
      dobAsPerCertificate: Yup.date()
        .required(dobAsPerCertificateMsg)
        .test(
          dobAsPerCertificateMsg1,
          dobAsPerCertificateMsg2,
          (date) => moment().diff(moment(date), dobAsPerCertificateMsg3) >= 18
        ),

      bloodGroup: Yup.string().required(bloodGroupMsg),

      panNumber: Yup.string()
        .required(panNumberMsg)
        .max(10)
        .matches(PAN_NO)
        .length(10),
      aadharNumber: Yup.string()
        .required(aadharNumberMsg)
        .max(12)
        .matches(AADHAR_NO)
        .length(12),
      reportingTo: Yup.string()
        .required(reportingToMsg)
        .min(3, reportingToMsg1),

      dateOfJoining: Yup.date().required(joiningDateMsg),
      designation: Yup.string().required(designationRequriedMsg),
      // .test(
      //   joiningDateMsg1,
      //   joiningDateMsg2
      //   // (date) => date === moment()
      // ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    // postFormValues(values);
  };

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      GENERAL_FORMID,
      emailId
    );
    if (response.success && response.data.content.length > 0) {
      let mapData: any = response?.data?.content[0]?.formData;
      dispatch(setReportingToEmailId(mapData?.reportingTo));

      let finalData: any = {
        empId: mapData?.empId,
        employeeType: mapData?.employeeType,
        firstName: mapData?.firstName,
        lastName: mapData?.lastName,
        gender: mapData?.gender,
        phoneNumber: mapData?.phone,
        dobAsPerCertificate: mapData?.dob,
        bloodGroup: mapData?.bloodGroup,
        panNumber: mapData?.panNumber,
        aadharNumber: mapData?.aadharNumber,
        officialEmail: mapData?.officialEmail,
        reportingTo: mapData?.reportingTo,
        dateOfJoining: mapData?.dateOfJoining,
        designation: mapData?.designation,
      };

      formOne.setValues(finalData);
      // formOne.setFieldValue("dateOfJoining", mapData.dateOfJoining, true);
      // formOne.setFieldValue("dobAsPerCertificate", mapData.dob, true);

      // setValue((prevState) => prevState + 1);
    } else {
    }
  };
  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container>
            <Typography
              color={"#f15830"}
              width="100%"
              sx={{
                "@media (min-width: 2000px)": {
                  fontSize: "calc(12px + 1.4rem)",
                },
                "@media (min-width: 1500px) and (max-width: 1999px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 1000px) and (max-width: 1499px)": {
                  fontSize: "calc(12px + 0.8rem)",
                },
                "@media (min-width: 600px) and (max-width: 999px)": {
                  fontSize: "calc(12px + 0.4rem)",
                },
                "@media (max-width: 600px)": {
                  fontSize: "calc(12px + 0.2rem)",
                },
              }}
            >
              General <Divider />
            </Typography>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 2 }}
              columnSpacing={4}
              rowSpacing={3}
              sx={{ paddingTop: "2%" }}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Employee Type*</label>
                  <FormControl size="small">
                    <Select
                      labelId="employeeType"
                      id="employee-type"
                      value={formOne.values.employeeType}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          "employeeType",
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne.touched.employeeType &&
                        Boolean(formOne.errors.employeeType)
                      }
                      disabled={disableAllFields}
                    >
                      <MenuItem value={"FTE"}>FTE</MenuItem>
                      <MenuItem value={"CE"}>CE</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.employeeType &&
                        formOne.errors.employeeType}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Emp ID *</label>
                  <TextField
                    id="emp-id"
                    name="empId"
                    size="small"
                    helperText={formOne.touched.empId && formOne.errors.empId}
                    error={
                      formOne.touched.empId && Boolean(formOne.errors.empId)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.empId}
                    // sx={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Official Email *</label>
                  <TextField
                    size="small"
                    id="officialEmail"
                    name="officialEmail"
                    helperText={
                      formOne.touched.officialEmail &&
                      formOne.errors.officialEmail
                    }
                    error={
                      formOne.touched.officialEmail &&
                      Boolean(formOne.errors.officialEmail)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.officialEmail}
                    // sx={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}> Designation*</label>
                  <TextField
                    id="Designation"
                    name="designation"
                    size="small"
                    helperText={
                      formOne.touched.designation && formOne.errors.designation
                    }
                    error={
                      formOne.touched.designation &&
                      Boolean(formOne.errors.designation)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.designation}
                    // sx={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>
                    {" "}
                    Reporting Manager Email*
                  </label>
                  <TextField
                    id="reporting-to"
                    name="reportingTo"
                    size="small"
                    helperText={
                      formOne.touched.reportingTo && formOne.errors.reportingTo
                    }
                    error={
                      formOne.touched.reportingTo &&
                      Boolean(formOne.errors.reportingTo)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.reportingTo}
                    // sx={{ width: "240px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>

              {/* <Box display={"flex"} flexDirection={"column"} >
                  <label className={classes.label}>Joining Date*</label>
                  <TextField
                    id="joining-date"
                    name="joiningDate"
                    size="small"
                    helperText={
                       formOne.touched.joiningDate && formOne.errors.joiningDate
                    }
                    error={
                       formOne.touched.joiningDate &&
                       Boolean(formOne.errors.joiningDate)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.joiningDate}
                    sx={{ width: "345px"}}
                  />
                </Box> */}
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Joining Date*</label>
                  <CustomDatePicker
                    value={formOne.values.dateOfJoining}
                    handleDateChange={(dateString) => {
                      formOne.setFieldValue("dateOfJoining", dateString, true);
                    }}
                    datePickerdisabled={disableAllFields}
                    textFieldDisabled={disableAllFields}
                    isError={
                      formOne.touched.dateOfJoining &&
                      Boolean(formOne.errors.dateOfJoining)
                    }
                    errorText={
                      formOne.touched.dateOfJoining &&
                      formOne.errors.dateOfJoining
                    }
                    padding={"17.9px 12px"}
                    rest={{}}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>First Name*</label>
                  <TextField
                    id="first-name"
                    name="firstName"
                    size="small"
                    helperText={
                      formOne.touched.firstName && formOne.errors.firstName
                    }
                    error={
                      formOne.touched.firstName &&
                      Boolean(formOne.errors.firstName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.firstName}
                    // sx={{ width: "210px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Last Name*</label>
                  <TextField
                    size="small"
                    id="last-name"
                    name="lastName"
                    helperText={
                      formOne.touched.lastName && formOne.errors.lastName
                    }
                    error={
                      formOne.touched.lastName &&
                      Boolean(formOne.errors.lastName)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.lastName}
                    // sx={{ width: "210px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box display={"flex"} flexDirection={"column"}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: "black",
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                      }}
                      className="generalLabel"
                    >
                      Gender *
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="gender"
                      id="gender-radio"
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.gender}
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                        disabled={disableAllFields}
                      />
                      <FormControlLabel
                        value="Female"
                        control={<Radio />}
                        label="Female"
                        disabled={disableAllFields}
                      />

                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                        disabled={disableAllFields}
                      />
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.gender && formOne.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 3, lg: 3, md: 3, sm: 3 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Phone*</label>
                  <PhoneInput
                    inputClass={classes.phoneInputStyles}
                    country={"in"}
                    inputProps={{
                      id: "phone-number",
                      name: "phoneNumber",
                    }}
                    onBlur={formOne.handleBlur}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                      formOne.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: value,
                        },
                      });
                    }}
                    isValid={!formOne.errors.phoneNumber}
                    disabled={disableAllFields}
                    value={formOne?.values?.phoneNumber}
                  />
                  {formOne.errors.phoneNumber && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {formOne.errors.phoneNumber}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>
                    DOB (as per Certificate)*
                  </label>
                  {/* <TextField
                    id="dobAsPerCertificate"
                    name="dobAsPerCertificate"
                    type="date"
                    helperText={
                      formOne.touched.dobAsPerCertificate &&
                      formOne.errors.dobAsPerCertificate
                    }
                    error={
                      formOne.touched.dobAsPerCertificate &&
                      Boolean(formOne.errors.dobAsPerCertificate)
                    }
                    value={dayjs(formOne.values.dobAsPerCertificate).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={formOne.handleChange}
                    // sx={{ width: 240 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    disabled={disableAllFields}
                  /> */}

                  <CustomDatePicker
                    value={formOne.values.dobAsPerCertificate}
                    errorText={
                      formOne.touched.dobAsPerCertificate &&
                      formOne.errors.dobAsPerCertificate
                    }
                    isError={
                      formOne.touched.dobAsPerCertificate &&
                      Boolean(formOne.errors.dobAsPerCertificate)
                    }
                    handleDateChange={(dateString) => {
                      // console.log("myoff", event);
                      // formOne.handleChange(dateString);
                      formOne.setFieldValue(
                        "dobAsPerCertificate",
                        dateString,
                        true
                      );
                      // setButtonDisabled(false);
                    }}
                    datePickerdisabled={true}
                    textFieldDisabled={true}
                    rest={{
                      id: "dobAsPerCertificate",
                      name: "dobAsPerCertificate",
                    }}
                    padding={"17.8px 14px"}
                  />
                </Box>
              </Grid>
              <Grid xl={4} lg={4} md={4} sm={4} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Blood Group *</label>
                  <FormControl sx={{ minWidth: 190 }} size="small">
                    <Select
                      labelId="blood-group"
                      id="bloodGroup"
                      value={formOne.values.bloodGroup}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          "bloodGroup",
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne.touched.bloodGroup &&
                        Boolean(formOne.errors.bloodGroup)
                      }
                      disabled={disableAllFields}
                    >
                      {bloodTypes.map((item) => (
                        <MenuItem value={item}>
                          {item} <BloodGroup />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.bloodGroup && formOne.errors.bloodGroup}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              container
              gridRow={{ xl: 2, lg: 2, md: 2, sm: 2 }}
              columnSpacing={4}
              rowSpacing={3}
            >
              <Grid xl={6} lg={6} md={6} sm={6} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>PAN Number *</label>
                  <TextField
                    id="pan-Number"
                    name="panNumber"
                    size="small"
                    helperText={
                      formOne.touched.panNumber && formOne.errors.panNumber
                    }
                    error={
                      formOne.touched.panNumber &&
                      Boolean(formOne.errors.panNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.panNumber}
                    // sx={{ width: "345px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
              <Grid xl={6} lg={6} md={6} sm={6} item>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  className="generalLabel"
                >
                  <label className={classes.label}>Aadhaar Number *</label>
                  <TextField
                    id="aadhar-Number"
                    name="aadharNumber"
                    size="small"
                    helperText={
                      formOne.touched.aadharNumber &&
                      formOne.errors.aadharNumber
                    }
                    error={
                      formOne.touched.aadharNumber &&
                      Boolean(formOne.errors.aadharNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.aadharNumber}
                    // sx={{ width: "345px" }}
                    disabled={disableAllFields}
                  />
                </Box>
              </Grid>
            </Grid>
            {/* <Grid xs={12}>
              <Box
                mt={1}
                display={"flex"}
                flexDirection={"row"}
                gap={2}
                justifyContent={"center"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                >
                  <label
                    style={{ marginRight: "15px", marginTop: "8px" }}
                    className={classes.label}
                  >
                    Created By:
                  </label>
                  <FormControl sx={{ width: "170px" }} size="small">
                    <Select
                      id={"createdBy"}
                      value={formOne.values.createdBy}
                      onChange={(e) => {
                        formOne.setFieldValue(
                          `createdBy`,
                          e.target.value,
                          true
                        );
                      }}
                      error={
                        formOne.touched.createdBy &&
                        Boolean(formOne.errors.createdBy)
                      }
                    >
                      {hrsList.map((item) => (
                        <MenuItem value={item.value}>{item.title}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.createdBy && formOne.errors.createdBy}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>
            </Grid> */}
          </Grid>

          {/* <Box className={classes.createEmployeeBtn}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
            >
              Create Employee
            </Button>
          </Box> */}
        </form>
      </Box>
    </>
  );
};

export default PersonalForm;
