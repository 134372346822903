import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  TablePagination,
  TextField,
  Tooltip,
} from "@mui/material";
import PopUpForm from "./popUpForm";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TsAccordion from "./TsAccordion";
import "./employeeList.css";
import LeadModal from "./ModalViews/Lead/leadModal";
import FinanceModal from "./ModalViews/Finance/financeModal";
import NcgModal from "./ModalViews/Ncg/ncgModal";
import HrAdminModal from "./ModalViews/HrAdmin/hrAdminModal";
import {
  addCompOffGroup,
  addLeaveGroup,
  createEmployeeBtnGroup,
  leaveTypeEnums,
} from "./uiElementsAccess";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Popover from "@mui/material/Popover";
import { FileUploader } from "react-drag-drop-files";
import { bulkDownload, bulkUpload } from "../services/DocumentService";
import { pushNotification } from "../redux/actions";
import CONSTANTS, { BULK_UPLOAD_INITIAL_MESSAGE } from "../constants/constants";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { searchEmployeesList } from "../services/lmsService";
import ViewEmployeeModal from "./ModalViews/ViewEmployee/ViewEmployeeModal";
import TuneIcon from "@mui/icons-material/Tune";
import AddLeaveModal from "./ModalViews/AddLeave/addLeaveModal";
import Lottie from "lottie-react";
import NoData from "../assets/animations/NoData.json";

const styles = {
  pagination: {
    backgroundColor: "#ffff",
    height: "50px",
    borderRadius: "10px",
  },
};

const EmployeesList = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const [isLead, setIsLead] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [isNcg, setIsNcg] = useState(false);
  const [isHrAdmin, setIsHrAdmin] = useState(false);
  const fileTypes = ["CSV"];

  const [employeesData, setEmployeesData] = useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingBulk, setIsLoadingBulk] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const [showView, setShowView] = useState(false);

  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any>([]);
  const [isAddLeaveModalOpen, setIsAddLeaveModalOpen] = useState(false);
  const [addLeaveType, setAddLeaveType] = useState(null);

  let userRole: any = sessionStorage.getItem("User-Role");

  // Code for filters menu
  const [anchorElFilterMenu, setAnchorElFilterMenu] = React.useState(null);
  const openFilterMenu = Boolean(anchorElFilterMenu);
  const handleClickFilterMenu = (event) => {
    setAnchorElFilterMenu(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setAnchorElFilterMenu(null);
  };

  //end

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setSelectedEmployeeList([]);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [searchValue, setSearchValue] = React.useState<any>("");
  const [status, setStatus] = React.useState<any>("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearchData = async (searchValue, status, page, rowsPerPage) => {
    setIsLoading(true);
    const response: any = await searchEmployeesList(
      searchValue,
      status,
      page,
      rowsPerPage
    );
    if (response?.success) {
      setTotalRecords(response?.data?.totalElements);
      setEmployeesData(
        response?.data?.content?.sort((a, b) => {
          // First, compare by status
          if (a.status === "Active" && b.status === "Inactive") {
            return -1;
          }
          if (a.status === "Inactive" && b.status === "Active") {
            return 1;
          }

          // If statuses are the same, compare by id in descending order
          if (a.empId > b.empId) {
            return -1;
          }
          if (a.empId < b.empId) {
            return 1;
          }

          // If both status and id are the same, return 0 (they are equal)
          return 0;
        })
      );

      setIsLoading(false);
    } else {
      setEmployeesData([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearchData(searchValue, status, page, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, refresh]);

  // const handleFileChange = async (file: any) => {
  //   setAnchorEle(null);
  //   setIsLoadingBulk(true);
  //   const response: any = await bulkUpload(file);
  //   if (response.data?.success) {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: response.data?.message,
  //         type: CONSTANTS.SUCCESS,
  //       })
  //     );
  //     setIsLoadingBulk(false);
  //   } else {
  //     dispatch(
  //       pushNotification({
  //         isOpen: true,
  //         message: response.data?.message,
  //         type: CONSTANTS.ERROR,
  //       })
  //     );
  //     setIsLoadingBulk(false);
  //   }
  // };

  const handleFileChange = async (file: any) => {
    setAnchorEle(null);

    setIsLoadingBulk(true);
    dispatch(
      pushNotification({
        isOpen: true,

        message: BULK_UPLOAD_INITIAL_MESSAGE,

        type: CONSTANTS.WARNING,
      })
    );

    const response: any = await bulkUpload(file);

    if (response) {
      // const url = window.URL.createObjectURL(new Blob([response.data]));

      // const link = document.createElement("a");

      // link.href = url;

      // link.setAttribute("download", "failedRecords.xlsx");

      // document.body.appendChild(link);

      // link.click(); // dispatch( //   pushNotification({ //     isOpen: true, //     message: response.data?.message, //     type: CONSTANTS.ERROR, //   }) // );

      setIsLoadingBulk(false);
    }
  };

  const handleBulkDownload = async () => {
    setIsLoadingBulk(true);
    const response: any = await bulkDownload();

    if (response?.success) {
      setIsLoadingBulk(false);
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.message,

          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      setIsLoadingBulk(false);
      dispatch(
        pushNotification({
          isOpen: true,

          message: response?.message,

          type: CONSTANTS.ERROR,
        })
      );
    }
  };

  const [anchorEle, setAnchorEle] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEle(event.currentTarget);
  };
  const handleCloseButton = () => {
    setAnchorEle(null);
  };
  const openButton = Boolean(anchorEle);
  const id = openButton ? "simple-popover" : undefined;

  // code of deactivation modal

  const handleStatusChange = (status) => {
    if (status === "Active") {
      setSearchValue("");
      setStatus("Active");
      handleCloseFilterMenu();
      handleSearchData("", "Active", 0, 10);
    } else if (status === "Inactive") {
      setSearchValue("");
      setStatus("Inactive");
      handleCloseFilterMenu();
      handleSearchData("", "Inactive", 0, 10);
    } else {
      setSearchValue("");
      setStatus("");
      handleCloseFilterMenu();
      handleSearchData("", "", 0, 10);
    }
  };

  const handleEmployeeSelect = (empId) => {
    if (selectedEmployeeList.includes(empId)) {
      const selectedList = [...selectedEmployeeList];
      const index = selectedList.indexOf(empId);
      selectedList.splice(index, 1);
      setSelectedEmployeeList([...selectedList]);
    } else {
      setSelectedEmployeeList([...selectedEmployeeList, empId]);
    }
  };

  const handleSelectAll = (event) => {
    const isSelectAll = event.target.checked;
    if (isSelectAll) {
      const selectedList = [...selectedEmployeeList];
      employeesData.forEach((empData) => {
        if (
          !selectedList.includes(empData.empId) &&
          empData.status !== "Inactive"
        ) {
          selectedList.push(empData.empId);
        }
      });
      setSelectedEmployeeList([...selectedList]);
    } else {
      setSelectedEmployeeList([]);
    }
  };

  const isAllSelected = () => {
    const activeList = employeesData?.filter(
      (empData) => empData.status !== "Inactive"
    );

    return (
      activeList?.length && selectedEmployeeList.length === activeList?.length
    );
  };

  const openAddLeaveModal = (leaveType) => {
    setAddLeaveType(leaveType);
    setIsAddLeaveModalOpen(true);
  };

  const closeAddLeaveModal = () => {
    setAddLeaveType(null);
    setIsAddLeaveModalOpen(false);
    setSelectedEmployeeList([]);
    handleSearchData(searchValue, status, page, rowsPerPage);
  };

  return (
    <div className="wrapper">
      <ViewEmployeeModal
        show={showView}
        setShow={() => {
          setShowView(false);
        }}
      />

      <Menu
        id="filtering-menu"
        anchorEl={anchorElFilterMenu}
        open={openFilterMenu}
        onClose={handleCloseFilterMenu}
        MenuListProps={{
          "aria-labelledby": "filtering-button",
        }}
      >
        <MenuItem
          id="filter-all"
          sx={{
            backgroundColor: status === "" ? "#F15830" : "",
          }}
          onClick={() => {
            handleStatusChange("All");
          }}
        >
          All
        </MenuItem>
        <MenuItem
          id="filter-Active"
          sx={{
            backgroundColor: status === "Active" ? "#F15830" : "",
          }}
          onClick={() => {
            handleStatusChange("Active");
          }}
        >
          Active
        </MenuItem>
        <MenuItem
          id="filter-Inactive"
          sx={{
            backgroundColor: status === "Inactive" ? "#F15830" : "",
          }}
          onClick={() => {
            handleStatusChange("Inactive");
          }}
        >
          Inactive
        </MenuItem>
      </Menu>

      <Popover
        id={id}
        open={openButton}
        anchorEl={anchorEle}
        onClose={handleCloseButton}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={400}
          height={150}
        >
          <FileUploader
            multiple={false}
            handleChange={handleFileChange}
            name="file"
            types={fileTypes}
          />
        </Box>
      </Popover>
      <div className={"heading-container"}>
        <div className="allEmployeesHeading">
          <Checkbox
            size="small"
            checked={isAllSelected()}
            onClick={handleSelectAll}
          />
          <span>EMPLOYEE DATA&nbsp;</span>
          {createEmployeeBtnGroup.includes(userRole) ? (
            <Button
              style={{ minWidth: "0", padding: "0", marginLeft: "10px" }}
              aria-describedby={id}
              onClick={handleClickButton}
            >
              <Tooltip title={"Master Data Upload  (CSV File Only)"}>
                {/* <img src={uploadCsvImg} style={{ height: "33px" }} /> */}
                <CloudUploadOutlinedIcon sx={{ color: "#f15830" }} />
              </Tooltip>
            </Button>
          ) : null}
          {createEmployeeBtnGroup.includes(userRole) ? (
            <Button
              style={{ minWidth: "0", marginLeft: "13px" }}
              onClick={handleBulkDownload}
            >
              <Tooltip title={"Master Data Download"}>
                {/* <img src={downloadCsvImg} style={{ height: "33px" }} /> */}
                <CloudDownloadOutlinedIcon sx={{ color: "#f15830" }} />
              </Tooltip>
            </Button>
          ) : null}
          {isLoadingBulk ? (
            <CircularProgress size={20} sx={{ color: "red" }} />
          ) : null}
        </div>

        {isLead ? <LeadModal show={isLead} setShow={setIsLead} /> : null}
        {isFinance ? (
          <FinanceModal show={isFinance} setShow={setIsFinance} />
        ) : null}
        {/* {isAdmin ? <AdminModal show={isAdmin} setShow={setIsAdmin} /> : null} */}
        {isNcg ? <NcgModal show={isNcg} setShow={setIsNcg} /> : null}
        {isHrAdmin ? (
          <HrAdminModal show={isHrAdmin} setShow={setIsHrAdmin} />
        ) : null}

        {isAddLeaveModalOpen ? (
          <AddLeaveModal
            isModalOpen={isAddLeaveModalOpen}
            closeModal={closeAddLeaveModal}
            selectedEmpIdList={selectedEmployeeList}
            leaveType={addLeaveType}
          />
        ) : null}

        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          justifyContent={"flex-end"}
          alignItems={"center"}
          flexWrap={"wrap"}
          // width={"100%"}
        >
          {/* {addCompOffGroup.includes(userRole) ? (
            <Button
              variant="outlined"
              className={"add-action-button"}
              onClick={() => {
                openAddLeaveModal(leaveTypeEnums.WFH);
              }}
              disabled={!selectedEmployeeList?.length}
            >
              Credit Time
            </Button>
          ) : null} */}
          {addCompOffGroup.includes(userRole) ? (
            <Button
              variant="outlined"
              className={"add-action-button"}
              onClick={() => {
                openAddLeaveModal(leaveTypeEnums.COMP_OFF);
              }}
              disabled={!selectedEmployeeList?.length}
            >
              Add Comp-Off
            </Button>
          ) : null}

          {addLeaveGroup.includes(userRole) ? (
            <Button
              variant="outlined"
              className={"add-action-button"}
              onClick={() => {
                openAddLeaveModal(leaveTypeEnums.GENERAL);
              }}
              disabled={!selectedEmployeeList?.length}
            >
              Add Leave
            </Button>
          ) : null}

          <div className="addEmployeeButton">
            {createEmployeeBtnGroup.includes(userRole) ? (
              <PopUpForm show={show} setShow={setShow}></PopUpForm>
            ) : null}
          </div>

          <TextField
            type={"search"}
            id="outlined-size-normal"
            value={searchValue}
            onChange={handleSearch}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                setPage(0);
                setRowsPerPage(10);
                setTotalRecords(0);
                handleSearchData(searchValue, status, 0, 10);
              }
            }}
            // placeholder="Search"
            sx={{ minWidth: "100px" }}
            size={"small"}
            InputProps={{
              style: {
                borderRadius: "10px",
                backgroundColor: "#ffff",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={"Click to Search"}>
                    <SearchOutlinedIcon
                      onClick={() => {
                        setPage(0);
                        setRowsPerPage(10);
                        setTotalRecords(0);
                        handleSearchData(searchValue, status, 0, 10);
                      }}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Box>
            <Tooltip title="Filter Employees">
              <IconButton onClick={handleClickFilterMenu}>
                <TuneIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </div>

      <div className="employee-list-container">
        {isLoading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={5}
            flexDirection={"column"}
            gap={6}
          >
            <CircularProgress size={100} sx={{ color: "red" }} />
            <Box sx={{ color: "#162952" }}>Please wait we are fetching...</Box>
          </Box>
        ) : (
          <Box>
            {employeesData?.length ? (
              <TsAccordion
                setSerachValue={setSearchValue}
                searchValue={searchValue}
                show={show}
                setShow={setShow}
                isLead={isLead}
                setIsLead={setIsLead}
                isAdmin={isAdmin}
                setIsAdmin={setIsAdmin}
                isFinance={isFinance}
                setIsFinance={setIsFinance}
                isNcg={isNcg}
                setIsNcg={setIsNcg}
                isHrAdmin={isHrAdmin}
                setIsHrAdmin={setIsHrAdmin}
                allEmployeesData={employeesData}
                showView={showView}
                setShowView={setShowView}
                setRefresh={setRefresh}
                selectedEmployeeList={selectedEmployeeList}
                selectEmployee={handleEmployeeSelect}
              />
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ backgroundColor: "#ffff" }}
              >
                <Lottie animationData={NoData} loop={true} />
              </Box>
            )}
          </Box>
        )}
      </div>

      <Box mt={1} mb={3} sx={styles.pagination}>
        <TablePagination
          id="employee-pagination"
          component="div"
          count={totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </Box>
    </div>
  );
};

export default EmployeesList;
