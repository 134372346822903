import React, { useEffect } from "react";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CONSTANTS from "../constants/constants";
import { JOB_DETAILS_FORM_ID } from "../constants/formsConstants";
import { store } from "../redux/store";
import {
  FilterFormDetailsWithOfficalEmail,
  postForm,
} from "../services/formService";
import { useDispatch } from "react-redux";
import {
  locationMsg,
  prevOrganizationMsg,
  roleMsg,
  totalNoOfYearsMsg,
} from "./forms/formConstants";
import { pushNotification } from "../redux/actions";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "red",
  },
  label: {
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  submitBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "40px",
  },
}));

const styles = {
  previousExperience: {
    heading: {
      "@media (min-width: 2000px)": {
        fontSize: "calc(12px + 1.4rem)",
      },
      "@media (min-width: 1500px) and (max-width: 1999px)": {
        fontSize: "calc(12px + 0.8rem)",
      },
      "@media (min-width: 1000px) and (max-width: 1499px)": {
        fontSize: "calc(12px + 0.8rem)",
      },
      "@media (min-width: 600px) and (max-width: 999px)": {
        fontSize: "calc(12px + 0.4rem)",
      },
      "@media (max-width: 600px)": {
        fontSize: "calc(12px + 0.2rem)",
      },
    },
    orgranization: { width: "160px" },
    role: { width: "180px" },
    location: { width: "160px" },
    totalNumberOfYearsMonths: { width: "200px" },
  },
  buttons: {
    handleServiceRemoveButton: { color: "#959595", marginTop: "19px" },
    submitButton: {
      backgroundColor: "#f15830",
      color: "white",
    },
    handleServiceButton: {
      marginTop: 15,
      height: "30px",
      background: "#808080",
    },
  },
  spans: {
    addExperience: { color: "white", fontWeight: "bold" },
  },
};

const Jobdetails = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const [id, setId] = React.useState<any>();

  const classes = useStyles();

  let emailId: any = sessionStorage.getItem("user-email");
  const reduxState = store.getState();
  const dispatch = useDispatch();

  // const formChild = useFormik({
  //   initialValues: { members: childArray },
  //   validationSchema: Yup.object().shape({
  //     members: Yup.array().of(
  //       Yup.object({
  //         prevOrganization: Yup.string().required("Please Enter Data"),
  //         role: Yup.string().required("Please Enter Data"),
  //         location: Yup.string().required("Please Enter Data"),
  //         totalNoOfYearAndMonths: Yup.string().required("Please Enter Data"),
  //       })
  //     ),
  //   }),
  //   onSubmit: (values, { setSubmitting }) => {
  //     handleSubmitFormChild(values, setSubmitting);
  //   },
  //   enableReinitialize: true,
  // });
  const initialValuesForForm = {
    prevOrganization: "",
    role: "",
    location: "",
    totalNumberOfYearsMonths: "",
    // totalNumberOfMonths: "",
  };

  const [childArray] = React.useState<any[]>([]);
  const formOne = useFormik({
    initialValues: {
      previousExperience: childArray,
    },
    validationSchema: Yup.object({
      previousExperience: Yup.array().of(
        Yup.object({
          prevOrganization: Yup.string().required(prevOrganizationMsg),
          role: Yup.string().required(roleMsg),
          location: Yup.string().required(locationMsg),
          totalNumberOfYearsMonths: Yup.string().required(totalNoOfYearsMsg),
          // totalNumberOfMonths: Yup.string().required(totalNoOfMonthsMsg),
        })
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },

    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);
    postFormValues(values);
  };

  // const [serviceList, setServiceList] = React.useState(
  //   formOne.values.previousExperience
  // );

  const getError = (name: string) => {
    const error = getIn(formOne.errors, name);
    const touch = getIn(formOne.touched, name);
    return touch && error ? error : null;
  };
  const handleService = () => {
    let value = [
      ...formOne.values.previousExperience,
      { ...initialValuesForForm },
    ];
    formOne.setFieldValue("previousExperience", value, true);
  };

  const handleServiceRemove = (index) => {
    let value = formOne.values.previousExperience;
    value.splice(index, 1);
    formOne.setFieldValue("previousExperience", value, true);
  };
  // const handlePreviousTab = () => {
  //   setValue((prevState) => prevState - 1);
  // };

  const formPayload = (values: any) => ({
    formId: JOB_DETAILS_FORM_ID,
    formData: {
      reportingTo: reduxState.reportingToEmailId,
      officialEmail: sessionStorage.getItem("user-email"),
      previousExperience: values.previousExperience,
    },
  });

  const postFormValues = async (values: any) => {
    const postData = formPayload(values);
    if (id) {
      let payload = {
        formId: postData.formId,
        id: id,
        formData: postData.formData,
      };
      const response: any = await postForm(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      const response = await postForm(postData);

      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailId]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      JOB_DETAILS_FORM_ID,
      emailId
    );
    if (response.success && response?.data?.content?.length > 0) {
      setId(response?.data?.content[0]?.id);
      let mapData: any = response?.data?.content[0]?.formData;

      let finalData = {
        previousExperience: mapData?.hasOwnProperty("previousExperience")
          ? mapData?.previousExperience
          : [],
      };

      formOne.setValues(finalData);

      // setValue((prevState) => prevState + 1);
    } else {
    }
  };
  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={3}>
            <Typography
              color={"#f15830"}
              width="100%"
              sx={styles.previousExperience.heading}
            >
              Previous Experience <Divider />
            </Typography>

            {formOne?.values?.previousExperience?.map(
              (singleService, index) => (
                <div
                  style={
                    {
                      // display: "flex",
                      // flexDirection: "row",
                      // gap: 20,
                      // marginTop: 15,
                    }
                  }
                  key={index}
                >
                  <Grid xs={12}>
                    <Box display={"flex"} flexDirection={"row"} gap={4}>
                      <Box display={"flex"} flexDirection={"column"}>
                        <label className={classes.label}>
                          Prev Organization
                        </label>
                        <TextField
                          id={`previousExperience[${index}].prevOrganization`}
                          size="small"
                          name={`previousExperience[${index}].prevOrganization`}
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={
                            formOne.values.previousExperience[index]
                              .prevOrganization
                          }
                          error={getError(
                            `previousExperience[${index}].prevOrganization`
                          )}
                          helperText={getError(
                            `previousExperience[${index}].prevOrganization`
                          )}
                          sx={styles.previousExperience.orgranization}
                        />
                      </Box>
                      <Box display={"flex"} flexDirection={"column"}>
                        <label className={classes.label}>Role</label>
                        <TextField
                          id={`previousExperience[${index}].role`}
                          size="small"
                          name={`previousExperience[${index}].role`}
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={formOne.values.previousExperience[index].role}
                          error={getError(`previousExperience[${index}].role`)}
                          helperText={getError(
                            `previousExperience[${index}].role`
                          )}
                          sx={styles.previousExperience.role}
                        />
                      </Box>
                      <Box display={"flex"} flexDirection={"column"}>
                        <label className={classes.label}>Location</label>
                        <TextField
                          id={`previousExperience[${index}].location`}
                          size="small"
                          name={`previousExperience[${index}].location`}
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={
                            formOne.values.previousExperience[index].location
                          }
                          error={getError(
                            `previousExperience[${index}].location`
                          )}
                          helperText={getError(
                            `previousExperience[${index}].location`
                          )}
                          sx={styles.previousExperience.location}
                        />
                      </Box>
                      <Box display={"flex"} flexDirection={"column"}>
                        <label className={classes.label}>Tenure</label>
                        <TextField
                          id={`previousExperience[${index}].totalNumberOfYearsMonths`}
                          size="small"
                          name={`previousExperience[${index}].totalNumberOfYearsMonths`}
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={
                            formOne.values.previousExperience[index]
                              .totalNumberOfYearsMonths
                          }
                          error={getError(
                            `previousExperience[${index}].totalNumberOfYearsMonths`
                          )}
                          helperText={getError(
                            `previousExperience[${index}].totalNumberOfYearsMonths`
                          )}
                          sx={
                            styles.previousExperience.totalNumberOfYearsMonths
                          }
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        gap={2}
                        mt={2}
                      >
                        <Box display={"flex"} flexDirection={"column"}>
                          <Button onClick={() => handleServiceRemove(index)}>
                            <DeleteOutlineOutlinedIcon
                              sx={styles.buttons.handleServiceRemoveButton}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  {/* <Grid xs={12} mt={1}></Grid> */}
                </div>
              )
            )}

            <button
              type="button"
              onClick={handleService}
              style={styles.buttons.handleServiceButton}
            >
              <span style={styles.spans.addExperience}>+ Add Experience</span>
            </button>
          </Grid>

          <Box className={classes.submitBtn}>
            <Button
              variant="contained"
              sx={styles.buttons.submitButton}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Jobdetails;
