import { HostDetails } from "../types/ReceptionTypes";

export function splitPhoneByCountryCode(country: String, phoneNumber: String) {
  switch (country) {
    case "in":
      return "+91-" + phoneNumber?.slice(2);
    default:
      return "No matching country found";
  }
}

export const convertStatus = (status: string): string => {
  return status.toUpperCase().replace(/ /g, "_");
};

export const createHostDetailsMap = (
  emails: string[],
  names: string[],
  mobiles: string[]
): { [email: string]: HostDetails } => {
  return emails.reduce((map, email, index) => {
    map[email] = {
      name: names[index],
      mobile: mobiles[index],
    };
    return map;
  }, {} as { [email: string]: HostDetails });
};

export const sanitizeMobileNumber = (mobileNumber: string) => {
  // Use a regular expression to replace any non-digit characters with an empty string
  return mobileNumber.replace(/\D/g, "");
};
