import React, { useEffect, useState } from "react";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CONSTANTS, {
  EMAIL_REGEX,
  insuranceDashboardUrl,
  PASSPORT_REGEX,
  PERSONAL_FORMID,
  phoneNumberRegex,
} from "../../../../constants/constants";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch } from "react-redux";
import { pushNotification } from "../../../../redux/actions";
import { store } from "../../../../redux/store";
import {
  FilterFormDetailsWithOfficalEmail,
  postFormGeneral,
} from "../../../../services/formService";
import {
  childFullNameMsg,
  contactName1Msg,
  contactName1Msg1,
  contactName2Msg1,
  currentAddressMsg,
  currentAddressMsg1,
  dobAsPerCelebrationMsg,
  dobAsPerCelebrationMsg1,
  dobAsPerCelebrationMsg2,
  emergencyPhone1Msg1,
  fatherNameMsg,
  fatherNameMsg1,
  marriedStatusMsg,
  motherNameMsg,
  motherNameMsg1,
  nationalityMsg,
  nationalityMsg1,
  officialEmailMsg1,
  passportValidationMsg,
  permanentAddressMsg,
  permanentAddressMsg1,
  personalEmailMsg,
  personalEmailMsg1,
  physicallyChallengedMsg,
  relationship1Msg,
  relationship1Msg1,
  relationship2Msg1,
} from "./formConstants";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../../forms/CustomDatePicker";

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: "#f15830",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: "15px",
  },
  addBtn: {
    marginTop: "15px",
  },
  limitWidth: {
    width: 402,
  },
  datePickerStyles: {
    color: "inherit",
    height: "36px",
    border: "1px solid grey !important",
    borderRadius: "5px",
    "&::placeholder": {
      textAlign: "center",
    },
    textAlign: "center",
    fontSize: "inherit",
  },
  phoneInputStyles: {
    width: "100% !important",

    "@media (min-width: 320px )": {
      width: "100% !important",
    },
    "@media (min-width: 375px)": {
      width: "85% !important",
    },
    "@media (min-width: 425px)": {
      width: "85% !important",
    },
    "@media (min-width: 600px)": {
      width: "100% !important",
    },
    "@media (min-width: 768px)": {
      width: "100% !important",
    },
    "@media (min-width: 1440px)": {
      width: "100% !important",
    },
    "@media (min-width: 2560px)": {
      width: "100% !important",
    },
  },
}));

const PersonalDetailsForm = (props: any) => {
  const { setOpenFormExitPopup } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const reduxState = store.getState();
  const [addresschecked, setAddressChecked] = React.useState(false);
  const [formId, setFormId] = React.useState("");
  const [updatedFields, setUpdatedFields] = useState({});

  const initialValuesForForm = {
    childFullName: "",
    childDob: "01-01-2004",
    childGender: "",
  };
  const [childArray]: any[] = React.useState([]);

  const handleServiceRemove = (index) => {
    let value = formOne.values.childrenData;
    value.splice(index, 1);
    formOne.setFieldValue("childrenData", value, true);
  };

  // const getError = (name: string) => {
  //   const error = getIn(formChild.errors, name);
  //   const touch = getIn(formChild.touched, name);
  //   return touch && error ? error : null;
  // };
  //emergencyPhone1
  const [countryCode, setCountryCode] = useState("");
  // const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [countryRegex, setCountryRegex] = useState(
    phoneNumberRegex[countryCode]
  );
  const handlePhoneChange = async (value, country) => {
    if (country && "dialCode" in country) {
      // const countryCode = `+${country.dialCode}`;
      // const phoneNumber = value.slice(countryCode.length);
      // const formattedNumber = `${countryCode} ${phoneNumber}`;
      // setFormattedPhoneNumber(formattedNumber);
    }
    setCountryCode(country.countryCode);
    setCountryRegex(phoneNumberRegex[country.countryCode]);
  };
  console.log(countryCode, "hrCountrycode");
  console.log(countryRegex, "hrCountryregex");

  //emergencyPhone2
  const [countryCode1, setCountryCode1] = useState("");
  // const [formattedPhoneNumber1, setFormattedPhoneNumber1] = useState("");
  const [countryRegex1, setCountryRegex1] = useState(
    phoneNumberRegex[countryCode1]
  );
  const handlePhoneChange1 = async (value, country) => {
    if (country && "dialCode" in country) {
      // const countryCode1 = `+${country.dialCode}`;
      // const phoneNumber1 = value.slice(countryCode1.length);
      // const formattedNumber1 = `${countryCode1} ${phoneNumber1}`;
      // setFormattedPhoneNumber1(formattedNumber1);
    }
    setCountryCode1(country.countryCode);
    setCountryRegex1(phoneNumberRegex[country.countryCode]);
  };

  const formOne = useFormik({
    initialValues: {
      dobAsPerCelebration: "",
      personalEmail: "",
      nationality: "",
      physicallyChallenged: false,
      passportNumber: "",
      passportExpiry: "",
      currentAddress: "",
      permanentAddress: "",
      contactName1: "",
      relationship1: "",
      emergencyPhone1: "",
      contactName2: "",
      relationship2: "",
      emergencyPhone2: "",
      fatherName: "",
      motherName: "",
      marriedStatus: "",
      spouseFirstName: "",
      spouseLastName: "",
      spouseDob: "",

      childrenData: childArray,
    },
    validationSchema: Yup.object({
      dobAsPerCelebration: Yup.string()
        .test(
          dobAsPerCelebrationMsg1,
          dobAsPerCelebrationMsg,
          (date) => moment().diff(moment(date), dobAsPerCelebrationMsg2) >= 18
        )
        .required(dobAsPerCelebrationMsg),

      personalEmail: Yup.string()
        .required(personalEmailMsg)
        .min(3, personalEmailMsg1)
        .matches(EMAIL_REGEX, officialEmailMsg1),
      nationality: Yup.string()
        .required(nationalityMsg)
        .min(3, nationalityMsg1),
      physicallyChallenged: Yup.boolean().required(physicallyChallengedMsg),

      currentAddress: Yup.string()
        .required(currentAddressMsg)
        .max(300, currentAddressMsg1),
      permanentAddress: Yup.string()
        .required(permanentAddressMsg)
        .max(300, permanentAddressMsg1),
      passportNumber: Yup.string().matches(
        PASSPORT_REGEX,
        passportValidationMsg
      ),
      passportExpiry: Yup.string(),
      contactName1: Yup.string()
        .required(contactName1Msg)
        .min(3, contactName1Msg1),
      relationship1: Yup.string()
        .required(relationship1Msg)
        .min(3, relationship1Msg1),
      emergencyPhone1: Yup.string().matches(countryRegex, emergencyPhone1Msg1),
      contactName2: Yup.string().min(3, contactName2Msg1),
      relationship2: Yup.string().min(3, relationship2Msg1),
      emergencyPhone2: Yup.string().matches(countryRegex1, emergencyPhone1Msg1),
      fatherName: Yup.string().required(fatherNameMsg).min(3, fatherNameMsg1),
      motherName: Yup.string().required(motherNameMsg).min(3, motherNameMsg1),
      marriedStatus: Yup.string().required(marriedStatusMsg),
      spouseFirstName: Yup.string(),
      // .min(3, "Minimum 3 Characters").required("Please Enter Data"),
      spouseLastName: Yup.string(),
      // .min(3, "Minimum 3 Characters").required("Please Enter Data"),
      spouseDob: Yup.string(),
      // .required("Please Enter Data")
      // .test(
      //   "DOB",
      //   "Please choose a valid date of birth",
      //   (date) => moment().diff(moment(date), "years") >= 18
      // ),

      childrenData: Yup.array().of(
        Yup.object({
          childFullName: Yup.string().min(3, childFullNameMsg),
          childDob: Yup.string(),
          childGender: Yup.string(),
        })
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      try {
        if (values?.emergencyPhone1?.length === 10)
          values.emergencyPhone1 = defaultCountryCode + values?.emergencyPhone1;
        if (values?.emergencyPhone2.length === 10)
          values.emergencyPhone2 = defaultCountryCode + values?.emergencyPhone2;
      } catch (e) {}
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleAddressCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressChecked(event.target.checked);
    if (event.target.checked === true) {
      formOne.setFieldValue(
        "permanentAddress",
        formOne.values.currentAddress,
        true
      );
    } else {
      formOne.setFieldValue("permanentAddress", "", true);
    }
  };

  const handleService = () => {
    let value = [...formOne.values.childrenData, { ...initialValuesForForm }];
    formOne.setFieldValue("childrenData", value, true);
  };

  const getError = (name: string) => {
    const error = getIn(formOne.errors.childrenData, name);
    const touch = getIn(formOne.touched.childrenData, name);
    return touch && error ? error : null;
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);

    postFormValues(values);
  };

  const formPayload = (values: any) => ({
    formId: PERSONAL_FORMID,
    formData: {
      passportNumber: values.passportNumber,
      passportExpiry: values?.passportExpiry,
      officialEmail: reduxState.officialEmailId,
      reportingTo: reduxState.reportingToEmailId,
      personalEmail: values.personalEmail,
      currentAddress: values.currentAddress,
      nationality: values.nationality,
      general: {
        dobAsPerCelebration: values.dobAsPerCelebration,

        physicallyChallenged: values.physicallyChallenged,

        permanentAddress: values.permanentAddress,

        sameAsCurrentAddress: addresschecked,
      },

      emergencyDetails: {
        emergencyDetails1: {
          contactName: values.contactName1,

          relationship: values.relationship1,

          phone: values.emergencyPhone1,
        },

        emergencyDetails2: {
          contactName: values.contactName2,

          relationship: values.relationship2,

          phone: values.emergencyPhone2,
        },
      },

      family: {
        fatherNameAsPerAadhaar: values.fatherName,

        motherNameAsPerAadhaar: values.motherName,

        marriedStatus: values.marriedStatus,

        spouseFirstNameAsPerAadhaar: values.spouseFirstName,

        spouseLastNameAsPerAadhaar: values.spouseLastName,

        dob: values.spouseDob,
      },
    },
    updatedFields: {
      ...updatedFields,
    },
  });

  useEffect(() => {
    prefillData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState.officialEmailId]);

  useEffect(() => {
    const caller = async () => {
      await prefillData();
      // setFormInitialData(formOne.values);
      setOpenFormExitPopup(false);
    };
    caller();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpenFormExitPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOne.values]);

  const prefillData = async () => {
    const response = await FilterFormDetailsWithOfficalEmail(
      PERSONAL_FORMID,
      reduxState.officialEmailId
    );
    if (response.success && response?.data?.content?.length > 0) {
      let mapData: any = response?.data?.content[0]?.formData;
      setFormId(response?.data?.content[0]?.id);

      let finalData: any = {
        dobAsPerCelebration: mapData?.general?.dobAsPerCelebration,
        personalEmail: mapData?.personalEmail,
        nationality: mapData?.nationality,
        physicallyChallenged: mapData?.general?.physicallyChallenged,
        passportNumber: mapData?.passportNumber,
        passportExpiry: mapData?.passportExpiry,
        currentAddress: mapData?.currentAddress,
        permanentAddress: mapData?.general?.permanentAddress,
        contactName1: mapData?.emergencyDetails?.emergencyDetails1?.contactName,
        relationship1:
          mapData?.emergencyDetails?.emergencyDetails1?.relationship,
        emergencyPhone1: mapData?.emergencyDetails?.emergencyDetails1?.phone,
        contactName2: mapData?.emergencyDetails?.emergencyDetails2?.contactName,
        relationship2:
          mapData?.emergencyDetails?.emergencyDetails2?.relationship,
        emergencyPhone2: mapData?.emergencyDetails?.emergencyDetails2?.phone,
        fatherName: mapData?.family?.fatherNameAsPerAadhaar,
        motherName: mapData?.family?.motherNameAsPerAadhaar,
        marriedStatus: mapData?.family?.marriedStatus,
        spouseFirstName: mapData?.family?.spouseFirstNameAsPerAadhaar,
        spouseLastName: mapData?.family?.spouseLastNameAsPerAadhaar,
        spouseDob: mapData?.family?.dob,
        childrenData: mapData?.family?.child,
      };

      formOne.setValues(finalData);
      setAddressChecked(mapData?.general?.sameAsCurrentAddress);
    } else {
    }
  };

  const postFormValues = async (values: any) => {
    const postData = formPayload(values);

    if (formId) {
      let payload = {
        formId: postData.formId,
        id: formId,
        formData: postData.formData,
        updatedFields: postData.updatedFields,
      };
      const response: any = await postFormGeneral(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } else {
      let payload = {
        formId: postData.formId,
        formData: postData.formData,
        updatedFields: {
          dobAsPerCelebration: formOne.values.dobAsPerCelebration,
        },
      };
      const response = await postFormGeneral(payload);
      if (response.success) {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.SUCCESS,
          })
        );
        setOpenFormExitPopup(false);
      } else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: response.message,
            type: CONSTANTS.ERROR,
          })
        );
      }
    }
  };

  const handleUpdatedFields = (key, value) => {
    const fields = { ...updatedFields };
    fields[key] = value;
    setUpdatedFields(fields);
  };

  const defaultCountryCode = "+91";
  return (
    <>
      <Box>
        <form onSubmit={formOne.handleSubmit}>
          <Grid container gap={2}>
            <Typography color={"#f15830"} width="100%">
              Personal <Divider />
            </Typography>

            <Grid xs={12}>
              <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Personal Email*</label>
                  <TextField
                    id="fpersonal-Email"
                    name="personalEmail"
                    size="small"
                    helperText={
                      formOne.touched.personalEmail &&
                      formOne.errors.personalEmail
                    }
                    error={
                      formOne.touched.personalEmail &&
                      Boolean(formOne.errors.personalEmail)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.personalEmail}
                    sx={{ width: "220px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Nationality *</label>
                  <TextField
                    id="nationality-personal"
                    name="nationality"
                    size="small"
                    helperText={
                      formOne.touched.nationality && formOne.errors.nationality
                    }
                    error={
                      formOne.touched.nationality &&
                      Boolean(formOne.errors.nationality)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.nationality}
                    sx={{ width: "190px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <FormControl>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{
                        color: "black",
                        fontFamily: "Roboto",
                        fontSize: "14px",
                      }}
                    >
                      Physically Challenged *
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="physicallyChallenged"
                      id="physicallyChallenged-radio"
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.physicallyChallenged}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          height: 15,
                          width: 15,
                        },
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Yes
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            No
                          </Typography>
                        }
                      />
                    </RadioGroup>
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.physicallyChallenged &&
                        formOne.errors.physicallyChallenged}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>
                    DOB (as per Celebration)*
                  </label>
                  <CustomDatePicker
                    value={formOne.values.dobAsPerCelebration}
                    handleDateChange={(dateString) => {
                      formOne.setFieldValue(
                        "dobAsPerCelebration",
                        dateString,
                        true
                      );
                      handleUpdatedFields("dobAsPerCelebration", dateString);
                    }}
                    errorText={
                      formOne.touched.dobAsPerCelebration &&
                      formOne.errors.dobAsPerCelebration
                    }
                    isError={
                      formOne.touched.dobAsPerCelebration &&
                      Boolean(formOne.errors.dobAsPerCelebration)
                    }
                    textFieldDisabled={false}
                    datePickerdisabled={false}
                    padding={"17.9px 14px"}
                    rest={{}}
                  />
                  {/* <DatePicker
                    id="dobAsPerCertificate-picker"
                    className={classes.datePickerStyles}
                    dateFormat="dd/MM/yyyy"
                    selected={formOne.values.dobAsPerCelebration}
                    onChange={(date) =>
                      formOne.setFieldValue("dobAsPerCelebration", date)
                    }
                    filterDate={isWeekday}
                    placeholderText="DD/MM/YYYY"
                    maxDate={addDays(new Date(), 0)}
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {formOne.touched.dobAsPerCelebration &&
                      formOne.errors.dobAsPerCelebration}
                  </FormHelperText> */}
                </Box>
              </Box>
            </Grid>

            <Grid xs={12}>
              <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Current Address *</label>
                  <TextField
                    id="current-Address"
                    name="currentAddress"
                    helperText={
                      formOne.touched.currentAddress &&
                      formOne.errors.currentAddress
                    }
                    error={
                      formOne.touched.currentAddress &&
                      Boolean(formOne.errors.currentAddress)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.currentAddress}
                    multiline
                    sx={{ width: "400px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Permanent Address *</label>
                  <TextField
                    id="permanent-Address"
                    name="permanentAddress"
                    helperText={
                      formOne.touched.permanentAddress &&
                      formOne.errors.permanentAddress
                    }
                    error={
                      formOne.touched.permanentAddress &&
                      Boolean(formOne.errors.permanentAddress)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.permanentAddress}
                    multiline
                    sx={{ width: "400px" }}
                    disabled={addresschecked}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addresschecked}
                        onChange={handleAddressCheck}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                    }
                    // label=""
                    label={
                      <Typography
                        sx={{
                          fontSize: "16px",
                        }}
                      >
                        Check if permanent address is same as current
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Typography color={"red"} width="100%">
              Passport <Divider />
            </Typography>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={5}
              className={classes.limitWidth}
            >
              <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Passport Number</label>
                  <TextField
                    id="passport-Number"
                    name="passportNumber"
                    size="small"
                    helperText={
                      formOne.touched.passportNumber &&
                      formOne.errors.passportNumber
                    }
                    error={
                      formOne.touched.passportNumber &&
                      Boolean(formOne.errors.passportNumber)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.passportNumber}
                    sx={{ width: "100%" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Passport Expiry</label>
                  <CustomDatePicker
                    value={formOne.values.passportExpiry}
                    handleDateChange={(dateString) => {
                      formOne.setFieldValue("passportExpiry", dateString, true);
                      handleUpdatedFields("passportExpiry", dateString);
                    }}
                    errorText={
                      formOne.touched.passportExpiry &&
                      formOne.errors.passportExpiry
                    }
                    isError={
                      formOne.touched.passportExpiry &&
                      Boolean(formOne.errors.passportExpiry)
                    }
                    textFieldDisabled={false}
                    datePickerdisabled={false}
                    padding={"17.9px 14px"}
                    rest={{}}
                  />
                  {/* <DatePicker
                    id="passportExpiry-picker"
                    className={classes.datePickerStyles}
                    dateFormat="dd/MM/yyyy"
                    selected={formOne.values.passportExpiry}
                    onChange={(date) =>
                      formOne.setFieldValue("passportExpiry", date)
                    }
                    filterDate={isWeekday}
                    placeholderText="DD/MM/YYYY"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {formOne.touched.passportExpiry &&
                      formOne.errors.passportExpiry}
                  </FormHelperText> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container gap={2} mt={3}>
            <Typography color={"red"} width="100%">
              Emergency Details <Divider />
            </Typography>
            <Grid xs={12}>
              <Box display={"flex"} flexDirection={"row"} gap={2}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Contact Name 1 *</label>
                  <TextField
                    id="contactName-1"
                    name="contactName1"
                    size="small"
                    helperText={
                      formOne.touched.contactName1 &&
                      formOne.errors.contactName1
                    }
                    error={
                      formOne.touched.contactName1 &&
                      Boolean(formOne.errors.contactName1)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.contactName1}
                    sx={{ width: "240px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Relationship *</label>
                  <TextField
                    size="small"
                    id="relationship-1"
                    name="relationship1"
                    helperText={
                      formOne.touched.relationship1 &&
                      formOne.errors.relationship1
                    }
                    error={
                      formOne.touched.relationship1 &&
                      Boolean(formOne.errors.relationship1)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.relationship1}
                    sx={{ width: "240px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Phone *</label>
                  <PhoneInput
                    country={"in"}
                    onlyCountries={["in"]}
                    inputClass={classes.phoneInputStyles}
                    inputProps={{
                      id: "emergencyPhone-1",
                      name: "emergencyPhone-1",
                    }}
                    onBlur={formOne.handleBlur}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                      formOne.setFieldValue("emergencyPhone1", value);
                    }}
                    isValid={!formOne.errors.emergencyPhone1}
                    value={formOne?.values?.emergencyPhone1}
                  />
                  {formOne.errors.emergencyPhone1 && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {formOne.errors.emergencyPhone1}
                    </div>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box display={"flex"} flexDirection={"row"} gap={2}>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Contact Name 2 </label>
                  <TextField
                    id="contactName-2"
                    name="contactName2"
                    size="small"
                    helperText={
                      formOne.touched.contactName2 &&
                      formOne.errors.contactName2
                    }
                    error={
                      formOne.touched.contactName2 &&
                      Boolean(formOne.errors.contactName2)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.contactName2}
                    sx={{ width: "240px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Relationship </label>
                  <TextField
                    size="small"
                    id="relationship-2"
                    name="relationship2"
                    helperText={
                      formOne.touched.relationship2 &&
                      formOne.errors.relationship2
                    }
                    error={
                      formOne.touched.relationship2 &&
                      Boolean(formOne.errors.relationship2)
                    }
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.relationship2}
                    sx={{ width: "240px" }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <label className={classes.label}>Phone </label>
                  <PhoneInput
                    inputClass={classes.phoneInputStyles}
                    country={"in"}
                    onlyCountries={["in"]}
                    inputProps={{
                      id: "emergencyPhone-2",
                      name: "emergencyPhone2",
                    }}
                    onBlur={formOne.handleBlur}
                    onChange={(value, country) => {
                      handlePhoneChange1(value, country);
                      formOne.handleChange({
                        target: {
                          name: "emergencyPhone2",
                          value: value,
                        },
                      });
                    }}
                    value={formOne?.values?.emergencyPhone2}
                    isValid={!formOne.errors.emergencyPhone2}
                  />
                  {formOne.errors.emergencyPhone2 && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {formOne.errors.emergencyPhone2}
                    </div>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid container gap={1}>
              <Typography color={"red"} width="100%">
                Family <Divider />
              </Typography>
              <a
                href={insuranceDashboardUrl}
                className="manage-documentation-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manage Policy Details
              </a>
              <Grid xs={12}>
                <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.label}>
                      Father Name (as per Aadhaar) *
                    </label>
                    <TextField
                      id="father-Name"
                      name="fatherName"
                      size="small"
                      helperText={
                        formOne.touched.fatherName && formOne.errors.fatherName
                      }
                      error={
                        formOne.touched.fatherName &&
                        Boolean(formOne.errors.fatherName)
                      }
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.fatherName}
                      sx={{ width: "330px" }}
                    />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.label}>
                      Mother Name (as per Aadhaar) *
                    </label>
                    <TextField
                      id="mother-Name"
                      name="motherName"
                      size="small"
                      helperText={
                        formOne.touched.motherName && formOne.errors.motherName
                      }
                      error={
                        formOne.touched.motherName &&
                        Boolean(formOne.errors.motherName)
                      }
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.motherName}
                      sx={{ width: "330px" }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Box display={"flex"} flexDirection={"column"}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "black",
                      fontFamily: "Roboto",
                      fontSize: "15px",
                    }}
                  >
                    Married Status *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="marriedStatus"
                    id="married-Status"
                    onBlur={formOne.handleBlur}
                    onChange={formOne.handleChange}
                    value={formOne.values.marriedStatus}
                  >
                    <FormControlLabel
                      value="Single"
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          Single
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="Married"
                      control={<Radio />}
                      label={
                        <Typography
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          Married
                        </Typography>
                      }
                    />
                  </RadioGroup>
                  <FormHelperText sx={{ color: "red" }}>
                    {formOne.touched.marriedStatus &&
                      formOne.errors.marriedStatus}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>

            {Boolean(formOne.values.marriedStatus === "Married") ? (
              <Grid xs={12} mb={3}>
                <Typography color={"red"} width="100%" mb={3}>
                  Spouse <Divider />
                </Typography>
                <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.label}>
                      Spouse First Name (as per Aadhaar)
                    </label>
                    <TextField
                      id="spouse-FirstName"
                      name="spouseFirstName"
                      size="small"
                      // helperText={
                      //   formOne.touched.spouseFirstName &&
                      //   formOne.errors.spouseFirstName
                      // }
                      // error={
                      //   formOne.touched.spouseFirstName &&
                      //   Boolean(formOne.errors.spouseFirstName)
                      // }
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.spouseFirstName}
                      sx={{ width: "180px" }}
                    />
                  </Box>
                  <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.label}>
                      Spouse Last Name (as per Aadhaar)
                    </label>
                    <TextField
                      id="spouse-LastName"
                      name="spouseLastName"
                      size="small"
                      // helperText={
                      //   formOne.touched.spouseLastName &&
                      //   formOne.errors.spouseLastName
                      // }
                      // error={
                      //   formOne.touched.spouseLastName &&
                      //   Boolean(formOne.errors.spouseLastName)
                      // }
                      onBlur={formOne.handleBlur}
                      onChange={(e) => {
                        formOne.handleChange(e);
                      }}
                      value={formOne.values.spouseLastName}
                      sx={{ width: "180px" }}
                    />
                  </Box>

                  <Box display={"flex"} flexDirection={"column"}>
                    <label className={classes.label}>DOB </label>
                    <CustomDatePicker
                      value={formOne.values.spouseDob}
                      handleDateChange={(dateString) => {
                        formOne.setFieldValue("spouseDob", dateString, true);
                        handleUpdatedFields("spouseDob", dateString);
                      }}
                      errorText={
                        formOne.touched.spouseDob && formOne.errors.spouseDob
                      }
                      isError={
                        formOne.touched.spouseDob &&
                        Boolean(formOne.errors.spouseDob)
                      }
                      textFieldDisabled={true}
                      datePickerdisabled={false}
                      padding={"17.9px 14px"}
                      rest={{}}
                    />
                    {/* <DatePicker
                      id="spouseDob-picker"
                      className={classes.datePickerStyles}
                      dateFormat="dd/MM/yyyy"
                      selected={formOne.values.spouseDob}
                      onChange={(date) =>
                        formOne.setFieldValue("spouseDob", date)
                      }
                      filterDate={isWeekday}
                      placeholderText="DD/MM/YYYY"
                      maxDate={addDays(new Date(), 0)}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      onKeyDown={(e) => e.preventDefault()}
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {formOne.touched.spouseDob && formOne.errors.spouseDob}
                    </FormHelperText> */}
                  </Box>

                  {/* <Box display={"flex"} flexDirection={"column"}>
                    <label
                      className={classes.label}
                      style={{ marginBottom: "30px" }}
                    >
                      DOB *
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        openTo="year"
                        views={["year", "month", "day"]}
                        value={formOne.values.spouseDob}
                        onChange={(value) => {
                          formOne.setFieldValue("spouseDob", value, true);
                        }}
                        // onChange={formOne.handleChange}
                        renderInput={(params) => (
                          <TextField
                            error
                            helperText={formOne.errors.spouseDob}
                            disabled={!props.hasButtons}
                            id="spouse-Dob"
                            fullWidth
                            name="spouseDob"
                            margin="normal"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box> */}
                  {/* <Box display={"flex"} flexDirection={"column"}>
                    <FormControl>
                      <FormLabel
                        id="spouseGender-radio"
                        sx={{
                          color: "black",
                          fontFamily: "Roboto",
                          fontSize: "15px",
                        }}
                      >
                        Gender*
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="spouseGender-radio"
                        name="spouse-Gender"
                        id="spouseGender"
                        onBlur={formOne.handleBlur}
                        onChange={(e) => {
                          formOne.setFieldValue(
                            "spouseGender",
                            e.target.value,
                            true
                          );
                        }}
                        value={formOne.values.spouseGender}
                      >
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        {formOne.touched.spouseGender &&
                          formOne.errors.spouseGender}
                      </FormHelperText>
                    </FormControl>
                  </Box> */}
                </Box>
              </Grid>
            ) : null}
            {formOne.values.marriedStatus === "yes" ? (
              <Grid xs={12}>
                <Typography color={"red"} width="100%" mb={3}>
                  Children <Divider />
                </Typography>
                {formOne.values.childrenData.map((singleService, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 3,
                      marginTop: 1,
                    }}
                    key={index}
                  >
                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>
                        Child {index + 1} Full Name (as per Aadhaar)
                      </label>
                      <TextField
                        id={`childrenData[${index}].childFullName`}
                        size="small"
                        name={`childrenData[${index}].childFullName`}
                        onBlur={formOne.handleBlur}
                        onChange={(e) => {
                          formOne.handleChange(e);
                          handleUpdatedFields("childFullName", e.target.value);
                        }}
                        value={formOne.values.childrenData[index].childFullName}
                        error={getError(`childrenData[${index}].childFullName`)}
                        helperText={getError(
                          `childrenData[${index}].childFullName`
                        )}
                        sx={{ width: "180px" }}
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <label className={classes.label}>DOB </label>
                      <TextField
                        id="childDob-Dob"
                        name={`childrenData[${index}].childDob`}
                        type="date"
                        value={formOne.values.childrenData[index].childDob}
                        error={getError(`childrenData[${index}].id="childDob-Dob"
                        `)}
                        helperText={getError(
                          `childrenData[${index}]. id="childDob-Dob"`
                        )}
                        onChange={formOne.handleChange}
                        sx={{ width: 180 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} ml={2}>
                      <FormControl>
                        <FormLabel
                          id="child-gender"
                          sx={{
                            color: "black",
                            fontFamily: "Roboto",
                            fontSize: "15px",
                          }}
                        >
                          Gender
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name={`childrenData[${index}].childGender`}
                          onBlur={formOne.handleBlur}
                          onChange={formOne.handleChange}
                          value={formOne.values.childrenData[index].childGender}
                        >
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Button onClick={() => handleServiceRemove(index)}>
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: "#959595", marginTop: "36px" }}
                        />
                      </Button>
                    </Box>
                  </div>
                ))}

                <button
                  type="button"
                  className={classes.addBtn}
                  onClick={handleService}
                >
                  <span>+ Add Child</span>
                </button>
              </Grid>
            ) : null}
          </Grid>
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#f15830",
                color: "white",
              }}
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PersonalDetailsForm;
