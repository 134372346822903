import { PUSH_NOTIFICATION } from "../constants/actions";

// Action Type
export const LOADER = "LOADER";
export const ACCESS_TOEKN = "ACCESS_TOEKN";
export const OFFICIALEMAILID = "OFFICIALEMAIL_ID";
export const SET_ROLES = "SET_ROLES";
export const SET_ROLE = "SET_ROLE";
export const SET_PROFILEPICTURE = "SET_PROFILEPICTURE";
export const SET_PROFILEPICTURE_REFRESH = "SET_PROFILEPICTURE_REFRESH";
export const SET_USERDATA = "SET_USERDATA";
export const REPORTINGTOEMAILID = "REPORTINGTOEMAIL_ID";
export const SET_VISITORS = "SET_VISITORS";
export const UPDATE_VISITOR = "UPDATE_VISITOR";
export const UPDATE_LOCATION = "UPDATE_LOCATION";

// Action
export const setLoader = (loading) => {
  return {
    type: LOADER,
    payload: loading,
  };
};

export const updateLocation = (address) => {
  return { type: UPDATE_LOCATION, payload: address };
};

export const setAnonymousUser = (data) => {
  return {
    type: LOADER,
    payload: data,
  };
};

export const pushNotification = (data) => {
  return { type: PUSH_NOTIFICATION, payload: data };
};

export const setAccessToken = (token) => {
  return {
    type: ACCESS_TOEKN,
    payload: token,
  };
};

export const setOfficialEmailId = (data) => {
  return {
    type: OFFICIALEMAILID,
    payload: data,
  };
};

export const setRolesData = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};

export const setRole = (data) => {
  return {
    type: SET_ROLE,
    payload: data,
  };
};

export const setProfilePicture = (data) => {
  return {
    type: SET_PROFILEPICTURE,
    payload: data,
  };
};

export const setProfilePictureRefresh = (data) => {
  return {
    type: SET_PROFILEPICTURE_REFRESH,
    payload: data,
  };
};

export const setUserData = (data) => {
  return {
    type: SET_USERDATA,
    payload: data,
  };
};

export const setReportingToEmailId = (data) => {
  return {
    type: REPORTINGTOEMAILID,
    payload: data,
  };
};
export const setVisitors = (data) => {
  return {
    type: SET_VISITORS,
    payload: data,
  };
};
export const updateVisitor = (data) => {
  return {
    type: UPDATE_VISITOR,
    payload: data,
  };
};
